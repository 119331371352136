import { MdModeEdit, MdDelete } from "react-icons/md";
import {
  ContentMessageImage,
  ContentMessageText,
  MessageResponse,
} from "./ModalContent";

interface MessageItemProps {
  message: MessageResponse;
}

const MessageItem = ({ message }: MessageItemProps) => {
  return (
    <div className="pl-12 relative group">
      <div className="rounded w-auto max-w-screen-md bg-[#1f6466] p-5 text-right relative z-10 cursor-pointer">
        {message.type === "text" ? (
          <p>{(message.content as ContentMessageText).body}</p>
        ) : message.type === "image" ? (
          <div>
            <img src={(message.content as ContentMessageImage).url} />
            <p className="mt-3">
              {(message.content as ContentMessageImage).caption}
            </p>
          </div>
        ) : (
          <p>Formato de mensaje no soportado.</p>
        )}
      </div>
      <div className="absolute left-0 top-[50%] translate-y-[-50%] flex gap-2 translate-x-[50%] z-[8] opacity-0 group-hover:opacity-100 transition-all group-hover:-translate-x-[60%] ">
        <button className="p-2 rounded-full bg-[#20cab4] flex justify-center items-center ">
          <MdModeEdit className="text-xl text-white" />
        </button>
        <button className="p-2 rounded-full bg-[#ff5758] flex justify-center items-center">
          <MdDelete className="text-xl text-white" />
        </button>
      </div>
    </div>
  );
};

export { MessageItem };
