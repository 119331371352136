import { useEffect, useState } from "react";
import {
  Condition,
  WorkflowCondition,
} from "../../interfaces/condition.interface";
import { ActionType, WorkflowAction } from "../../interfaces/action.interface";
import {
  ActionConditionType,
  FormattedActionCondition,
} from "../../interfaces/actionCondition.interface";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { MdDragIndicator } from "react-icons/md";
import { ActionCondition } from "./ActionCondition";
import { getActionTypes } from "../../services/actions.service";
import { GiChoice } from "react-icons/gi";
import { BsPlay } from "react-icons/bs";

interface ItemsContainerProps {
  prop_items: Array<WorkflowAction> | Array<WorkflowCondition>;
  type: ActionConditionType;
  title: string;
  edit_mode: boolean;
  on_update: (items: FormattedActionCondition[]) => void;
  on_toggle_critical?: (condition_id: string) => void;
  on_open_modal?: (
    mode: "view" | "edit" | "create",
    id: string,
    allow_add?: boolean
  ) => void;
}

export const ItemsContainer: React.FC<ItemsContainerProps> = ({
  prop_items,
  title,
  edit_mode,
  type,
  on_update,
  on_toggle_critical,
  on_open_modal,
}) => {
  const [actionTypes, setActionTypes] = useState<Array<ActionType>>([]);
  const [items, updateItems] = useState<Array<FormattedActionCondition>>([]);

  const formatItem = (
    item: WorkflowAction | WorkflowCondition
  ): FormattedActionCondition => {
    const formattedItem = {} as FormattedActionCondition;
    if (type === ActionConditionType.ACTION) {
      const _item = item as WorkflowAction;

      formattedItem.id = _item.action._id;
      formattedItem.title = _item.action.title;
      formattedItem.subtitle =
        actionTypes.find((actionType) => actionType.type === _item.action.type)
          ?.label || "";
      formattedItem.index = _item.index;
      formattedItem.icon = _item.action.icon;
    } else {
      const _item = item as WorkflowCondition;
      formattedItem.id = _item.condition._id;
      formattedItem.title = _item.condition.title;
      formattedItem.subtitle = _item.condition.extractor_label;
      formattedItem.description = _item.condition.description;
      formattedItem.is_critical = _item.inverse_for_skip_condition;
      formattedItem.index = _item.index;
      formattedItem.icon = _item.condition.icon;
    }

    return formattedItem;
  };

  useEffect(() => {
    items.map((item, index) => {
      return {
        ...item,
        index,
      };
    });
    updateItems(items);
  }, [items]);

  const fetchActionTypes = async () => {
    if (type === ActionConditionType.ACTION && actionTypes.length === 0) {
      const response = await getActionTypes();
      setActionTypes(response.data);
    }
  };

  useEffect(() => {
    fetchActionTypes();

    const formattedItems = prop_items.map((item) => formatItem(item));
    const sortedFormattedItems = formattedItems.sort(
      (a, b) => a.index - b.index
    );

    updateItems(sortedFormattedItems);
  }, [prop_items, edit_mode, actionTypes]);

  const styles = {
    container: {
      maxHeight: "calc(100vh - 300px)",
      minHeight: "calc(100vh - 300px)",
    },
  };

  const handleOnRemove = (id: string) => {
    updateItems(items.filter((item) => item.id !== id));
    on_update(items.filter((item) => item.id !== id));
  };

  const handleOnDragEnd = (result: any) => {
    if (!result.destination) return;

    const _items = Array.from(items);
    const [reorderedItem] = _items.splice(result.source.index, 1);
    _items.splice(result.destination.index, 0, reorderedItem);

    _items.forEach((item, index) => {
      item.index = index;
    });

    updateItems(_items);
    on_update(_items);
  };

  return (
    <div
      style={styles.container}
      className="bg-darkerBackground rounded-lg text-white items-center overflow-y-auto overflow-x-clip pb-32"
    >
      <div className="text-xl font-medium pt-4 pb-4 bg-darkerBackground sticky top-0 z-10 flex flex-row gap-4 justify-center text-principalColor">
        {type === ActionConditionType.CONDITION ? (
          <>
            <GiChoice size={35}></GiChoice>
          </>
        ) : (
          <>
            <BsPlay size={35}></BsPlay>
          </>
        )}
        {title}
      </div>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <ul
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="flex flex-col gap-6 p-6 "
            >
              {items.map((item, index) => (
                <Draggable
                  isDragDisabled={!edit_mode}
                  key={`${item.id}-${index}`} // Clave única usando id + índice
                  draggableId={`${item.id}-${index}`} // draggableId único
                  index={index}
                >
                  {(provided) => (
                    <li ref={provided.innerRef} {...provided.draggableProps}>
                      <ActionCondition
                        on_remove={(id: string) => {
                          handleOnRemove(id);
                        }}
                        on_toggle_critical={(condition_id: string) => {
                          on_toggle_critical &&
                            on_toggle_critical(condition_id);
                        }}
                        on_open_modal={(mode, id, allow_add) => {
                          on_open_modal && on_open_modal(mode, id, allow_add);
                        }}
                        id={item.id}
                        edit_mode={edit_mode}
                        type={type}
                        title={item.title}
                        subtitle={item.subtitle}
                        icon={item.icon}
                        description={item.description}
                        is_critical={item.is_critical}
                        draggableElement={
                          <div className="" {...provided.dragHandleProps}>
                            <MdDragIndicator size={30} />
                          </div>
                        }
                      ></ActionCondition>
                    </li>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};
