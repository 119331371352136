import React, { useContext, useEffect, useState} from 'react';
import { AiOutlineEdit, AiOutlineCopy, AiOutlineDelete } from "react-icons/ai";
import 'react-toastify/dist/ReactToastify.css';
import { Campaign } from '../interfaces/analytics.interface';

type Props = {
    data: Campaign,
    copyToClipboard: (textToCopy: string) => void,
    deleteCampaign: (id:string) => void,
    editCampaign: (id:string) => void
}
const CampaignListItem: React.FC<Props> = ({data, copyToClipboard, deleteCampaign, editCampaign}) => {
    return (
        <>
            <div 
                className='border border-gray-200 hover:border-[#53b6a7] flex flex-col p-5 pl-6 pr-6 mb-4 rounded-xl overflow-hidden bg-white w-full justify-between cursor-pointer'
                onClick={() => editCampaign(data._id)}
            >
                <div  className='flex justify-between'>
                    <h3 className='text-black text-lg mb-3 font-semibold'>{data.campaign}</h3>
                    <div className='flex gap-2 items-start'>
                        <button className='bg-[#53b6a7] text-white rounded p-2' onClick={(e) => {
                            e.stopPropagation()
                            copyToClipboard(data.utm)}
                        }>
                            <AiOutlineCopy />
                        </button>
                        <button className='bg-[#53b6a7] text-white rounded p-2' 
                            onClick={(e) => {
                                editCampaign(data._id)
                            }}
                        >
                            <AiOutlineEdit />
                        </button>
                        <button 
                            className='bg-[#cb5f5f] text-white rounded p-2'
                            onClick={(e) => {
                                e.stopPropagation()
                                deleteCampaign(data._id)
                            }}
                        >
                            <AiOutlineDelete />
                        </button>
                    </div>
                </div>    
                <div className='w-full flex'>
                    <span className='mr-2 text-sm text-black '>URL:</span>
                    <p className='w-full overflow-hidden text-ellipsis text-sm text-[#a2a2a2]'>{data.utm}</p>

                </div>

            </div>
            
        </>
    )
};

export default CampaignListItem;
