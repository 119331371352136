import { axiosConfig } from "./axios.config";

const createTopic = (company, body) => {
  return axiosConfig.post(
    `${process.env.REACT_APP_API_V3}/topics/${company}/topics`,
    body
  );
};

const updateTopic = (company, body, id) => {
  return axiosConfig.put(
    `${process.env.REACT_APP_API_V3}/topics/${company}/topics/${id}`,
    body
  );
};

const getMessagesFromTopic = (company, id) => {
  return axiosConfig.get(
    `${process.env.REACT_APP_API_V3}/topics/${company}/topics/${id}`
  );
};

export { createTopic, updateTopic, getMessagesFromTopic };
