import { useEffect, useState } from "react";
import Switch from "@mui/material/Switch";
import {
  Condition,
  ConditionDataObject,
} from "../../../interfaces/condition.interface";
import {
  getExtractors,
  getExtractorValues,
} from "../../../services/extractors.service";
import { Extractor } from "../../../interfaces/extractor.interface";
import { renderIcon } from "../../../utils/icons";
import { Skeleton } from "@mui/material";
import SearchDropdown from "../../SearchDropdown";
import { GiChoice } from "react-icons/gi";

interface EditCreateConditionProps {
  condition: Condition | null;
  mode: "edit" | "create";
  emit_data: (data: ConditionDataObject) => void;
  companyId: string;
}

export const EditCreateCondition: React.FC<EditCreateConditionProps> = ({
  condition,
  mode,
  emit_data,
  companyId,
}) => {
  const [extractors, setExtractors] = useState<Extractor[]>([]);
  const [extractorOperators, setExtractorOperators] = useState<
    { value: string; label: string }[]
  >([{ value: "", label: "" }]);
  const [dataObject, setDataObject] = useState<ConditionDataObject>({
    name: "",
    description: "",
    extractor: null,
    operator: null,
    comparison_value: { type: "", value: [] },
  });
  const [fetchingExtractorValues, setFetchingExtractorValues] =
    useState<boolean>(false);

  const handleChangeConditionType = async (conditionType: string) => {
    const extractor = extractors.find(
      (e) => e.extractor_type === conditionType
    );
    await setDataObject({
      ...dataObject,
      extractor: extractor || null,
      operator: extractor?.operators[0].value || null,
      comparison_value: {
        type: extractor?.type!,
        value:
          extractor?.type === "list_string"
            ? []
            : extractor?.type === "boolean"
            ? false
            : extractor?.type === "list_datetime"
            ? [
                [
                  new Date().toISOString().slice(11, 19),
                  new Date().toISOString().slice(11, 19),
                ],
              ]
            : 0,
      },
    });
  };

  const fetchExtractors = async () => {
    const _extractors = await getExtractors();
    setExtractors(_extractors.data);
    setFetchingExtractorValues(false);
  };

  useEffect(() => {
    fetchExtractors();
  }, []);

  useEffect(() => {
    emit_data(dataObject);
  }, [dataObject]);

  useEffect(() => {
    setFetchingExtractorValues(true);
    getExtractorValues(dataObject.extractor?.extractor_type!, companyId).then(
      (data) => {
        setExtractorOperators(data.data);
        setFetchingExtractorValues(false);
      }
    );
  }, [dataObject.extractor?.extractor_type]);

  useEffect(() => {
    if (!condition) {
      return;
    }
    setDataObject({
      ...dataObject,
      name: mode === "edit" ? condition?.title! : "",
      description: mode === "edit" ? condition?.description! : "",
      extractor:
        mode === "edit"
          ? extractors.find(
              (e) => e.extractor_type === condition!.objectExtractor
            )!
          : null,
      operator:
        mode === "edit"
          ? condition?.operator.value!
          : dataObject?.extractor?.operators[0].value!,
      comparison_value:
        mode === "edit"
          ? condition?.comparison_value!
          : {
              type: "x",
              value: "x",
            },
    });
  }, [extractors, condition]);

  const handleTimeRangeChange = (
    index: number,
    timeIndex: number,
    newTime: string
  ) => {
    const newRanges = [...(dataObject.comparison_value.value as string[][])];
    newRanges[index][timeIndex] = newTime + ":00";
    setDataObject({
      ...dataObject,
      comparison_value: {
        ...dataObject.comparison_value,
        value: newRanges,
      },
    });
  };

  const addTimeRange = () => {
    setDataObject({
      ...dataObject,
      comparison_value: {
        ...dataObject.comparison_value,
        value: [
          ...(dataObject.comparison_value.value as string[][]),
          [
            new Date().toISOString().slice(11, 19),
            new Date().toISOString().slice(11, 19),
          ],
        ],
      },
    });
  };

  const removeTimeRange = (index: number) => {
    const newRanges = [...(dataObject.comparison_value.value as string[][])];
    newRanges.splice(index, 1);
    setDataObject({
      ...dataObject,
      comparison_value: {
        ...dataObject.comparison_value,
        value: newRanges,
      },
    });
  };

  return (
    <>
      {condition !== null || mode === "create" ? (
        <div className="flex flex-col gap-2">
          <div className="header flex flex-col items-center justify-start gap-2 w-full text-md">
            <div className="w-full flex flex-row items-center justify-start gap-2">
              <div className="avatar w-2/12 text-center">
                <div className="w-9/12 m-auto">
                  {renderIcon(
                    dataObject.extractor?.icon!,
                    {
                      width: "100%",
                      height: "100%",
                    },
                    <GiChoice
                      style={{ width: "100%", height: "100%" }}
                    ></GiChoice>
                  )}
                </div>
              </div>
              <div>
                <label className="form-control w-full">
                  <div className="label">
                    <span className="label-text">Tipo de condición</span>
                  </div>
                  <select
                    className="select select-bordered w-full bg-mainBackground"
                    value={dataObject.extractor?.extractor_type || ""}
                    onChange={(e) => {
                      handleChangeConditionType(e.target.value);
                    }}
                  >
                    <option value="" disabled>
                      Selecciona un tipo de condición
                    </option>
                    {extractors.map((extractor, index) => (
                      <option key={index} value={extractor.extractor_type}>
                        {extractor.extractor_label}
                      </option>
                    ))}
                  </select>
                </label>
              </div>
            </div>
            <label className="form-control w-full">
              <div className="label">
                <span className="label-text">Nombre</span>
              </div>
              <input
                type="text"
                defaultValue={mode === "edit" ? condition!.title : ""}
                className="input input-bordered w-full bg-mainBackground"
                onInput={(e) => {
                  setDataObject({
                    ...dataObject,
                    name: e.currentTarget.value,
                  });
                }}
              />
            </label>

            {dataObject.extractor && (
              <>
                <label className="form-control w-full">
                  <div className="label">
                    <span className="label-text">Tipo de comparación</span>
                  </div>
                  <select
                    className="select select-bordered w-full bg-mainBackground"
                    value={
                      dataObject.operator || extractors[0]?.operators[0].value
                    }
                    onChange={(e) => {
                      setDataObject({
                        ...dataObject,
                        operator: e.target.value,
                      });
                    }}
                  >
                    {dataObject.extractor?.operators.map((extractor, index) => (
                      <option key={index} value={extractor.value}>
                        {extractor.label}
                      </option>
                    ))}
                  </select>
                </label>

                <label className="form-control w-full">
                  <div className="label">
                    <span className="label-text">Valor</span>
                  </div>
                  {dataObject.extractor?.type === "boolean" && (
                    <Switch
                      checked={dataObject.comparison_value.value as boolean}
                      onChange={(e) => {
                        setDataObject({
                          ...dataObject,
                          comparison_value: {
                            type: "boolean",
                            value: e.target.checked,
                          },
                        });
                      }}
                      color="primary"
                    />
                  )}
                  {dataObject.extractor?.type === "integer" && (
                    <input
                      type="number"
                      defaultValue={
                        mode === "edit"
                          ? (condition!.comparison_value!.value as number)
                          : 0
                      }
                      className="input input-bordered bg-mainBackground"
                      onInput={(e) => {
                        setDataObject({
                          ...dataObject,
                          comparison_value: {
                            type: "integer",
                            value: Number(e.currentTarget.value),
                          },
                        });
                      }}
                    />
                  )}
                  {dataObject.extractor.type === "integer_seconds" && (
                    <input
                      type="number"
                      defaultValue={
                        mode === "edit"
                          ? (condition!.comparison_value!.value as number) /
                            3600
                          : 0
                      }
                      className="input input-bordered bg-mainBackground"
                      onInput={(e) => {
                        setDataObject({
                          ...dataObject,
                          comparison_value: {
                            type: dataObject.comparison_value.type,
                            value:
                              Number(Number(e.currentTarget.value).toFixed(2)) *
                              3600,
                          },
                        });
                      }}
                      step={1}
                      min={0}
                    />
                  )}
                  {dataObject.extractor?.type === "list_string" &&
                    (!fetchingExtractorValues &&
                    extractorOperators &&
                    extractorOperators.length > 0 ? (
                      <SearchDropdown
                        defaultValue={
                          condition?.comparison_value?.value as string[]
                        }
                        items={extractorOperators.map((value) => {
                          return { label: value.label, value: value.value };
                        })}
                        multipleChoice={true}
                        onChange={(value) => {
                          setDataObject({
                            ...dataObject,
                            comparison_value: {
                              type: "list_string",
                              value: value,
                            },
                          });
                        }}
                      ></SearchDropdown>
                    ) : (
                      <>
                        <Skeleton variant="rounded" height={40} />
                      </>
                    ))}
                  {dataObject.extractor?.type === "list_datetime" && (
                    <div className="">
                      <div>
                        {(dataObject.comparison_value.value as string[][]).map(
                          (range, index: number) => (
                            <div key={index} className="flex gap-2 mb-2">
                              <input
                                type="time"
                                value={range[0].slice(0, 5)}
                                onChange={(e) =>
                                  handleTimeRangeChange(
                                    index,
                                    0,
                                    e.target.value
                                  )
                                }
                                className="input input-bordered bg-mainBackground"
                              />
                              <input
                                type="time"
                                value={range[1].slice(0, 5)}
                                onChange={(e) =>
                                  handleTimeRangeChange(
                                    index,
                                    1,
                                    e.target.value
                                  )
                                }
                                className="input input-bordered bg-mainBackground"
                              />
                              <button
                                disabled={
                                  (
                                    dataObject.comparison_value
                                      .value as string[][]
                                  ).length === 1
                                }
                                onClick={() => removeTimeRange(index)}
                                className="btn btn-warning mb-4"
                              >
                                -
                              </button>
                            </div>
                          )
                        )}
                      </div>
                      <button
                        onClick={addTimeRange}
                        className="btn btn-warning mb-2"
                      >
                        +
                      </button>
                    </div>
                  )}
                </label>
              </>
            )}
            <label className="form-control w-full">
              <div className="label">
                <span className="label-text">Descripción</span>
              </div>
              <textarea
                onInput={(e) => {
                  setDataObject({
                    ...dataObject,
                    description: e.currentTarget.value,
                  });
                }}
                defaultValue={mode === "edit" ? condition!.description : ""}
                className="input input-bordered w-full h-20 bg-mainBackground"
              />
            </label>
          </div>
        </div>
      ) : null}
    </>
  );
};
