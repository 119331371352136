import { axiosConfig } from "./axios.config";

const updateClientInfo = async (chat_id , {whatsapp_name, country_phone, email, documento}, company, workspace, agent_id) => {

  const res = await axiosConfig.post(`${process.env.REACT_APP_API_V3}/chat_workspace/${company}/${workspace}/update_client`,
    {
        "agent_email": agent_id,
        "chat_id": chat_id,
        "name": whatsapp_name,
        "country": country_phone,
        "email": email,
        "DNI": documento,
        "photo": ""
    }
	);
	return res.data;
}

const getClientInfo = async (chat_id, company) => {

  const res = await axiosConfig.post(`${process.env.REACT_APP_API_NEW_URL}/agent/${company}/get_client`,
    {
        "wa_id": chat_id
    }
	);
	return res.data;
} 

export { updateClientInfo, getClientInfo }