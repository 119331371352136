import { axiosConfig } from "./axios.config";

const searchPreviews = async (
  agent_id,
  page_number,
  tags_filters = [],
  search_value = "",
  show_unread = 0,
  type,
  area = "",
  company,
  starred_filter,
  time_left_filter,
  products = []
) => {
  let result = [];
  if (tags_filters.length > 0) {
    result = tags_filters.map((tag) => tag.id);
  }
  const endpoint =
    area !== "" ? `${company}/${type}/${area}` : `${company}/${type}`;

  const res = await axiosConfig.post(
    `${process.env.REACT_APP_API_V3}/assign_previews/${endpoint}`,
    {
      agent_email: agent_id,
      previews_per_page: 10,
      agent_filter: [],
      order_desc: 0,
      page_number: page_number,
      starred_filter: starred_filter,
      unread_filter: show_unread ? 1 : 0,
      tags_filters: result,
      search_like: search_value,
      time_left_order: time_left_filter,
      products_filtered: products,
    }
  );
  return res.data;
};

const getAllTags = async (company) => {
  const res = await axiosConfig.get(
    `${process.env.REACT_APP_API_NEW_URL}/agent/${company}/get_all_tags`
  );
  return res.data;
};

const getAllProducts = async (company) => {
  const res = await axiosConfig.get(
    `${process.env.REACT_APP_API_NEW_URL}/products/${company}/all_products`
  );
  return res.data;
};

const archiveChatsSelected = async (
  agent_id,
  chatSelected,
  type,
  area,
  company
) => {
  const res = await axiosConfig.post(
    `${process.env.REACT_APP_API_NEW_URL}/${type}/${company}/archive_n_chats`,
    {
      area: area,
      agent_id: agent_id,
      chat_ids_to_archive: chatSelected,
    }
  );
  return res.data;
};

export { searchPreviews, getAllTags, archiveChatsSelected, getAllProducts };
