import { FileMultimedia } from "../components/MessageInput/Multimedia/Multimedia";
import { IoDocumentOutline } from "react-icons/io5";

export const debounce = (func: Function, delay: number) => {
  let timeoutId: NodeJS.Timeout;
  return (...args: any[]) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func.apply(null, args), delay);
  };
};

export function renderFilePreview(file: FileMultimedia) {
  const documentExtensions = [
    ".pdf",
    ".doc",
    ".docx",
    ".xls",
    ".xlsx",
    ".ppt",
    ".pptx",
  ];
  const imageExtensions = [".jpg", ".jpeg", ".png", ".gif", ".svg"];
  const audioExtensions = [".mp3", ".wav", ".ogg"];
  const videoExtensions = [".mp4", ".mov", ".avi"];

  if (documentExtensions.some((ext) => file.name.includes(ext))) {
    return (
      <div className="w-full flex justify-center items-center h-[130px]">
        <IoDocumentOutline className="text-3xl" />
      </div>
    );
  } else if (imageExtensions.some((ext) => file.name.includes(ext))) {
    return (
      <img className="w-full object-cover h-32" src={file.url} alt="Imagen" />
    );
  } else if (audioExtensions.some((ext) => file.name.includes(ext))) {
    return (
      <audio controls src={file.url} className="w-full">
        Tu navegador no soporta el elemento de audio.
      </audio>
    );
  } else if (videoExtensions.some((ext) => file.name.includes(ext))) {
    return (
      <video controls src={file.url} className="w-full h-32 object-cover">
        Tu navegador no soporta el elemento de video.
      </video>
    );
  } else {
    return <div>Tipo de archivo no soportado</div>;
  }
}
