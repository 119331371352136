import Axios from "axios";

const axiosConfig = Axios.create();

axiosConfig.interceptors.request.use(
  (config) => {
    const authToken = localStorage.getItem("token");
    config.headers["Authorization"] = "Bearer " + authToken;
    config.headers["Access-Control-Allow-Origin"] = '*';
    return config;
  },
  (error) => Promise.reject(error)
);

export { axiosConfig }