import { axiosConfig } from "./axios.config";

const api = 'https://source-tracker.letschatty.com'


const getWebsitesPreview = async (company) => {
  const res = await axiosConfig.get(`${api}/urls/all_websites_previews/${company}`);
  return res.data;
};

const createWebsites = async (data, company) => {
  const res = await axiosConfig.post(`${api}/urls/create/${company}`, data);
  return res.data;
}

const getGeneralView = async (id, company) => {
  const res = await axiosConfig.get(`${api}/urls/${company}/${id}`);
  return res.data;
};

const getMessages = async (id, company) => {
  const res = await axiosConfig.get(`${api}/messages/${company}/${id}`);
  return res.data;
};

const updateMessages = async (company, urlId, id, body) => {
  const res = await axiosConfig.put(`${api}/messages/${company}/${urlId}/${id}`, body);
  return res.data;
}

const updateGeneralView = async (id, company, body) => {
  const res = await axiosConfig.put(`${api}/urls/${company}/${id}`, body);
  return res.data;
};

const updateAutomation = async (company, id, body) => {
  const res = await axiosConfig.put(`${api}/automation/${company}/${id}`, body);
  return res.data;
};

const getAutomation = async (company, id) => {
  const res = await axiosConfig.get(`${api}/automation/${company}/${id}`);
  return res.data;
};

const getFlows = async (company) => {
  const res = await axiosConfig.get(`${api}/flows/${company}`);
  return res.data;
};

const getUtmCampaign = async (company) => {
  const res = await axiosConfig.get(`${api}/utm_campaigns/${company}`);
  return res.data;
};

const getAllUtmCampaigns = async (company, id, search) => {
  const res = await axiosConfig.get(`${api}/utm_campaigns/${company}/${id}?page=${search.page}&search_like=${search.value}&campaigns_per_page=${search.result_per_page}`);
  return res.data;
};

const postUtmCampaign = async (company, id, body) => {
  const res = await axiosConfig.post(`${api}/utm_campaigns/${company}/${id}`, body);
  return res.data;
};

const deleteUtmCampaign = async (company, urlId ,campaignId) => {
  const res = await axiosConfig.delete(`${api}/utm_campaigns/${company}/${urlId}/${campaignId}`);
  return res.data;
};

const updateUtmCampaign = async (company, urlId ,campaignId, body) => {
  const res = await axiosConfig.put(`${api}/utm_campaigns/${company}/${urlId}/${campaignId}`, body);
  return res.data;
};

const getSourcesType = async (company) => {
  const res = await axiosConfig.get(`${process.env.REACT_APP_API_V3}/sources/${company}`);
  return res.data;
}

const getSourcesCategories = async (company) => {
  const res = await axiosConfig.get(`${process.env.REACT_APP_API_V3}/sources/${company}/other_source_categories`);
  return res.data;
}

const getTopics = async (company) => {
  const res = await axiosConfig.get(`${process.env.REACT_APP_API_V3}/topics/${company}/topics`);
  return res.data;
}

const getSourcesSchema = async (company) => {
  const res = await axiosConfig.get(`${process.env.REACT_APP_API_V3}/sources/${company}/schema`);
  return res.data;
}

const getSourceCheckerList = async (source_checker) => {
  const res = await axiosConfig.get(`${process.env.REACT_APP_API_V3}/sources/${source_checker}/source_checkers`);
  return res.data;
}


const postSources = async (company, body) => {
  const res = await axiosConfig.post(`${process.env.REACT_APP_API_V3}/sources/${company}`, body);
  return res.data;
}

const putSources = async (company, body, sourceId) => {
  const res = await axiosConfig.put(`${process.env.REACT_APP_API_V3}/sources/${company}/${sourceId}`, body);
  return res.data;
}

const getSourceById = async (company, sourceId) => {
  const res = await axiosConfig.get(`${process.env.REACT_APP_API_V3}/sources/${company}/${sourceId}`);
  return res.data;
}

const deleteSourceById = async (company, sourceId, body) => {
  const res = await axiosConfig.delete(`${process.env.REACT_APP_API_V3}/sources/${company}/${sourceId}`, 
  {
    data: {
      agent_email: body,
    },
    headers: {
      'Content-Type': 'application/json'
    }
  });
  return res.data;
}

export { 
  getWebsitesPreview, 
  createWebsites, 
  getGeneralView, 
  updateGeneralView,
  getMessages,
  updateMessages,
  updateAutomation,
  getAutomation,
  getFlows,
  getUtmCampaign,
  getAllUtmCampaigns,
  postUtmCampaign, 
  deleteUtmCampaign, 
  updateUtmCampaign, 
  getSourcesType,
  getSourcesCategories,
  postSources,
  getSourcesSchema,
  getSourceById,
  putSources,
  deleteSourceById,
  getTopics,
  getSourceCheckerList
}