import React, { useContext, useEffect, useState } from "react";
import userContext from "../utils/userContext";
import Tags from "./Tags/Tags";
import { Tab } from "./Tab";
import { useQuery } from "@tanstack/react-query";
import { context } from "../interfaces";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { getChat } from "../services/ChatsActions.service";

interface Props {
  inboxToggle: boolean;
  setInboxToggle: React.Dispatch<React.SetStateAction<boolean>>;
  user: string;
  bgColor: string;
  setTagDropdownIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  tagDropdownIsOpen: boolean;
  isPanelDisabled: boolean;
  setRefetchPreview: React.Dispatch<React.SetStateAction<boolean>>;
  refetchPreview: any;
  setPanelToggle: React.Dispatch<React.SetStateAction<boolean>>;
  panelToggle: boolean;
}

const MyPanel: React.FC<Props> = ({
  user: userId,
  setPanelToggle,
  panelToggle,
  setTagDropdownIsOpen,
  tagDropdownIsOpen,
  bgColor,
  isPanelDisabled,
  setRefetchPreview, 
  refetchPreview,
  inboxToggle
}) => {
  const {agent, context, clientUser} = useContext(userContext) as context;

  const [activeTagsTab, setActiveTagsTab] = useState<number>(0);
  const [tags, setTags] = useState([])
  const [highlights, setHighlights] = useState([])
  const [isBroaderPreview, setIsBroaderPreview] = useState(false)
  const { 
    data: conversationData,
    refetch: refetchData,
   } = useQuery({
    queryFn: () => getChat(agent.company, agent.id, clientUser.conversation_id, context === 'admin' ? 'chats_manager' : 'agent_workspace' ),
    keepPreviousData: false,
    enabled: !!clientUser.conversation_id,
    onSuccess(data) {
      setHighlights(data.highlights)
      setTags(data.tags)
    }
  });

  useEffect(() => {
    if(clientUser?.conversation_id) {
      console.log('Actualizo los tags')
      refetchData()
    }else {
      setTags([])
      setHighlights([])
    }
    if(localStorage.getItem('is_broader_preview') === 'true'){
      setIsBroaderPreview(true)
    }else {
      setIsBroaderPreview(false)
    }
  }, [clientUser])

  return (
    <div className={`${panelToggle ? 'flex' : 'hidden'} md:flex h-full md:col-span-2 col-span-full flex-col gap-2 bg-[#212944] dark:bg-white`}>
      <div className={`flex justify-between items-center border-b border-[#2f3652] ${bgColor} py-[14px] px-6 min-h-[80px] md:h-[84px]`}>
        <button className="flex md:hidden" onClick={() => setPanelToggle(false)}>
          <AiOutlineArrowLeft style={{fontSize: 24, color: 'white'}} />
        </button>
        <p className="block md:hidden w-full text-center text-lg text-white">Panel de Chat</p>
      </div>
      {isPanelDisabled ? (
          <Tab highlights={[]} disabled={true} activeTagsTab={activeTagsTab} />
      ) : (
        <>
          <Tags
            isBroaderPreview={isBroaderPreview}
            conversationTags={tags}
            tagDropdownIsOpen={tagDropdownIsOpen}
            setTagDropdownIsOpen={setTagDropdownIsOpen}
            setActiveTagsTab={setActiveTagsTab}
            setRefetchPreview={setRefetchPreview}
            />
          
          <Tab 
            highlights={highlights}
            refetchData={refetchData}
            activeTagsTab={activeTagsTab} 
            isBroaderPreview={isBroaderPreview}
          />
        </>
      )}
    </div>
  );
};

export default MyPanel;
