import React, { useEffect, useState, useRef, useContext } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Button, Modal } from "flowbite-react";
import MultiSelect from "./MultiSelect";
import userContext from "../../utils/userContext";
import ModalParameters from "../ModalConfig/ModalParameters";
import ResponsePreview from "./ResponsePreview";
import EmojiPicker from "emoji-picker-react";
import "./Form.css";
import { actionTable } from "../../services/Tables.service";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ModalContent, Response } from "../Responses/ModalContent";
import { getResponse, postResponses } from "../../services/responses.service";

const RESPONSES_MODEL = {
  name: "",
  messages: [],
};

const TAGS_MODEL = {
  name: "",
  color: "",
  icon_name: "",
  description: "",
  category: "",
  is_visible: "",
  created_at: new Date(),
};

const PURE_ADS_MODEL = {
  ad_id: "",
  name: "",
  description: "",
  tags_id_and_name: [],
};
const OTHER_SOURCERS_MODEL = {
  name: "",
  description: "",
  tags_id_and_name: [],
};
const TRIGGERS_MODEL = {
  posibilidad: "",
  corte: "",
  other_source_id_and_name: [],
  pure_ad_id_and_name: [],
};
const IMPURE_ADS_MODEL = {
  url: "",
  pure_ad_id_and_name: [],
};

type Props = {
  tableSelected: string;
  action: string;
  selectedRow: any;
  tableGetData: () => Promise<void>;
  closeModal: (value: React.SetStateAction<boolean>) => void;
};

type valueData = {
  id: string;
  name: string;
};

interface TriggersType {
  posibilidad: string;
  corte: string;
  other_source_id_and_name: valueData[];
  pure_ad_id_and_name: valueData[];
}

const FormConfig: React.FC<Props> = ({
  tableSelected,
  action,
  selectedRow,
  tableGetData,
  closeModal,
}) => {
  const { agent } = useContext<any>(userContext);

  const [formType, setFormType] = useState(tableSelected);
  const [responsesForm, setResponsesForm] = useState<Response>(RESPONSES_MODEL);
  const [tagsForm, setTagsForm] = useState(TAGS_MODEL);
  const [pureAdsForm, setPureAdsForm] = useState(PURE_ADS_MODEL);
  const [impureAdsForm, setImpureAdsForm] = useState(IMPURE_ADS_MODEL);
  const [otherSourcersForm, setOtherSourcersForm] =
    useState(OTHER_SOURCERS_MODEL);
  const [triggersForm, setTriggersForm] =
    useState<TriggersType>(TRIGGERS_MODEL);
  const [allTags, setAllTags] = useState([]);
  const [listDropdown, setListDropdown] = useState([]);
  const [listDropdownPureAd, setListDropdownPureAd] = useState([]);

  const getResponseById = async (id: string, name: string) => {
    try {
      const data = await getResponse(agent.company, id);
      setResponsesForm({ name: name, messages: data.data.messages });
    } catch {}
  };

  useEffect(() => {
    if (action == "edit") {
      // Here the edit forms
      if (tableSelected === "tags") {
        setTagsForm(selectedRow);
      } else if (tableSelected === "pure_ads") {
        setPureAdsForm(selectedRow);
      } else if (tableSelected === "other_sources") {
        setOtherSourcersForm(selectedRow);
      } else if (tableSelected === "triggers") {
        setTriggersForm(selectedRow);
      } else if (tableSelected === "impure_ads") {
        setImpureAdsForm(selectedRow);
      } else if (tableSelected === "responses") {
        getResponseById(selectedRow.id, selectedRow.name);
      }
    } else {
      if (tableSelected === "responses") {
        setResponsesForm(RESPONSES_MODEL);
      } else if (tableSelected === "tags") {
        setTagsForm(TAGS_MODEL);
      } else if (tableSelected === "pure_ads") {
        setPureAdsForm(PURE_ADS_MODEL);
      } else if (tableSelected === "other_sources") {
        setOtherSourcersForm(OTHER_SOURCERS_MODEL);
      } else if (tableSelected === "triggers") {
        setTriggersForm(TRIGGERS_MODEL);
      } else if (tableSelected === "impure_ads") {
        setImpureAdsForm(IMPURE_ADS_MODEL);
      }
    }
  }, [action, selectedRow, tableSelected]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (tableSelected === "tags") {
      setTagsForm({ ...tagsForm, [e.target.name]: e.target.value });
    } else if (tableSelected === "pure_ads") {
      setPureAdsForm({ ...pureAdsForm, [e.target.name]: e.target.value });
    } else if (tableSelected === "other_sources") {
      setOtherSourcersForm({
        ...otherSourcersForm,
        [e.target.name]: e.target.value,
      });
    } else if (tableSelected === "triggers") {
      console.log("triggersForm: ", triggersForm);
      setTriggersForm({ ...triggersForm, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log("submit");
    let formToSend: any;
    switch (tableSelected) {
      case "responses":
        formToSend = {
          ...responsesForm,
        };

        break;
      case "tags":
        formToSend = { ...tagsForm };
        break;
      case "pure_ads":
        formToSend = { ...pureAdsForm };
        break;
      case "other_sources":
        formToSend = { ...otherSourcersForm };
        break;
      case "triggers":
        formToSend = { ...triggersForm };
        break;
      case "impure_ads":
        formToSend = { ...impureAdsForm };
        break;
      default:
        console.error("Tipo de formulario no reconocido");
        return;
    }

    // Enviar el formulario
    if (tableSelected === "responses") {
      sendResponses(formToSend);
    } else {
      actionTable(
        action,
        formToSend,
        tableSelected,
        selectedRow.id,
        agent.company
      ).then((data) => {
        tableGetData();
        closeModal(false);
      });
    }
  };

  const sendResponses = async (formData: Response) => {
    try {
      const data = await postResponses(
        formData,
        agent.company,
        action,
        selectedRow.id
      );
      toast.success("Response creada con exito");
    } catch {
    } finally {
      tableGetData();
      closeModal(false);
    }
  };

  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { m: 1, width: "100%" },
      }}
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
      className="form-config-container"
    >
      <div className="w-full gap-5 flex flex-col items-center">
        <div
          className={`${
            formType == "responses" && action != "delete" ? "w-full" : "w-full"
          }`}
        >
          {action !== "delete" && (
            <>
              {formType === "responses" && (
                <ModalContent
                  responsesForm={responsesForm}
                  setResponsesForm={setResponsesForm}
                />
              )}
              {formType === "tags" && (
                <>
                  <TextField
                    id="name"
                    label="Name"
                    name="name"
                    onChange={(e) => handleChange(e)}
                    value={tagsForm.name}
                    sx={{ marginLeft: "3px" }}
                    className="tagsTextField"
                  />
                  <TextField
                    id="color"
                    name="color"
                    label="Color"
                    onChange={(e) => handleChange(e)}
                    value={tagsForm.color}
                    className="tagsTextField"
                  />
                  <TextField
                    id="icon_name"
                    name="icon_name"
                    label="Icon name"
                    onChange={(e) => handleChange(e)}
                    value={tagsForm.icon_name}
                    className="tagsTextField"
                  />
                  <TextField
                    id="description"
                    name="description"
                    label="Descripcion"
                    onChange={(e) => handleChange(e)}
                    value={tagsForm.description}
                    className="tagsTextField"
                  />
                  <TextField
                    id="category"
                    name="category"
                    label="Category"
                    onChange={(e) => handleChange(e)}
                    value={tagsForm.category}
                    className="tagsTextField"
                  />
                </>
              )}
              {formType === "pure_ads" && (
                <>
                  <TextField
                    id="name"
                    label="Name"
                    name="name"
                    onChange={(e) => handleChange(e)}
                    value={pureAdsForm.name}
                  />
                  <TextField
                    id="description"
                    name="description"
                    label="Description"
                    onChange={(e) => handleChange(e)}
                    value={pureAdsForm.description}
                  />
                  <TextField
                    id="ad_id"
                    name="ad_id"
                    label="AD ID"
                    onChange={(e) => handleChange(e)}
                    value={pureAdsForm.ad_id}
                  />
                  {/* @ts-ignore */}
                  {allTags.length > 1 && (
                    <MultiSelect
                      data={allTags}
                      //@ts-ignore
                      defaultSelected={pureAdsForm.tags}
                      onchange={(value: string[]) =>
                        setPureAdsForm({
                          ...pureAdsForm,
                          //@ts-ignore
                          tags_id_and_name: value,
                        })
                      }
                    />
                  )}
                </>
              )}
              {formType === "other_sources" && (
                <>
                  <TextField
                    id="name"
                    label="Name"
                    name="name"
                    onChange={(e) => handleChange(e)}
                    value={otherSourcersForm.name}
                  />
                  <TextField
                    id="description"
                    name="description"
                    label="Description"
                    onChange={(e) => handleChange(e)}
                    value={otherSourcersForm.description}
                  />
                  {/* @ts-ignore */}
                  {allTags.length > 1 && (
                    <MultiSelect
                      data={allTags}
                      //@ts-ignore
                      defaultSelected={pureAdsForm.tags}
                      onchange={(value: string[]) =>
                        setOtherSourcersForm({
                          ...otherSourcersForm,
                          //@ts-ignore
                          tags_id_and_name: value,
                        })
                      }
                    />
                  )}
                </>
              )}
              {formType === "triggers" && (
                <>
                  <TextField
                    id="posibilidad"
                    name="posibilidad"
                    label="Posibilidad"
                    onChange={(e) => handleChange(e)}
                    value={triggersForm.posibilidad}
                  />
                  <TextField
                    id="corte"
                    name="corte"
                    label="Corte"
                    type="number"
                    onChange={(e) => handleChange(e)}
                    value={triggersForm.corte}
                  />
                  {listDropdown.length > 1 && (
                    //@ts-ignore
                    <MultiSelect
                      data={listDropdown}
                      name={"Other Sources"}
                      multiple={false}
                      disabled={
                        //@ts-ignore
                        triggersForm.pure_ad_id_and_name.id ? true : false
                      }
                      onchange={(value: valueData[]) =>
                        //@ts-ignore
                        setTriggersForm({
                          ...triggersForm,
                          other_source_id_and_name: value,
                        })
                      }
                    />
                  )}
                  {listDropdownPureAd.length > 1 && (
                    //@ts-ignore
                    <MultiSelect
                      data={listDropdownPureAd}
                      name={"Pure Ad"}
                      disabled={
                        //@ts-ignore
                        triggersForm.other_source_id_and_name.id ? true : false
                      }
                      multiple={false}
                      onchange={(value: valueData[]) =>
                        //@ts-ignore
                        setTriggersForm({
                          ...triggersForm,
                          pure_ad_id_and_name: value,
                        })
                      }
                    />
                  )}
                </>
              )}
              {formType === "impure_ads" && (
                <>
                  <TextField
                    id="url"
                    label="Url"
                    name="url"
                    onChange={(e) => handleChange(e)}
                    value={impureAdsForm.url}
                  />
                  {listDropdown.length > 1 && (
                    //@ts-ignore
                    <MultiSelect
                      multiple={false}
                      name={"Impure Ads"}
                      data={listDropdown}
                      onchange={(value: string[]) =>
                        setImpureAdsForm({
                          ...impureAdsForm,
                          //@ts-ignore
                          pure_ad_id_and_name: value,
                        })
                      }
                    />
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
      <Modal.Footer
        className="flex justify-center"
        style={{ paddingBottom: 0 }}
      >
        <Button className="bg-principalColor" type="submit">
          Confirmar
        </Button>
        <Button color="failure" onClick={() => closeModal(false)}>
          Cancelar
        </Button>
      </Modal.Footer>
      <ToastContainer
        toastStyle={{
          border: "1px solid white",
          backgroundColor: "#212944",
        }}
      />
    </Box>
  );
};

export default FormConfig;
