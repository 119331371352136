import { useState, useEffect } from "react";

interface SearchDropdownProps {
  items: Array<{ label: string; value: string }>;
  multipleChoice?: boolean;
  onChange: (value: string | Array<string>) => void;
  defaultValue?: string | Array<string>;
}

const SearchDropdown: React.FC<SearchDropdownProps> = ({
  items,
  multipleChoice = false,
  onChange,
  defaultValue = multipleChoice ? [] : "",
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedValues, setSelectedValues] = useState<Array<string>>(
    multipleChoice && Array.isArray(defaultValue)
      ? defaultValue
      : typeof defaultValue === "string"
      ? [defaultValue]
      : []
  );
  const [isOpen, setIsOpen] = useState(false);

  const getSelectedLabels = () => {
    return items
      .filter((item) => selectedValues.includes(item.value))
      .map((item) => item.label);
  };

  useEffect(() => {
    if (defaultValue) {
      onChange(multipleChoice ? selectedValues : selectedValues[0]);
    }
  }, []);

  const filteredItems = items.filter((item) =>
    item.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSelectItem = (itemValue: string) => {
    if (multipleChoice) {
      const newSelectedValues = selectedValues.includes(itemValue)
        ? selectedValues.filter((value) => value !== itemValue)
        : [...selectedValues, itemValue];

      setSelectedValues(newSelectedValues);
      onChange(newSelectedValues);
    } else {
      setSelectedValues([itemValue]);
      onChange(itemValue);
      setIsOpen(false);
    }
  };

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="search-dropdown relative">
      <input
        className="w-full h-10 border border-gray-300 rounded-md px-2 bg-mainBackground"
        type="text"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        onClick={handleToggleDropdown}
        placeholder={
          selectedValues.length > 0
            ? getSelectedLabels().join(", ")
            : "Buscar..."
        }
      />
      {isOpen && (
        <ul className="dropdown-list fixed w-full max-h-60 overflow-y-auto border border-gray-300 rounded-md mt-1 z-10 w-max bg-mainBackground">
          {filteredItems.map((item) => (
            <li key={item.value} className="p-2">
              {multipleChoice ? (
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    checked={selectedValues.includes(item.value)}
                    onChange={() => handleSelectItem(item.value)}
                    className="mr-2"
                  />
                  {item.label}
                </label>
              ) : (
                <span onClick={() => handleSelectItem(item.value)}>
                  {item.label}
                </span>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SearchDropdown;
