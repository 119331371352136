import { useState, useRef, useEffect, useContext } from "react";
import { BsMicFill, BsEmojiSmileFill, BsPaperclip } from "react-icons/bs";
import {
  AiOutlineDelete,
  AiOutlineCloseCircle,
  AiOutlineCheckCircle,
  AiOutlinePlayCircle,
  AiOutlinePauseCircle,
} from "react-icons/ai";
import { IoMdSend } from "react-icons/io";
import { toast } from "react-toastify";
import OpusMediaRecorder from "opus-media-recorder";

import "./MessageInput.css";
import userContext from "../../utils/userContext";
import { useVoiceVisualizer, VoiceVisualizer } from "react-voice-visualizer";
import ModalMultimedia from "./Multimedia/Multimedia";
import { IoMdClose } from "react-icons/io";
import { renderFilePreview } from "../../utils/helpers";
import FastAnswers from "../FastAnswers";
import { uploadFile } from "../../services/ChatMultimedia.service";
import emojiArr from "../emoji";
import { useDocumentClick } from "../../hooks/useDocumentClick";

// Detect if the browser is Safary
const isSafari =
  navigator.userAgent.indexOf("Safari") !== -1 &&
  navigator.userAgent.indexOf("Chrome") === -1;

window.OriginalMediaRecorder = MediaRecorder;

let safariRecorder;

// Default MediaRecorder formats
// Firefox => audio/ogg; codecs=opus
// Chrome => audio/webm;codecs=opus
// Safari => audio/mp4
window.MediaRecorder = function (stream, opt = {}, wkOptions = {}) {
  const opusRecorder = new OpusMediaRecorder(
    stream,
    Object.assign({}, opt, { mimeType: "audio/ogg" }),
    Object.assign({}, wkOptions, {
      encoderWorkerFactory: function () {
        return new Worker("/assets/opus-media-recorder/encoderWorker.umd.js");
      },
      OggOpusEncoderWasmPath: "/assets/opus-media-recorder/OggOpusEncoder.wasm",
      WebMOpusEncoderWasmPath:
        "/assets/opus-media-recorder/WebMOpusEncoder.wasm",
    })
  );

  if (isSafari) {
    safariRecorder = opusRecorder;

    safariRecorder.start();

    // Return the original MediaRecorder otherwise Safari
    // will not be able to decode the recorder audio
    return new window.OriginalMediaRecorder(stream, opt, wkOptions);
  }

  return opusRecorder;
};

const MessageInput = ({
  onSendMessage,
  setFastAnswersIsOpen,
  fastAnswersIsOpen,
  importedFilesSelected,
  setImportedFilesSelected,
}) => {
  const { agent } = useContext(userContext);

  const textareaRef = useRef(null);

  const [message, setMessage] = useState("");
  const [isSendingAudio, setIsSendingAudio] = useState(false);
  const [openMultimedia, setOpenMultimedia] = useState(false);
  const [audioRecordingMode, setAudioRecordingMode] = useState(false);
  const [playbackMode, setPlaybackMode] = useState(false);

  const [emojiDropdownIsOpen, setEmojiDropdownIsOpen] = useDocumentClick(
    ".emoji-dropdown-container",
    false
  );

  const recorderControls = useVoiceVisualizer({
    onStartRecording: () => {
      const canvas = document.querySelector("canvas");
      canvas.setAttribute("width", "auto");
      canvas.setAttribute("height", "auto");
      canvas.style.height = "150px !important";

      setAudioRecordingMode(true);
    },
    onStopRecording: () => {
      const canvas = document.querySelector("canvas");
      canvas.style.height = "40px !important";
      setPlaybackMode(true);
    },
    onClearCanvas: () => {
      setAudioRecordingMode(false);
      setPlaybackMode(false);
    },
  });

  const {
    recordedBlob,
    error,
    audioRef,
    isRecording,
    startRecording,
    stopRecording,
    clearCanvas,
  } = recorderControls;

  console.log("recorderControls: ", recorderControls);

  useEffect(() => {
    if (!recordedBlob) return;
  }, [recordedBlob, error]);

  const sendAudio = async () => {
    if (!recordedBlob) {
      return;
    }

    let oggAudio = recordedBlob;

    // Get ogg audio if is in Safary
    if (safariRecorder) {
      oggAudio = await new Promise((resolve) => {
        function dataavailable(e) {
          resolve(e.data);

          safariRecorder.removeEventListener("dataavailable", dataavailable);
        }

        safariRecorder.addEventListener("dataavailable", dataavailable);

        safariRecorder.requestData();
      });
    }

    setIsSendingAudio(true);

    try {
      const company = agent.company;
      const response = await uploadFile(oggAudio, company);

      if (response) {
        toast.success("¡Audio enviado correctamente!");
        onSendMessage(message, response);
      }
    } catch (error) {
      toast.error("Error al enviar el audio, por favor intenta de nuevo.");
    } finally {
      setAudioRecordingMode(false);
      setPlaybackMode(false);
      setIsSendingAudio(false);
    }
  };

  const sendMessage = () => {
    if (message.trim()) {
      onSendMessage(message);
      setMessage("");
      return;
    }
    if (importedFilesSelected !== 0) {
      onSendMessage(message);
    }
  };

  // KEY PRESS HANDLER

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      sendMessage();
    }
    if (event.ctrlKey && event.key === "Enter") {
      setMessage(message + "\n");
    }
  };

  const handleInputChange = (event) => {
    const text = event.target.value;
    if (text === "/") {
      setFastAnswersIsOpen(true);
      setMessage("");
    } else {
      setMessage(text);
    }
    if (textareaRef.current && text.length > 44) {
      auto_grow(textareaRef);
    } else if (textareaRef.current && text.length < 44) {
      textareaRef.current.style.maxHeight = `30vh`;
    }
  };

  function auto_grow(textareaa) {
    if (textareaa.current) {
      textareaa.current.style.height = "5px";
      if (textareaa.current.scrollHeight !== 0) {
        textareaa.current.style.height = textareaa.current.scrollHeight + "px";
      }
    }
  }

  useEffect(() => {
    auto_grow(textareaRef);
  }, [message]);

  const handleDeleteFile = (file) => {
    setImportedFilesSelected((prevSelected) => {
      if (prevSelected.includes(file)) {
        return prevSelected.filter((f) => f !== file);
      }
    });
  };

  return (
    <>
      {emojiDropdownIsOpen && (
        <div
          className={`absolute max-h-72 overflow-scroll grid overflow-x-hidden bottom-20 grid-rows-auto grid-cols-6 p-1 rounded-2xl rounded-bl-none bg-slate-50 list-none emoji-dropdown-container`}
        >
          {emojiArr.map((emoji, index) => (
            <li
              className="text-3xl cursor-pointer"
              key={index}
              onClick={() => setMessage(message.concat(emoji))}
            >
              {emoji}
            </li>
          ))}
        </div>
      )}
      <div className="flex flex-col w-full">
        <div className="w-full px-4">
          {importedFilesSelected.map((file) => {
            return (
              <div className="float-right mx-2 p-3 pt-7 rounded-t-lg bg-[#1a2238] w-[calc(25%-16px)] flex flex-col gap-3 relative">
                <button
                  className="absolute top-3 right-3"
                  onClick={() => handleDeleteFile(file)}
                  type="button"
                >
                  <IoMdClose className="text-[#acb8c0]" />
                </button>
                {renderFilePreview(file)}
                <span className="text-sm text-[#acb8c0] w-full overflow-hidden text-ellipsis whitespace-nowrap">
                  {file.name}
                </span>
              </div>
            );
          })}
        </div>
        <div className="w-full flex bg-[#1a2238] px-6 py-5 rounded-t-lg items-end">
          {!audioRecordingMode && !playbackMode && (
            <>
              <FastAnswers
                updateInput={setMessage}
                fastAnswersIsOpen={fastAnswersIsOpen}
                setFastAnswersIsOpen={setFastAnswersIsOpen}
              />
              <button
                className="bg-none border-none px-2 pb-2"
                onClick={() => setOpenMultimedia(true)}
                type="button"
              >
                <BsPaperclip className="text-white text-2xl" />
              </button>
            </>
          )}

          {openMultimedia && (
            <ModalMultimedia
              show={openMultimedia}
              onClose={() => setOpenMultimedia(false)}
              importedFilesSelected={setImportedFilesSelected}
            />
          )}

          {isSendingAudio ? (
            <div className="sending-audio-indicator">
              <span className="text-white mr-2">
                No toques nada, estamos enviando tu audio🔥...
              </span>
            </div>
          ) : (
            <>
              {audioRecordingMode && !playbackMode && (
                <p className="text-white w-1/2 text-right mr-3 text-sm pb-2">
                  Grabando...
                </p>
              )}
              {playbackMode && (
                <>
                  <p className="text-white w-1/2 text-right mr-3 text-sm pb-2">
                    Mensaje de voz
                  </p>
                  <button onClick={clearCanvas} type="button" className="pb-2">
                    <AiOutlineDelete className="text-white text-2xl" />
                  </button>
                </>
              )}
              <div
                className={`
                  ${audioRecordingMode ? "block" : "hidden"} 
                  flex items-center justify-between w-full px-4 bg-greyBackground rounded-lg mx-2 min-h-[40px]`}
              >
                <VoiceVisualizer
                  ref={audioRef}
                  controls={recorderControls}
                  barWidth={2}
                  gap={1}
                  isControlPanelShown="false"
                  mainContainerClassName={
                    "flex text-white items-center justify-center flex-row-reverse w-full"
                  }
                  canvasContainerClassName={
                    "!ml-3 max-h-[30px] w-full flex items-center justify-center"
                  }
                  progressIndicatorTimeClassName={"text-white"}
                  width="100%"
                  height={"auto"}
                />
              </div>
              {!audioRecordingMode && (
                <div className="flex items-end justify-between py-1 px-4 bg-greyBackground w-full rounded-lg mx-2 min-h-[40px] max-h-none">
                  <>
                    <button
                      className="border-none bg-none hidden md:block mb-[6px]"
                      type="button"
                      disabled={isRecording}
                      onClick={(e) => {
                        e.stopPropagation();
                        setEmojiDropdownIsOpen(true);
                      }}
                    >
                      <BsEmojiSmileFill className="text-white text-xl" />
                    </button>
                    <textarea
                      className="!p-0 !mb-[6px] bg-slate-400 !resize-none placeholder-white input-field md:text-base !text-white text-sm !border-none flex items-center !min-h-[20px] h-[20px]"
                      placeholder="Escribe un mensaje"
                      value={message}
                      onChange={(e) => handleInputChange(e)}
                      onKeyPress={handleKeyPress}
                      disabled={isRecording}
                      ref={textareaRef}
                      style={{ resize: "vertical", background: "none" }}
                    />
                  </>
                </div>
              )}

              {audioRecordingMode && !playbackMode && (
                <div className="pb-2 flex gap-2">
                  <button onClick={clearCanvas} type="button">
                    <AiOutlineCloseCircle className="text-[#f44336] text-2xl" />
                  </button>
                  <button onClick={stopRecording} type="button">
                    <AiOutlineCheckCircle className="text-green-400 text-2xl" />
                  </button>
                </div>
              )}
              {playbackMode && (
                <div className="pb-2 flex gap-2 px-2">
                  <button
                    type="button"
                    onClick={() => recorderControls.togglePauseResume()}
                  >
                    <AiOutlinePlayCircle className="text-white text-2xl" />
                  </button>
                  <button className="bg-none" onClick={sendAudio} type="button">
                    <IoMdSend className="text-2xl text-green-400" />
                  </button>
                </div>
              )}
            </>
          )}

          {(message || importedFilesSelected.length !== 0) && (
            <button
              className="bg-none px-2 pb-2"
              onClick={sendMessage}
              type="button"
            >
              <IoMdSend className="text-2xl text-green-400" />
            </button>
          )}
          {!audioRecordingMode && !message && !isSendingAudio && (
            <button
              className="bg-none px-2 md:block pb-2"
              onClick={() => startRecording()}
              type="button"
            >
              <BsMicFill className="text-2xl text-[#cacaca]" />
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default MessageInput;
