import { useContext, useEffect, useRef, useState } from "react";
import { ActionConditionType } from "../../../interfaces/actionCondition.interface";
import { Modal } from "flowbite-react";
import {
  Action,
  ActionDataObject,
  WorkflowAction,
} from "../../../interfaces/action.interface";
import {
  Condition,
  ConditionDataObject,
  ConditionRequestObject,
  WorkflowCondition,
} from "../../../interfaces/condition.interface";
import { ItemList } from "./ItemList";
import { ViewCondition } from "./ViewCondition";
import { ViewAction } from "./ViewAction";
import { EditCreateCondition } from "./EditCreateCondition";
import {
  createCondition,
  duplicateCondition,
  updateCondition,
} from "../../../services/conditions.service";
import { EditCreateAction } from "./EditCreateAction";
import { createAction, updateAction } from "../../../services/actions.service";
import userContext from "../../../utils/userContext";
import { context } from "../../../interfaces";
import OpenModal from "../../OpenModal";
import { GiChoice } from "react-icons/gi";
import { FaCheck, FaRegCopy } from "react-icons/fa";
import { BsPlay } from "react-icons/bs";
import { AiOutlineEdit } from "react-icons/ai";

interface ActionConditionModalProps {
  type: ActionConditionType;
  item: WorkflowAction | WorkflowCondition | null;
  item_group: "conditions" | "actions" | "alternative_actions";
  mode: "view" | "edit" | "create";
  allow_add?: boolean;
  on_close: () => void;
  on_update: () => void;
  on_add: (
    item_group: "conditions" | "actions" | "alternative_actions",
    item: Action | Condition
  ) => void;
  on_delete: (type: ActionConditionType, item_id: string) => void;
  on_notification: (type: "success" | "error", message: string) => void;
}

export const ActionConditionModal: React.FC<ActionConditionModalProps> = ({
  type,
  item,
  item_group,
  mode,
  allow_add = false,
  on_close,
  on_update,
  on_add,
  on_delete,
  on_notification,
}) => {
  const { isModalOpen, setModalOpen, agent } = useContext(
    userContext
  ) as context;

  const [currentMode, setCurrentMode] = useState<"view" | "edit" | "create">(
    mode
  );
  const [_item, setItem] = useState<Condition | Action | null>(null);

  let clickedItem: Condition | Action | null = _item;

  const [isUpdated, setIsUpdated] = useState(true);

  const listContainerRef = useRef<HTMLDivElement>(null);

  let itemDataObject: ConditionDataObject | ActionDataObject =
    {} as ConditionDataObject;

  const editButtonsRef = useRef<HTMLDivElement>(null);

  const checkIfItemIsEdited = (
    item: ConditionDataObject | ActionDataObject
  ): boolean => {
    if (currentMode === "edit") {
      if (_item === null || item === null) return false;
    }
    if (type === ActionConditionType.CONDITION) {
      const dataObject = item as ConditionDataObject;
      const itemData = _item as Condition;

      if (currentMode === "create") {
        if (dataObject.name === undefined) {
          return false;
        }
        if (
          dataObject.name !== "" ||
          dataObject.description !== "" ||
          dataObject.comparison_value.type.length > 1
        ) {
          return true;
        } else {
          return false;
        }
      }

      if (
        JSON.stringify(itemData.comparison_value) !==
          JSON.stringify(dataObject.comparison_value) ||
        itemData.description !== dataObject.description ||
        itemData.objectExtractor !== dataObject.extractor?.extractor_type ||
        itemData.operator.value !== dataObject.operator ||
        itemData.title !== dataObject.name
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      const dataObject = item as ActionDataObject;
      const itemData = _item as Action;

      if (currentMode === "create") {
        if (dataObject.title === undefined) {
          return false;
        }
        if (dataObject.title !== "" || dataObject.type !== "") {
          return true;
        } else {
          return false;
        }
      }

      if (
        itemData.title !== dataObject.title ||
        itemData.type !== dataObject.type ||
        JSON.stringify(itemData.values) !== JSON.stringify(dataObject.values)
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  const handleDataEdit = (data: ConditionDataObject | ActionDataObject) => {
    itemDataObject = data;
    editButtonsRef.current?.setAttribute(
      "style",
      `display: ${checkIfItemIsEdited(data) ? "flex" : "none"}`
    );
  };

  const handleConditionUpdate = (dataObject: ConditionDataObject) => {
    const requestObject: ConditionRequestObject = {
      _id: _item?._id!,
      companyId: agent.company!,
      comparison_value: dataObject.comparison_value,
      description: dataObject.description,
      objectExtractor: dataObject.extractor?.extractor_type!,
      operator: dataObject.operator!,
      title: dataObject.name,
    };
    updateCondition(requestObject)
      .then(() => {
        on_notification("success", "Condición actualizada");
        setIsUpdated(true);
        setCurrentMode("view");
      })
      .catch(() => {
        on_notification("error", "No se pudo actualizar la condición");
      });
  };

  const handleConditionCreate = (dataObject: ConditionDataObject) => {
    const requestObject: ConditionRequestObject = {
      companyId: agent.company!,
      comparison_value: dataObject.comparison_value,
      description: dataObject.description,
      objectExtractor: dataObject.extractor?.extractor_type!,
      operator: dataObject.operator!,
      title: dataObject.name,
    };
    createCondition(requestObject)
      .then(() => {
        on_notification("success", "Condición creada");
        setIsUpdated(true);
        setCurrentMode("view");
      })
      .catch(() => {
        on_notification("error", "No se pudo crear la condición");
      });
  };

  const handleActionUpdate = (dataObject: ActionDataObject) => {
    const requestObject: ActionDataObject = {
      _id: _item?._id!,
      companyId: agent.company!,
      values: dataObject.values,
      title: dataObject.title,
      type: dataObject.type,
    };
    updateAction(requestObject)
      .then(() => {
        on_notification("success", "Acción actualizada");
        setIsUpdated(true);
        setCurrentMode("view");
      })
      .catch(() => {
        on_notification("error", "No se pudo actualizar la acción");
      });
  };

  const handleActionCreate = (dataObject: ActionDataObject) => {
    const requestObject: ActionDataObject = {
      companyId: agent.company!,
      values: dataObject.values,
      title: dataObject.title,
      type: dataObject.type,
    };
    createAction(requestObject)
      .then(() => {
        on_notification("success", "Acción creada");
        setIsUpdated(true);
        setCurrentMode("view");
      })
      .catch(() => {
        on_notification("error", "No se pudo crear la acción");
      });
  };

  const handleCancel = (selectedItem: Condition | Action | null = null) => {
    if (selectedItem) {
      clickedItem = selectedItem;
    } else {
      clickedItem = _item;
    }
    if (checkIfItemIsEdited(itemDataObject)) {
      setModalOpen({ ...isModalOpen, cancel: true });
    } else {
      confirmCancel();
    }
  };

  const confirmCancel = () => {
    setModalOpen({ ...isModalOpen, cancel: false });
    setItem(clickedItem);
    setCurrentMode("view");
  };

  const handleConfirm = () => {
    if (type === ActionConditionType.CONDITION) {
      const dataObject = itemDataObject as ConditionDataObject;
      if (currentMode === "edit") {
        handleConditionUpdate(dataObject);
      } else if (currentMode === "create") {
        handleConditionCreate(dataObject);
      }
    } else if (type === ActionConditionType.ACTION) {
      const dataObject = itemDataObject as ActionDataObject;
      if (currentMode === "edit") {
        handleActionUpdate(dataObject);
      } else if (currentMode === "create") {
        handleActionCreate(dataObject);
      }
    }
    setModalOpen({ ...isModalOpen, message: true });
    setItem(null);
  };

  const handleAdd = () => {
    if (_item) {
      on_add(item_group, _item);
    }
  };

  const handleDelete = () => {
    if (_item) {
      const itemId =
        type === ActionConditionType.CONDITION
          ? (_item as Condition)._id!
          : (_item as Action)._id!;
      on_delete(type, itemId);
      on_update();
    }
  };

  const duplicate = (condition: Condition) => {
    const requestObject: ConditionRequestObject = {
      objectExtractor: condition.objectExtractor,
      operator: condition.operator.value,
      comparison_value: condition.comparison_value,
      title: condition.title,
      companyId: condition.companyId,
      description: condition.description,
    };
    duplicateCondition(requestObject)
      .then(() => {
        on_notification("success", "Condición duplicada");
        setIsUpdated(true);
      })
      .catch(() => {
        on_notification("error", "No se pudo duplicar la condición");
      });
  };

  useEffect(() => {
    setModalOpen({ ...isModalOpen, deleteElement: false });
    setCurrentMode(mode);

    if (item) {
      const currentItem =
        type === ActionConditionType.CONDITION
          ? (item as WorkflowCondition).condition
          : (item as WorkflowAction).action;
      setItem(currentItem);
    }
  }, [mode]);

  const renderCancelModal = () => (
    <OpenModal
      bodyStyle={{ backgroundColor: "#212944" }}
      headerStyle={{ backgroundColor: "#212944" }}
      content="¿Está seguro que desea descartar los cambios?"
      value="cancel"
      btnValue="Confirmar"
      fn={() => {
        confirmCancel();
      }}
    />
  );

  const renderDeleteModal = () => (
    <OpenModal
      bodyStyle={{ backgroundColor: "#212944" }}
      headerStyle={{ backgroundColor: "#212944" }}
      content="¿Está seguro que desea eliminar el elemento?"
      value="deleteElement"
      btnValue="Confirmar"
      fn={handleDelete}
    />
  );

  const renderHeaderTitle = () => {
    return (
      <div className="flex items-center gap-4 text-3xl">
        {type === ActionConditionType.CONDITION ? (
          <>
            <GiChoice size={40}></GiChoice>
            Condiciones
          </>
        ) : (
          <>
            <BsPlay size={40}></BsPlay>
            Acciones
          </>
        )}
      </div>
    );
  };

  const renderViewCondition = () =>
    _item &&
    type === ActionConditionType.CONDITION && (
      <ViewCondition condition={_item as Condition} />
    );

  const renderViewAction = () =>
    _item &&
    type === ActionConditionType.ACTION && (
      <ViewAction action={_item as Action} />
    );

  const renderEditCreateCondition = (mode: "edit" | "create") =>
    type === ActionConditionType.CONDITION && (
      <EditCreateCondition
        condition={_item as Condition | null}
        mode={mode}
        emit_data={handleDataEdit}
        companyId={agent.company!}
      />
    );

  const renderEditCreateAction = (mode: "edit" | "create") =>
    type === ActionConditionType.ACTION && (
      <EditCreateAction
        action={_item as Action | null}
        mode={mode}
        emit_data={handleDataEdit}
      />
    );

  return (
    <Modal show={true} onClose={on_close} size={"7xl"}>
      {renderDeleteModal()}
      {renderCancelModal()}
      <Modal.Header
        style={{
          background: "#212944",
          border: "none",
          padding: "10px 10px 0px 1.5rem",
        }}
      >
        <div className="w-full text-white">{renderHeaderTitle()}</div>
      </Modal.Header>
      <Modal.Body
        style={{
          background: "#212944",
          padding: ".75rem 1.5rem",
          color: "#a6adbb",
        }}
      >
        <div className="flex flex-row w-full gap-4" style={{ height: "700px" }}>
          <div className="flex flex-col w-6/12">
            <div
              className="list overflow-auto"
              style={{ height: "85%" }}
              ref={listContainerRef}
            >
              <ItemList
                onSelect={(item) => {
                  if (currentMode !== "view") {
                    handleCancel(item);
                    return;
                  }
                  listContainerRef.current?.setAttribute(
                    "style",
                    "visibility: hidden;"
                  );
                  const currenTop = listContainerRef.current?.scrollTop;
                  setItem(item);
                  setTimeout(() => {
                    listContainerRef.current?.scrollTo({
                      top: currenTop,
                    });
                    listContainerRef.current?.setAttribute(
                      "style",
                      "viibility: visible;"
                    );
                  });
                  setCurrentMode("view");
                }}
                selectedId={_item?._id!}
                type={type}
                isUpdated={isUpdated}
                setIsUpdated={setIsUpdated}
              />
            </div>
            {currentMode == "view" && (
              <div
                className="flex flex-row justify-around items-center"
                style={{ height: "15%" }}
              >
                <button
                  className="btn"
                  style={{
                    backgroundColor: "#1ecab3",
                    color: "white",
                  }}
                  onClick={() => {
                    setCurrentMode("create");
                  }}
                >
                  Nueva{" "}
                  {type === ActionConditionType.CONDITION
                    ? "condición"
                    : "acción"}
                </button>
              </div>
            )}
          </div>

          <div className={"w-6/12 flex flex-col justify-around"}>
            <div className="overflow-auto p-2" style={{ height: "85%" }}>
              {currentMode === "view" && renderViewCondition()}
              {currentMode === "view" && renderViewAction()}
              {currentMode === "edit" && renderEditCreateCondition("edit")}
              {currentMode === "edit" && renderEditCreateAction("edit")}
              {currentMode === "create" && renderEditCreateCondition("create")}
              {currentMode === "create" && renderEditCreateAction("create")}
            </div>
            <div
              className="flex flex-row justify-center items-center gap-4"
              style={{ height: "15%" }}
            >
              {currentMode === "view" && _item && (
                <>
                  <button
                    onClick={() => {
                      setCurrentMode("edit");
                    }}
                    className="btn gap-1"
                    style={{
                      backgroundColor: "#576080",
                      color: "white",
                    }}
                  >
                    <AiOutlineEdit></AiOutlineEdit> Editar
                  </button>
                  {type === ActionConditionType.CONDITION && (
                    <button
                      className="btn gap-1"
                      onClick={() => {
                        duplicate(_item as Condition);
                      }}
                      style={{
                        backgroundColor: "#576080",
                        color: "white",
                      }}
                    >
                      <FaRegCopy></FaRegCopy>
                      Duplicar
                    </button>
                  )}

                  {allow_add && (
                    <button
                      onClick={handleAdd}
                      className="btn gap-1"
                      style={{
                        backgroundColor: "#1ecab3",
                        color: "white",
                      }}
                    >
                      <FaCheck></FaCheck>
                      Utilizar
                    </button>
                  )}

                  <button
                    onClick={() => {
                      setModalOpen({ ...isModalOpen, deleteElement: true });
                    }}
                    className="btn"
                    style={{
                      backgroundColor: "#ff5757",
                      color: "white",
                    }}
                  >
                    Eliminar
                  </button>
                </>
              )}
              {currentMode !== "view" && (
                <div ref={editButtonsRef} className="gap-5">
                  <button
                    onClick={() => handleCancel()}
                    className="hover:bg-red-500 text-white py-2 px-4 rounded"
                    style={{
                      backgroundColor: "#ff5757",
                    }}
                  >
                    Descartar cambios
                  </button>
                  <button
                    className="bg-principalColor hover:bg-cyan-500 text-white py-2 px-4 rounded"
                    onClick={handleConfirm}
                  >
                    Confirmar
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
