import { useEffect, useRef, useState } from "react";
import { ItemsContainer } from "./ItemsContainer";
import { EditWorkflow, Workflow } from "../../interfaces/workflow.interface";
import {
  ActionConditionType,
  FormattedActionCondition,
} from "../../interfaces/actionCondition.interface";
import { Action, WorkflowAction } from "../../interfaces/action.interface";
import {
  Condition,
  WorkflowCondition,
} from "../../interfaces/condition.interface";
import { ActionConditionModal } from "./ActionConditionModal/ActionConditionModal";
import { getWorkflow } from "../../services/workflows.service";
import { deleteCondition } from "../../services/conditions.service";
import { deleteAction } from "../../services/actions.service";
import { Switch } from "@mui/material";
import { FaRegCopy } from "react-icons/fa";
import { IoTrashOutline } from "react-icons/io5";
import { IoIosAdd } from "react-icons/io";
import { toast, ToastContainer } from "react-toastify";
import { AiOutlineEdit } from "react-icons/ai";
import { MdOutlineRoute } from "react-icons/md";

interface WorkflowPanelProps {
  workflow: Workflow;
  isEditMode: boolean;
  onDelete: (workflow: Workflow) => void;
  onDuplicate: () => void;
  onSave: (worflow: EditWorkflow) => void;
  onEditModeChange: (mode: boolean) => void;
}

interface EditedItems {
  conditions: EditedConditions[];
  actions: Array<{ id: string; index: number }>;
  alternative_actions: Array<{ id: string; index: number }>;
}

interface EditedConditions {
  id: string;
  index: number;
  inverse_for_skip_condition: boolean;
}

interface FormValues {
  title: string;
  description: string;
  visible: string;
}

interface Modal {
  mode: "view" | "edit" | "create";
  open: boolean;
  item?: WorkflowAction | WorkflowCondition | null;
  item_group?: "actions" | "conditions" | "alternative_actions";
  type?: ActionConditionType;
  allow_add?: boolean;
}

export const WorkflowPanel: React.FC<WorkflowPanelProps> = ({
  workflow,
  isEditMode,
  onDelete,
  onDuplicate,
  onSave,
  onEditModeChange,
}) => {
  const [currentWorkflow, setCurrentWorkflow] = useState<Workflow>({
    ...workflow,
  });

  const editForm = useRef<HTMLFormElement>({} as HTMLFormElement);
  const visibleCheckboxRef = useRef<HTMLInputElement>({} as HTMLInputElement);

  const [editedItems, setEditedItems] = useState<EditedItems>(
    {} as EditedItems
  );
  const [editMode, setEditMode] = useState(isEditMode);
  const [isEdited, setIsEdited] = useState(false);

  const [modal, setModal] = useState<Modal>({
    mode: "view",
    open: false,
    item: {} as WorkflowAction | WorkflowCondition,
    item_group: "actions",
    type: ActionConditionType.CONDITION,
    allow_add: false,
  });

  const updateWorkflow = async () => {
    const workflowId = currentWorkflow._id;
    const updatedWorkflow = await getWorkflow(workflowId);
    setCurrentWorkflow(updatedWorkflow.data);
    setTimeout(() => {
      setCurrentWorkflow(updatedWorkflow.data);
    }, 100);
  };

  const formatActionsToPut = (
    actions: FormattedActionCondition[]
  ): Array<{ id: string; index: number }> => {
    const formattedActions = actions.map((action) => {
      return {
        id: action.id,
        index: action.index,
      };
    });
    const actionsWithNewIndex = formattedActions.map((action, index) => {
      return {
        ...action,
        index: index,
      };
    });
    return actionsWithNewIndex;
  };

  const formatConditionsToPut = (
    conditions: FormattedActionCondition[]
  ): EditedConditions[] => {
    const formattedConditions = conditions.map(
      (condition) =>
        ({
          id: condition.id,
          inverse_for_skip_condition: condition.is_critical,
          index: condition.index,
        } as EditedConditions)
    );
    const conditionsWithNewIndex = formattedConditions.map(
      (condition, index) => {
        return {
          ...condition,
          index: index,
        };
      }
    );

    return conditionsWithNewIndex;
  };

  const updateEditedConditions = (
    updatedConditions: FormattedActionCondition[]
  ) => {
    const newCurrentWorkflowConditions = currentWorkflow.conditions.filter(
      (condition) => {
        return updatedConditions
          .map((c) => c.id)
          .includes(condition.condition._id);
      }
    );

    newCurrentWorkflowConditions.forEach((condition) => {
      condition.index = updatedConditions.find(
        (c) => c.id === condition.condition._id
      )?.index as number;
    });

    setCurrentWorkflow({
      ...currentWorkflow,
      conditions: newCurrentWorkflowConditions,
    });

    const updatedConditionsObject: EditedConditions[] =
      formatConditionsToPut(updatedConditions);

    setEditedItems({
      ...editedItems,
      conditions: updatedConditionsObject,
    });
  };

  const updateEditedActions = (
    updatedActions: FormattedActionCondition[],
    isAlternative = false
  ) => {
    const formattedActions = formatActionsToPut(updatedActions);
    const newActions = currentWorkflow[
      isAlternative ? "alternative_actions" : "actions"
    ].filter((action) =>
      formattedActions.map((a) => a.id).includes(action.action._id)
    );

    newActions.forEach((action) => {
      action.index = updatedActions.find((a) => a.id === action.action._id)
        ?.index as number;
    });

    setCurrentWorkflow({
      ...currentWorkflow,
      [isAlternative ? "alternative_actions" : "actions"]: newActions,
    });

    setEditedItems({
      ...editedItems,
      [isAlternative ? "alternative_actions" : "actions"]: formattedActions,
    });
  };

  const checkIsEdited = () => {
    const formValues = getFormValues();
    const editedFormValues =
      formValues.title !== workflow.title ||
      formValues.description !== workflow.description ||
      formValues.visible !== workflow.visible.toString();

    const editedActions =
      JSON.stringify(editedItems.actions) === undefined
        ? false
        : JSON.stringify(editedItems.actions.map((a) => a.id)) !==
          JSON.stringify(workflow.actions.map((a) => a.action._id));
    const editedAlternativeActions =
      JSON.stringify(editedItems.alternative_actions) === undefined
        ? false
        : JSON.stringify(editedItems.alternative_actions.map((a) => a.id)) !==
          JSON.stringify(workflow.alternative_actions.map((a) => a.action._id));
    const editedConditions =
      JSON.stringify(editedItems.conditions) === undefined
        ? false
        : JSON.stringify(
            editedItems.conditions.map((c) => {
              return {
                id: c.id,
                inverse_for_skip_condition: c.inverse_for_skip_condition,
              };
            })
          ) !==
          JSON.stringify(
            workflow.conditions.map((c) => {
              return {
                id: c.condition._id,
                inverse_for_skip_condition: c.inverse_for_skip_condition,
              };
            })
          );
    const isEdited =
      editedFormValues ||
      editedActions ||
      editedAlternativeActions ||
      editedConditions;

    setIsEdited(isEdited);
  };

  const handleToggleCritical = (condition_id: string) => {
    const newConditions = currentWorkflow.conditions.map((c) => ({
      ...c,
      inverse_for_skip_condition:
        c.condition._id === condition_id
          ? !c.inverse_for_skip_condition
          : c.inverse_for_skip_condition,
    }));

    setCurrentWorkflow({
      ...currentWorkflow,
      conditions: newConditions,
    });

    setEditedItems({
      ...editedItems,
      conditions: newConditions.map((c) => {
        return {
          id: c.condition._id,
          index: c.index,
          inverse_for_skip_condition: c.inverse_for_skip_condition,
        };
      }),
    });
    checkIsEdited();
  };

  const getFormValues = (): FormValues => {
    const formData = new FormData(editForm.current!);
    const formProps = Object.fromEntries(formData.entries());
    const formValues = {
      title: formProps.title,
      description: formProps.description,
      visible: formProps.visible ? "true" : "false",
    } as FormValues;
    return formValues;
  };

  const saveWorkflow = async () => {
    const formData = new FormData(editForm.current!);
    const formProps = Object.fromEntries(formData.entries());

    const isVisible = visibleCheckboxRef.current?.checked;

    const editedWorkflow: EditWorkflow = {
      ...workflow,
      actions:
        editedItems.actions ||
        workflow.actions.map((a) => {
          return {
            id: a.action._id,
            index: a.index,
          };
        }),
      alternative_actions:
        editedItems.alternative_actions ||
        workflow.alternative_actions.map((a) => {
          return {
            id: a.action._id,
            index: a.index,
          };
        }),
      conditions:
        editedItems.conditions ||
        workflow.conditions.map((c) => {
          return {
            id: c.condition._id,
            inverse_for_skip_condition: c.inverse_for_skip_condition,
          };
        }),
      ...formProps,
      visible: isVisible,
    };

    await onSave(editedWorkflow);
    setEditMode(false);
    await updateWorkflow();
  };

  const openModal = (
    item: WorkflowAction | WorkflowCondition | null,
    item_group: "actions" | "conditions" | "alternative_actions",
    type: ActionConditionType,
    mode: "view" | "edit" | "create",
    allow_add?: boolean
  ) => {
    setModal({
      mode: mode,
      open: true,
      item: item,
      item_group,
      type: type,
      allow_add: allow_add,
    });
  };

  const deleteItem = (type: ActionConditionType, item_id: string) => {
    if (type === "condition") {
      deleteCondition(item_id)
        .then((res) => {
          toast.success("Condición eliminada");
        })
        .catch((err) => {
          toast.error(err.response.data.detail.message || err.message);
        });
    } else {
      deleteAction(item_id)
        .then((res) => {
          toast.success("Acción eliminada");
        })
        .catch((err) => {
          toast.error(err.response.data.detail.message || err.message);
        });
    }

    updateWorkflow();
    setModal({ ...modal, open: false });
  };

  const addItemToWorkflow = (
    itemGroup: "conditions" | "actions" | "alternative_actions",
    item: Action | Condition
  ) => {
    let newItem = {};

    if (itemGroup === "conditions") {
      newItem = {
        condition: {
          _id: item._id,
          companyId: item.companyId,
          description: (item as Condition).description,
          explanation: item.explanation,
          title: item.title,
          comparison_value: (item as Condition).comparison_value,
          objectExtractor: (item as Condition).objectExtractor,
          extractor_label: (item as Condition).extractor_label,
          icon: (item as Condition).icon,
          operator: (item as Condition).operator,
        },
        inverse_for_skip_condition: false,
        index: currentWorkflow[itemGroup].length,
      };
    } else if (itemGroup === "actions" || itemGroup === "alternative_actions") {
      newItem = {
        action: {
          _id: item._id,
          companyId: item.companyId,
          explanation: item.explanation,
          title: item.title,
          icon: (item as Action).icon,
          type: (item as Action).type,
        },
        index: currentWorkflow[itemGroup].length,
      };
    }

    setCurrentWorkflow({
      ...currentWorkflow,
      [itemGroup]: [...currentWorkflow[itemGroup], newItem],
    });
    let newItems = currentWorkflow[itemGroup].map((c) =>
      itemGroup === "conditions"
        ? {
            id: (c as WorkflowCondition).condition._id,
            index: c.index,
            inverse_for_skip_condition: (c as WorkflowCondition)
              .inverse_for_skip_condition,
          }
        : {
            id: (c as WorkflowAction).action._id,
            index: c.index,
          }
    );

    if (editedItems[itemGroup]) {
      newItems = newItems.filter(
        (c) => editedItems[itemGroup].findIndex((ec) => ec.id === c.id) !== -1
      );
    }

    setEditedItems({
      ...editedItems,
      [itemGroup]: [...newItems, { id: item._id, index: newItems.length }],
    });
  };

  useEffect(() => {
    setCurrentWorkflow(workflow);
    setEditedItems({} as EditedItems);
    setIsEdited(false);
  }, [workflow]);

  useEffect(() => {
    if (editMode) {
      checkIsEdited();
    }
  }, [editedItems]);

  useEffect(() => {
    onEditModeChange(editMode);
    if (editMode) {
      setEditedItems({} as EditedItems);
    }
  }, [editMode]);

  return (
    <div className="bg-darkBackground text-white h-screen items-center pt-5 px-10 overflow-clip">
      {modal.open && (
        <ActionConditionModal
          item={modal.item!}
          item_group={modal.item_group!}
          type={modal.type!}
          mode={modal.mode}
          allow_add={modal.allow_add}
          on_close={() => setModal({ ...modal, open: false })}
          on_update={() => {
            updateWorkflow();
          }}
          on_add={(item_group, item) => {
            addItemToWorkflow(item_group, item);
            setModal({ ...modal, open: false });
          }}
          on_delete={(type, item_id) => deleteItem(type, item_id)}
          on_notification={(type: "success" | "error", message: string) => {
            toast[type](message);
          }}
        ></ActionConditionModal>
      )}
      <div className="header flex flex-row justify-between items-start px-6 ">
        <div className="w-full">
          <form ref={editForm} onChange={checkIsEdited}>
            <div className="flex flex-row items-center gap-4">
              <MdOutlineRoute size={80} />
              {!editMode ? (
                <div className="">
                  <p className="text-3xl">{workflow.title}</p>
                  <div className="">
                    {workflow.visible ? <p>Visible</p> : <p>No Visible</p>}
                  </div>
                </div>
              ) : (
                <div className="flex flex-row w-full gap-4 pr-6 items-center">
                  <input
                    defaultValue={workflow.title}
                    type="text"
                    placeholder="Nombre"
                    name="title"
                    className="input input-bordered w-full bg-mainBackground"
                  />
                  <div className="flex flex-row gap-2 items-center">
                    Visible
                    <Switch
                      name="visible"
                      inputRef={visibleCheckboxRef}
                      disabled={!editMode}
                      defaultChecked={currentWorkflow.visible}
                      color="primary"
                    />
                  </div>
                </div>
              )}
            </div>
            {editMode ? (
              <textarea
                defaultValue={workflow.description}
                placeholder="Descripción"
                name="description"
                className="resize-none textarea textarea-bordered textarea-md w-full !h-24 bg-mainBackground"
              ></textarea>
            ) : (
              <>
                <p className="text-xl mt-2">Descripción</p>
                <p className="text-xl text-gray-400">{workflow.description}</p>
              </>
            )}
          </form>
        </div>

        <div className="flex flex-row items-center gap-4">
          {!editMode && (
            <button
              className="btn gap-1"
              onClick={() => setEditMode(true)}
              style={{
                backgroundColor: "#1ecab3",
                color: "white",
              }}
            >
              <AiOutlineEdit />
              Editar
            </button>
          )}
          {!editMode && (
            <button
              className="btn gap-1"
              onClick={onDuplicate}
              style={{
                backgroundColor: "#576080",
                color: "white",
              }}
            >
              <FaRegCopy />
              Duplicar
            </button>
          )}
          {!editMode && (
            <button
              className="btn gap-1"
              onClick={() => onDelete(workflow)}
              style={{
                backgroundColor: "#ff5757",
                color: "white",
              }}
            >
              <IoTrashOutline />
              Eliminar
            </button>
          )}
          {editMode && (
            <button
              className="btn bg-[#1ecab3] disabled:bg-gray-500 disabled:text-gray-300"
              onClick={() => saveWorkflow()}
              disabled={!isEdited}
              style={{
                color: "white",
              }}
            >
              Guardar
            </button>
          )}
          {editMode && (
            <button
              className="btn"
              onClick={() => {
                setEditMode(false);
                updateWorkflow();
              }}
              style={{
                backgroundColor: "#ff5757",
                color: "white",
              }}
            >
              Cancelar
            </button>
          )}
        </div>
      </div>
      <div className="panels flex flex-row px-6 pt-10 gap-16">
        <div className="w-4/12 text-center">
          <ItemsContainer
            title="Condiciones"
            prop_items={currentWorkflow.conditions}
            type={ActionConditionType.CONDITION}
            edit_mode={editMode}
            on_update={(items) => {
              updateEditedConditions(items as FormattedActionCondition[]);
            }}
            on_toggle_critical={(condition_id) => {
              handleToggleCritical(condition_id);
            }}
            on_open_modal={(
              mode: "view" | "edit" | "create",
              id: string,
              allow_add?: boolean
            ) => {
              openModal(
                currentWorkflow.conditions.find(
                  (item) => item.condition._id === id
                )!,
                "conditions",
                ActionConditionType.CONDITION,
                mode,
                allow_add
              );
            }}
          />
          <div className="w-full text-center relative bottom-16">
            {editMode && (
              <button
                style={{
                  backgroundColor: "#1ecab3",
                  color: "white",
                }}
                className="btn gap-1"
                onClick={() =>
                  openModal(
                    null,
                    "conditions",
                    ActionConditionType.CONDITION,
                    "create",
                    true
                  )
                }
              >
                <IoIosAdd size={25} />
                Añadir condición
              </button>
            )}
          </div>
        </div>
        <div className="w-4/12 text-center">
          <ItemsContainer
            title="Plan A: Acciones"
            prop_items={currentWorkflow.actions}
            type={ActionConditionType.ACTION}
            edit_mode={editMode}
            on_update={(items) => {
              updateEditedActions(items as FormattedActionCondition[]);
            }}
            on_open_modal={(
              mode: "view" | "edit" | "create",
              id: string,
              allow_add?: boolean
            ) => {
              openModal(
                currentWorkflow.actions.find((item) => item.action._id === id)!,
                "actions",
                ActionConditionType.ACTION,
                mode,
                allow_add
              );
            }}
          />
          <div className="w-full text-center relative bottom-16">
            {editMode && (
              <button
                className="btn gap-1"
                style={{
                  backgroundColor: "#1ecab3",
                  color: "white",
                }}
                onClick={() =>
                  openModal(
                    null,
                    "actions",
                    ActionConditionType.ACTION,
                    "create",
                    true
                  )
                }
              >
                <IoIosAdd size={25} />
                Añadir acción
              </button>
            )}
          </div>
        </div>
        <div className="w-4/12 text-center">
          <ItemsContainer
            title="Plan B: Acciones"
            prop_items={currentWorkflow.alternative_actions}
            type={ActionConditionType.ACTION}
            edit_mode={editMode}
            on_update={(items) => {
              updateEditedActions(items as FormattedActionCondition[], true);
            }}
            on_open_modal={(
              mode: "view" | "edit" | "create",
              id: string,
              allow_add?: boolean
            ) => {
              openModal(
                currentWorkflow.alternative_actions.find(
                  (item) => item.action._id === id
                )!,
                "actions",
                ActionConditionType.ACTION,
                mode,
                allow_add
              );
            }}
          />
          <div className="w-full text-center relative bottom-16">
            {editMode && (
              <button
                className="btn gap-1"
                style={{
                  backgroundColor: "#1ecab3",
                  color: "white",
                }}
                onClick={() =>
                  openModal(
                    null,
                    "alternative_actions",
                    ActionConditionType.ACTION,
                    "create",
                    true
                  )
                }
              >
                <IoIosAdd size={25} />
                Añadir acción
              </button>
            )}
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={3500}
        toastStyle={{ 
          border: '1px solid white',
          backgroundColor: "#212944" 
				}}
        pauseOnFocusLoss
        pauseOnHover
        hideProgressBar
        newestOnTop={false}
        rtl={false}
        draggable
      />
    </div>
  );
};
