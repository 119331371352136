import { useContext, useEffect, useRef, useState } from "react";
import {
  Action,
  ActionDataObject,
  ActionType,
} from "../../../interfaces/action.interface";
import { MdAccessTime } from "react-icons/md";
import {
  getActionFieldOptions,
  getActionTypes,
} from "../../../services/actions.service";
import userContext from "../../../utils/userContext";
import { context } from "../../../interfaces";
import { renderIcon } from "../../../utils/icons";
import { Switch } from "@mui/material";
import { BsPlay } from "react-icons/bs";

interface EditCreateActionProps {
  action: Action | null;
  mode: "edit" | "create";
  emit_data: (data: ActionDataObject) => void;
}

interface FieldOptions {
  label: string;
  value: string;
  flow_description?: string;
}

export const EditCreateAction: React.FC<EditCreateActionProps> = ({
  action,
  mode,
  emit_data,
}) => {
  const { agent } = useContext(userContext) as context;

  const [dataObject, setDataObject] = useState<ActionDataObject>({
    title: "",
    companyId: "",
    type: "",
    values: {},
  });

  const multipleInputsForm = useRef<HTMLFormElement>(null);

  const [multipleInputsValues, setMultipleInputsValues] = useState<string[]>([
    "",
  ]);

  const [actionTypes, setActionTypes] = useState<Array<ActionType>>([]);

  const [selectedType, setSelectedType] = useState<string>("");
  const [typeData, setTypeData] = useState<ActionType>({} as ActionType);

  const [fieldOptions, setFieldOptions] = useState<FieldOptions[]>([]);

  const addValue = (key: string, value: any) => {
    setDataObject({
      ...dataObject,
      values: {
        ...dataObject.values,
        [key]: value,
      },
    });
  };

  const handleMultipleInputsChange = (e: any, optionName: string) => {
    const targetInput = e.target;
    const targetInputValue = targetInput.value;
    const targetInputIndex = parseInt(targetInput.id);

    //last input
    if (targetInputIndex === multipleInputsValues.length - 1) {
      if (targetInputValue !== "") {
        const newValues = [...multipleInputsValues, ""];
        setMultipleInputsValues(newValues);
      }
    }

    //middle input
    if (
      targetInputIndex > 0 &&
      targetInputIndex < multipleInputsValues.length - 1 &&
      targetInputValue === ""
    ) {
      targetInput.parentNode.remove();
      const newValues = [...multipleInputsValues];
      newValues.splice(targetInputIndex, 1);
      newValues.push("");
      setMultipleInputsValues(newValues);
    }

    const _multipleInputsForm = multipleInputsForm.current;
    const inputs = _multipleInputsForm?.getElementsByTagName("textarea");
    const values = Array.prototype.map
      .call(inputs, (input) => {
        if (input.value != "") return input.value;
      })
      .filter((value) => value);

    addValue(optionName, values);
  };

  const getTypes = async () => {
    const actionTypes = await getActionTypes();
    if (actionTypes) setActionTypes(actionTypes.data);
  };

  const getTypeData = async (type: string) => {
    const actionType_ = actionTypes.find(
      (actionType) => actionType.type === type
    );
    if (actionType_) {
      setTypeData(actionType_);
      if (actionType_.options_key) {
        getFieldOptions(actionType_.options_key);
      }
      checkForMultipleInputs(actionType_);
    }
  };

  const checkForMultipleInputs = (actionType: ActionType) => {
    if (actionType.fields) {
      const fields = actionType.fields;
      fields.forEach((field) => {
        if (field.values[0].type === "list_string") {
          const texts = action?.values[field.values[0].name] as string[];
          if (texts) {
            setMultipleInputsValues([...texts, ""]);
          } else {
            setMultipleInputsValues([""]);
          }
        }
      });
    }
  };

  const handleChangeActionType = async (actionType: string) => {
    setSelectedType(actionType);
    await setDataObject({
      ...dataObject,
      values: {},
      type: actionType,
    });
  };

  const addComposedValue = (value: {}) => {
    setDataObject({
      ...dataObject,
      values: {
        ...dataObject.values,
        ...value,
      },
    });
  };

  const handleChangeActionFieldOption = async (
    key: string,
    _optionValue: string
  ) => {
    const optionValue = {
      [key]: _optionValue,
    };
    addComposedValue(optionValue);
  };

  const getFieldOptions = async (actionOptionsId: string) => {
    const fieldOptions = (await getActionFieldOptions(
      actionOptionsId,
      agent.company
    )) as any;
    if (fieldOptions) setFieldOptions(fieldOptions.data);
  };

  useEffect(() => {
    getTypeData(selectedType);
  }, [selectedType]);

  useEffect(() => {
    emit_data(dataObject);
  }, [dataObject]);

  const setObject = async () => {
    await getTypes();
    setDataObject({
      ...dataObject,
      title: mode === "edit" ? action?.title! : "",
      type: mode === "edit" ? action?.type! : "",
      companyId: action?.companyId!,
      values: mode === "edit" ? action?.values! : {},
    });
    setSelectedType(mode === "edit" ? action?.type! : "");
  };

  useEffect(() => {
    setObject();
  }, [action]);

  return (
    <>
      {action !== null || mode === "create" ? (
        <div className="flex flex-col gap-2">
          <div className="header flex flex-col items-center justify-start gap-2 w-full text-md">
            <label className="form-control w-full">
              <div className="label">
                <span className="label-text">Nombre</span>
              </div>
              <input
                type="text"
                defaultValue={mode === "edit" ? action!.title : ""}
                className="input input-bordered w-full bg-mainBackground"
                onInput={(e) => {
                  setDataObject({
                    ...dataObject,
                    title: e.currentTarget.value,
                  });
                }}
              />
            </label>
            <div className="w-full flex flex-row items-center justify-start gap-2">
              <div className="avatar w-2/12 text-center">
                <div className="w-8/12 m-auto">
                  {renderIcon(
                    typeData?.icon!,
                    {
                      width: "100%",
                      height: "100%",
                    },
                    <BsPlay style={{ width: "100%", height: "100%" }}></BsPlay>
                  )}
                </div>
              </div>
              <div className="w-full">
                <label className="form-control w-full">
                  <div className="label">
                    <span className="label-text">Tipo de acción</span>
                  </div>
                  {actionTypes && (
                    <select
                      className="select select-bordered w-full bg-mainBackground"
                      value={selectedType}
                      onChange={(e) => {
                        handleChangeActionType(e.target.value);
                      }}
                    >
                      <option value="" disabled>
                        Selecciona tipo de acción
                      </option>
                      {actionTypes.map((actionType, index) => (
                        <option key={index} value={actionType.type}>
                          {actionType.label}
                        </option>
                      ))}
                    </select>
                  )}
                </label>
              </div>
            </div>
            <div className="w-full">
              {typeData.fields?.map((field, index) => (
                <div key={index} className="form-control w-full">
                  <label className="label">
                    <span className="label-text">{field.label}</span>
                  </label>
                  {field.options ? (
                    <>
                      {
                        <select
                          className="select select-bordered w-full bg-mainBackground"
                          onChange={(e) => {
                            handleChangeActionFieldOption(
                              field.values[0].name,
                              e.target.value
                            );
                          }}
                          value={dataObject.values[field.values[0].name] || ""}
                        >
                          <option value="" disabled>
                            Selecciona una opción
                          </option>
                          {fieldOptions?.map((option, indexY) => (
                            <option value={option.value} key={Math.random()}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      }
                    </>
                  ) : (
                    field.values?.map((value) => (
                      <>
                        {value.type === "string" && (
                          <input
                            type="text"
                            className="input input-bordered w-full bg-mainBackground"
                            value={dataObject.values[value.name]}
                            onInput={(e) => {
                              addValue(value.name, e.currentTarget.value);
                            }}
                          />
                        )}
                        {value.type === "list_string" && (
                          <form
                            ref={multipleInputsForm}
                            onInput={(event) => {
                              handleMultipleInputsChange(event, value.name);
                            }}
                          >
                            <div className="flex flex-col gap-2">
                              {multipleInputsValues &&
                                multipleInputsValues.map((input, index) => (
                                  <div
                                    className="flex flex-row gap-2 items-center"
                                    key={index}
                                  >
                                    <div className="size-2 bg-white rounded"></div>
                                    <textarea
                                      defaultValue={input}
                                      key={index}
                                      id={index.toString()}
                                      className={`input input-bordered w-full ${
                                        index ===
                                        multipleInputsValues.length - 1
                                          ? "bg-gray-700"
                                          : ""
                                      } bg-mainBackground`}
                                      rows={4}
                                    />
                                  </div>
                                ))}
                            </div>
                          </form>
                        )}
                        {value.type === "bool" && (
                          <>
                            <Switch
                              checked={dataObject.values[field.values[0].name]}
                              name={field.values[0].name}
                              color="primary"
                              onChange={(e) => {
                                addValue(value.name, e.target.checked);
                              }}
                            />
                          </>
                        )}
                      </>
                    ))
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
