import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ModalConfig from "../ModalConfig/ModalConfig";
import "./ActionsConfig.css";

type Props = {
  tableSelected: string;
  selectedRow?: {
    content: string;
    id: string;
    name: string;
    split_content: string[];
  };
  tableGetData: () => Promise<void>;
};

const ActionsConfig: React.FC<Props> = ({
  tableSelected,
  selectedRow,
  tableGetData,
}) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [action, setAction] = useState<string>("");

  const openModal = (action: string, state: boolean) => {
    setAction(action);
    setShowModal(state);
  };

  return (
    <>
      <div className="actions">
        <button className="btn-config" onClick={() => openModal("add", true)}>
          Agregar {tableSelected === "smart_messages" && "topic"}
        </button>
        {(tableSelected === "responses" ||
          tableSelected === "fuentes_de_origen" ||
          tableSelected === "smart_messages") && (
          <button
            className={`btn-config ${!selectedRow ? "disable" : ""}`}
            onClick={() => openModal("edit", true)}
          >
            Modificar {tableSelected === "smart_messages" && "topic"}
          </button>
        )}
        <button
          className={`btn-config btn-delete ${!selectedRow ? "disable" : ""}`}
          onClick={() => openModal("delete", true)}
        >
          Eliminar {tableSelected === "smart_messages" && "topic"}
        </button>
      </div>
      {showModal && (
        <ModalConfig
          selectedRow={selectedRow}
          tableSelected={tableSelected}
          show={showModal}
          action={action}
          onClose={() => setShowModal(false)}
          tableGetData={tableGetData}
        />
      )}
    </>
  );
};

export default ActionsConfig;
