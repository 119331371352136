import { GiChoice } from "react-icons/gi";
import { Condition } from "../../../interfaces/condition.interface";
import { renderIcon } from "../../../utils/icons";
import { getLabel } from "../../../services/conditions.service";
import { useEffect, useState } from "react";

interface ViewConditionProps {
  condition: Condition;
}

export const ViewCondition: React.FC<ViewConditionProps> = ({ condition }) => {
  const [labels, setLabels] = useState<string[]>([]);

  const getValueLabel = async (values: string[]) => {
    try {
      const labelPromises = values.map((value) =>
        getLabel(condition.companyId, condition.objectExtractor, value)
      );
      const responses = await Promise.all(labelPromises);
      setLabels(responses.map((response) => response.data));
    } catch (error) {
      setLabels(values);
    }
  };

  useEffect(() => {
    if (condition.comparison_value.type === "list_string") {
      if (condition.comparison_value.value instanceof Array) {
        getValueLabel(condition.comparison_value.value as string[]);
      } else {
        getValueLabel([condition.comparison_value.value as string]);
      }
    }
  }, [condition.comparison_value.value]);

  const getComparisonValue = () => {
    let value = "";
    if (condition.comparison_value.type === "list_string") {
      if (labels.length > 0) {
        value = labels.join(", ");
      } else if (condition.comparison_value.value instanceof Array) {
        value = (condition.comparison_value.value as string[]).join(", ");
      } else {
        value = condition.comparison_value.value as string;
      }
    } else if (condition.comparison_value.type === "boolean") {
      value = (condition.comparison_value.value as boolean)
        ? "Verdadero"
        : "Falso";
    } else if (condition.comparison_value.type === "time_before_now") {
      value = Number(condition.comparison_value.value) / 3600 + " horas";
    } else {
      value = (condition.comparison_value.value as Number).toString();
    }
    return value;
  };

  const fields: { title: string; value: string | string[] }[] = [
    {
      title: "Tipo de comparación",
      value: condition.operator.label || "",
    },
    {
      title: "Valor",
      value: getComparisonValue(),
    },
    {
      title: "Descripción",
      value: condition.description || "",
    },
  ];

  return (
    condition && (
      <div className="flex flex-col gap-2">
        <div className="header flex flex-row items-center justify-start gap-2">
          <div className="avatar w-2/12 text-center">
            <div className="w-9/12 m-auto">
              {renderIcon(
                condition.icon,
                { width: "100%", height: "100%" },
                <GiChoice style={{ width: "100%", height: "100%" }}></GiChoice>
              )}
            </div>
          </div>
          <div className="w-10/12">
            <p className="text-xl">{condition.title}</p>
            <p className="text-md">{condition.extractor_label}</p>
          </div>
        </div>
        <div className="flex flex-col gap-2 p-2">
          {fields.map((field, index) => (
            <div key={index} className="flex flex-col justify-start">
              <p className="text-xl">{field.title}</p>
              <p className="text-xl text-gray-500">{field.value}</p>
            </div>
          ))}
        </div>
      </div>
    )
  );
};
