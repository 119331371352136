import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function AgentAccordion({agents, removeAgent}) {


  return (
    <div className='w-[50%]'>
        {agents.map((i) => {
            return(
                <Accordion className='w-full'>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    >
                        <p className='text-xl'>{i.nombre}</p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className='text-base'>
                            <p className='font-bold	mr-2'>Email: <span className='font-thin'>{i.email}</span></p> 
                        </div>
                        <div className='text-base'>
                            <p className='font-bold	mr-2'>Es administrador: <span className='font-thin'>{i.is_admin ? 'Si' : 'No'}</span></p> 
                        </div>
                        <button className='button-admin-accordion' onClick={() => removeAgent(i._id)}>ELIMINAR</button>
                    </AccordionDetails>
                </Accordion>
            )
        })}
    </div>
  );
}
