import { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import NavBar from "../components/NavBar/NavBar";
import TableConfig from "../components/TableConfig/TableConfig";
import TableConfigAnalitycs from "../components/TableConfigAnalitycs/TableConfigAnalitycs";
import {
  getSmartMessagesTable,
  getSourcesTable,
  getTable,
} from "../services/Tables.service";
import userContext from "../utils/userContext";
import "./Config.css";

const Analytics: React.FC = () => {
  const params = useParams();
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { agent, setContext } = useContext(userContext)!;

  const getData = async () => {
    setLoading(true);
    try {
      if (params.table === "fuentes_de_origen") {
        const data = await getSourcesTable(agent.company);
        setTableData(data);
        setContext('agent');
      } else {
        const data = await getSmartMessagesTable(agent.company);

        setTableData(
          data.map((item: any) => {
            const { _id, ...rest } = item;
            return { ...rest, id: item._id };
          })
        );
      }
      setLoading(false);
    } catch {
      console.log("err");
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [params.table, agent.company]);

  return (
    <div className="min-h-screen w-screen font-sans items-center justify-center text-black bg-[#212944] overflow-auto">
      <NavBar />
      <div style={{ padding: "25px 35px" }}>
        <div className="title-config">
          <h2>
            Tabla de <b>{params.table?.toLowerCase().replace("_", " ")}</b>
          </h2>
        </div>
        <TableConfigAnalitycs
          data={tableData}
          loading={loading}
          tableGetData={getData}
        />
      </div>
    </div>
  );
};

export default Analytics;
