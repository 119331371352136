import { useContext, useEffect } from "react";
import userContext from "../utils/userContext";
import { useNavigate } from "react-router-dom";
import logo1 from "../images/Chatty_Logo01.png";
import logo3 from "../images/GualdAI_Logo03.png";
import OpenNotification from "../components/OpenNotification";
import { useAuth0 } from "@auth0/auth0-react";
import { validateCompany } from "../services/company.service";

const Home: React.FC = () => {
  const { setContext, setAgent, agent } = useContext<any>(userContext);
  const { logout, user } = useAuth0();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  const redirection = (value: string) => {
    setContext(value);
    window.localStorage.setItem("context", value);
    navigate("/inbox");
  };

  const checkUserCompany = async () => {
    try {
      const data = await validateCompany(user?.email);
      console.log("User company: ", data.company_identification);
      console.log("User permissions: ", data.agent_permissions);

      if (data.company_identification === null) {
        handleLogout();
      } else {
        window.localStorage.setItem("company_id", data.company_identification);
        setAgent({
          id: user?.email,
          fullName: user?.name,
          email: user?.email,
          company: data.company_identification,
          isAdmin: data.agent_permissions,
        });
      }
    } catch (e) {
      //@ts-ignore
      // toast.error(e.message)
      console.log("error en company");
    } finally {
    }
  };

  useEffect(() => {
    console.log("user: ", user);
    checkUserCompany();
  }, []);

  return (
    <div className="min-h-screen w-screen flex font-sans items-center justify-center text-black bg-white">
      <OpenNotification />
      <div className="md:w-[75rem] w-full md:h-[35rem] h-screen flex flex-col md:flex-row justify-center md:justify-between md:rounded-lg md:drop-shadow-lg md:shadow-lg">
        <div className="md:w-5/12 w-full md:h-full md:h-46 flex md:flex items-center justify-center md:bg-[#1B2238] md:rounded-l-lg md:rounded-tr-none flex-col">
          <img src={logo1} alt="Logo GualdAI" className="md:h-44 h-36" />
          <div className="flex items-center justify-center my-2">
            <p className="md:block text-lg text-[#0fb6a1] text-center font-bold md:text-2xl">
              Chatty
            </p>
          </div>
          <div className="items-center mt-1 hidden md:flex">
            <p className="text-white text-base font-medium mr-2">
              A product by
            </p>
            <img className="h-4" src={logo3} alt="Logo GualdAI" />
          </div>
        </div>
        <div className="py-0 md:px-4 px-11 flex flex-col justify-center md:w-[59.33%] h-auto md:h-full items-center bg-[white] md:rounded-r-lg md:rounded-bl-none mt-5 md:mt-0">
          <div
            className="flex justify-evenly w-full h-full"
            style={{ flexDirection: "column", justifyContent: "center" }}
          >
            <div className="w-full flex-col md:flex-row mb-[30px] flex md:justify-between">
              <button
                className="bg-white text-sm md:text-base md:w-[49%] mb-7 md:mb-0 w-full text-[#1B2238] border-[#1B2238] text-l py-3 px-6 md:py-4 md:px-8 rounded-1xl font-bold border hover:bg-[#0fb6a1] hover:border-[#0fb6a1] hover:text-white trainsition-all duration-300"
                onClick={() => redirection("agent")}
              >
                Mis chats
              </button>
              <button
                className="bg-white text-sm md:text-base text-[#1B2238] md:w-[49%] w-full border-[#1B2238] text-l py-3 px-6 md:py-4 md:px-8 rounded-1xl font-bold border hover:bg-[#0fb6a1] hover:border-[#0fb6a1] hover:text-white trainsition-all duration-300 "
                onClick={() => redirection("admin")}
              >
                Gestor de chats
              </button>
            </div>
            <div className="md:flex hidden mb-[30px] justify-between">
              <button
                style={{ width: "49%" }}
                className="bg-white text-[#1B2238] border-[#1B2238] text-l py-4 px-8 rounded-1xl font-bold w-60 border hover:bg-[#0fb6a1] hover:border-[#0fb6a1] hover:text-white trainsition-all duration-300 "
                onClick={() => navigate("/config/responses")}
              >
                Configurar bot
              </button>
              <button
                style={{ width: "49%" }}
                className="bg-white text-[#1B2238] border-[#1B2238] text-l py-4 px-8 rounded-1xl font-bold w-60 border hover:bg-[#0fb6a1] hover:border-[#0fb6a1] hover:text-white trainsition-all duration-300 "
                onClick={() => navigate("/plantillas")}
              >
                Plantillas
              </button>
            </div>
            <div className="md:flex hidden mb-[30px] justify-between gap-[2%]">
              <button
                style={{ width: "49%" }}
                className="w-50 bg-white text-[#1B2238] border-[#1B2238] text-l py-4 px-8 rounded-1xl font-bold w-60 border hover:bg-[#0fb6a1] hover:border-[#0fb6a1] hover:text-white trainsition-all duration-300 "
                onClick={() => navigate("/analytics/fuentes_de_origen")}
              >
                Analytics
              </button>
              {agent.isAdmin && (
                <button
                  style={{ width: "49%" }}
                  className="bg-white text-[#1B2238] border-[#1B2238] text-l py-4 px-8 rounded-1xl font-bold w-60 border hover:bg-[#0fb6a1] hover:border-[#0fb6a1] hover:text-white trainsition-all duration-300 "
                  onClick={() => navigate("/admin")}
                >
                  Agentes
                </button>
              )}
            </div>
            <div className="md:flex hidden mb-[30px] justify-between gap-[2%]">
              <button
                style={{ width: "49%" }}
                className="w-50 bg-white text-[#1B2238] border-[#1B2238] text-l py-4 px-8 rounded-1xl font-bold w-60 border hover:bg-[#0fb6a1] hover:border-[#0fb6a1] hover:text-white trainsition-all duration-300 "
                onClick={() => navigate("/products")}
              >
                Productos
              </button>
              <button
                style={{ width: "49%" }}
                className="bg-white text-[#1B2238] border-[#1B2238] text-l py-4 px-8 rounded-1xl font-bold w-60 border hover:bg-[#0fb6a1] hover:border-[#0fb6a1] hover:text-white trainsition-all duration-300 "
                onClick={() => navigate("/workflows")}
              >
                Workflows
              </button>
            </div>
          </div>
          <div className="flex w-full items-end justify-center ">
            <button
              className="text-[#1B2238] mt-8 md:mt-0 md:text-base text-sm mb-20 rounded-3xl font-bold hover:underline hover:text-[#0f443e]"
              onClick={handleLogout}
            >
              Cerrar Sesión
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
