import { ReactNode } from "react";

export interface ActionConditionProps {
  id: string;
  type: ActionConditionType;
  edit_mode?: boolean;
  title: string;
  subtitle: string;
  description?: string;
  icon: string;
  is_critical?: boolean;
  draggableElement?: ReactNode;
  on_remove?: (id: string) => void;
  on_toggle_critical?: (condition_id: string) => void;
  on_open_modal?: (
    type: "view" | "edit" | "create",
    id: string,
    prevent_add?: boolean
  ) => void;
}

export interface FormattedActionCondition {
  id: string;
  title: string;
  subtitle: string;
  description?: string;
  icon: string;
  is_critical?: boolean;
  index: number;
}

export enum ActionConditionType {
  ACTION = "action",
  CONDITION = "condition",
}
