import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Spinner } from "flowbite-react";

export const AuthenticationGuard = ({ component }) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <Spinner />
    ),
  });

  return <Component />;
};