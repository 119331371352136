import React from "react";
import { Spinner } from "flowbite-react";

const FullScreenLoader: React.FC<{ isLocal?: boolean }> = ({ isLocal }) => {
  return (
    <div
      style={{
        position: `${isLocal ? "relative" : "fixed"}`,
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: `${isLocal ? "transparent" : "rgba(0, 0, 0, 0.8)"}`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 9999,
      }}
    >
      <Spinner size="xl" />
    </div>
  );
};

export default FullScreenLoader;
