import moment from "moment-timezone";

type Highlight = {
  ID: string
  created_at: string
  creator_id: string
  description: string
  title: string
  updated_at: Date
}

interface Props {
  highlightsData: Highlight[]
}

export const Highlight: React.FC<Props> = ({highlightsData}) => {
  return (
    <div className="mx-2 mt-2 flex flex-col z-10 h-full">
      <div className="w-full flex flex-col bg-[#212944] pl-2">
        {highlightsData.map(
          (highlight: Highlight, index: number) => (
            <div key={index} className='item-highlight-new border-l border-white pl-5 relative pb-2'>
              <div className="w-2 h-2 rounded-full bg-white absolute top-2 left-[-4.5px]"></div>
              <span className="text-white text-sm">{moment(highlight.created_at).format("ll, LT") + " hs"}{" "}</span>
              <p className="text-white">{highlight.title}</p>
              <p className="text-[#acb8c0]">
                {highlight.description.split("\n").map((line, i) => (
                  <span key={i}>
                    {line}
                    <br />
                  </span>
                ))}  
              </p>
            </div>
          )
        )}
      </div>
    </div>
  );
};
