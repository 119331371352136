import { axiosConfig } from "./axios.config";
import axios from "axios";

export const getAllProducts = async (type, company) => {
    const res = await axios(`${process.env.REACT_APP_API_NEW_URL}/${type}/${company}/all_products`);
    return res.data;
};


export const addProductToChat = async (type, company, productId, chatId, agentId) => {
  try {
    const response = await axiosConfig.post(`${process.env.REACT_APP_API_V3}/chat_workspace/${company}/agent_workspace/product`, {
      agent_id: agentId,
      chat_id: chatId,
      product_id: productId
    });
    if (response.status !== 200) {
      throw new Error('Error al añadir el producto');
    }
  }
  catch (error) {
    if (error instanceof Error) {
      console.log(error.message, "Catch de añadir producto.");
    } else {
      console.log("Catch de añadir producto.");
    }
  }
}

export const deleteProductFromChat = async (company, productId, chatId, agentId) => {
  try {
    const response = await axiosConfig.delete(`${process.env.REACT_APP_API_V3}/chat_workspace/${company}/agent_workspace/product`,
     {
      data: {
        agent_id: agentId,
        chat_id: chatId,
        product_id: productId
      },
      headers: {
        'Content-Type': 'application/json'
      }
    });
    console.log(response);
    if (response.status !== 200) {
      throw new Error('Error al eliminar el producto');
    }
  } catch (error) {
    if (error instanceof Error) {
      console.log(error.message, "Catch de eliminar producto.");
    } else {
      console.log("Catch de eliminar producto.");
    }
  }
}




export const deleteProduct = async (type, company, productId, render) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_API_NEW_URL}/${type}/${company}/delete/${productId}`);
    if (response.status !== 200) {
      throw new Error('Error al eliminar el producto');
    }
  }
  finally {
    render()
  }
};

export const addProduct = async (type, company, newProduct, handleClose, render) => {
    const { _id, ...productData } = newProduct;
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_NEW_URL}/${type}/${company}/create`, productData);
    } catch (error) {
      if (error instanceof Error) {
        console.log(error.message, "Catch de añadir producto.");
      } else {
        console.log("Catch de añadir producto.");
      }
    } finally {
      handleClose();
      render()
    }
};

export const editProducts = async (type, company, productToEdit, handleClose, render) => {
    const { _id, ...updatedProductData } = productToEdit;
    try {
      await axios.post(`${process.env.REACT_APP_API_NEW_URL}/${type}/${company}/update/${_id}`, {_id, ...updatedProductData});
    } catch (error) {
      if (error instanceof Error) {
        console.log(error.message, "Catch de editar producto.");
      } else {
        console.log("Catch de editar producto.");
      }
    } finally {
      handleClose();
      render()
    }
};


