import { axiosConfig } from "./axios.config";

//visible_filter = false
const getWorkflows = (companyId) => {
  return axiosConfig.get(
    `${process.env.REACT_APP_API_COPILOT}/flows/${companyId}/?visible_filter=false`
  );
};

const getWorkflow = (workflowId) => {
  return axiosConfig.get(
    `${process.env.REACT_APP_API_COPILOT}/flows/full/${workflowId}/`
  );
};

const deleteWorkflow = (workflowId) => {
  return axiosConfig.delete(
    `${process.env.REACT_APP_API_COPILOT}/flows/${workflowId}/`
  );
};

const createWorkflow = (workflow) => {
  return axiosConfig.post(
    `${process.env.REACT_APP_API_COPILOT}/flows/`,
    workflow
  );
};

const editWorkflow = (workflow) => {
  return axiosConfig.put(
    `${process.env.REACT_APP_API_COPILOT}/flows/${workflow._id}/`,
    workflow
  );
};

const duplicateWorkflow = (workflow) => {
  const newWorkflow = {
    ...workflow,
    _id: undefined,
    title: `${workflow.title} - Copia`,
    actions: workflow.actions.map((a) => {
      return {
        index: a.index,
        id: a.action._id,
      };
    }),
    alternative_actions: workflow.alternative_actions.map((a) => {
      return {
        index: a.index,
        id: a.action._id,
      };
    }),
    conditions: workflow.conditions.map((c) => {
      return {
        index: c.index,
        id: c.condition._id,
        inverse_for_skip_condition: c.inverse_for_skip_condition,
      };
    }),
  };

  return createWorkflow(newWorkflow);
};

export {
  getWorkflows,
  getWorkflow,
  deleteWorkflow,
  createWorkflow,
  editWorkflow,
  duplicateWorkflow,
};
