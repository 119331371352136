import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { sendNumberRegistration } from '../services/Register.service';
import { useAuth0 } from '@auth0/auth0-react';
import logo1 from "../images/Chatty_Logo01.png";
import logo3 from "../images/GualdAI_Logo03.png";

function Register() {
  const [phone_number_id, setPhone_number_id] = useState<any>(null);
  const [waba_id, setWaba_id] = useState<any>(null);
  const [code, setCode] = useState<any>(null);
  const { logout, user } = useAuth0();

  useEffect(() => {
    if (phone_number_id && waba_id && code && user?.email) {
      sendNumberRegistration(phone_number_id, waba_id, code, user?.email, user?.name)
        .then((res) => {
          console.log(res);
          toast.success("Número registrado exitosamente");
        })
        .catch((err) => {
          console.log(err);
          toast.error("Error al registrar el número");
        });
      console.log(code, phone_number_id, waba_id);
    }
  }, [phone_number_id, waba_id, code, user?.email, user?.name]);

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  const loadFacebookSDK = () => {
    //@ts-ignore
    window.fbAsyncInit = function() {
          //@ts-ignore
      FB.init({
        appId: '203066455700677',
        cookie: true,
        xfbml: true,
        version: 'v19.0'
      });
    };

    (function(d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s); js.id = id;
          //@ts-ignore

      js.src = "https://connect.facebook.net/en_US/sdk.js";
          //@ts-ignore

      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  };

  const sessionInfoListener = (event: MessageEvent) => {
    if (event.origin !== "https://www.facebook.com") return;
    try {
      const data = JSON.parse(event.data);
      if (data.type === 'WA_EMBEDDED_SIGNUP') {
        if (data.event === 'FINISH') {
          const { phone_number_id, waba_id } = data.data;
          setPhone_number_id(phone_number_id);
          setWaba_id(waba_id);
        } else {
          const { current_step } = data.data;
          console.log('User cancelled the signup flow', current_step);
        }
      }
    } catch {
      console.log('Non JSON Response', event.data);
    }
  };

  function launchWhatsAppSignup() {
    toast.info("Por favor, espere mientras se carga el formulario de registro de WhatsApp.");
        //@ts-ignore

    FB.login(function (response) {
      if (response.authResponse) {
        const c = response.authResponse.code;
        setCode(c);
      } else {
        console.log('User cancelled login or did not fully authorize.');
      }
    }, {
      config_id: 3602517536637877,
      response_type: 'code',
      override_default_response_type: true,
      extras: {
        sessionInfoVersion: 2,
      }
    });
  }

  useEffect(() => {
    loadFacebookSDK();
  }, []);

  useEffect(() => {
    window.addEventListener('message', sessionInfoListener);
    return () => {
      window.removeEventListener('message', sessionInfoListener);
    };
  }, []);

  return (
    <div className="min-h-screen w-screen flex font-sans items-center justify-center text-black bg-white">
      <div className="md:w-[75rem] w-full md:h-[35rem] h-screen flex flex-col md:flex-row justify-center md:justify-between md:rounded-lg md:drop-shadow-lg md:shadow-lg">
        <div className="md:w-5/12 w-full md:h-full md:h-46 flex md:flex items-center justify-center md:bg-[#1B2238] md:rounded-l-lg md:rounded-tr-none flex-col">
          <img src={logo1} alt="Logo GualdAI" className="md:h-44 h-36" />
          <div className="flex items-center justify-center my-2">
            <p className="md:block text-lg text-[#0fb6a1] text-center font-bold md:text-2xl">
              Chatty
            </p>
          </div>
          <div className="items-center mt-1 hidden md:flex">
            <p className="text-white text-base font-medium mr-2">A product by</p>
            <img className="h-4" src={logo3} alt="Logo GualdAI" />
          </div>
        </div>
        <div className="py-0 md:px-4 px-11 flex flex-col justify-center align-center  md:w-[59.33%] h-auto md:h-full items-center bg-[white] md:rounded-r-lg md:rounded-bl-none mt-5 md:mt-0">
          <div className="flex justify-evenly w-full h-full" style={{ flexDirection: 'column', justifyContent: 'center' }}>
            <div className='w-full flex-col justify-center md:flex-row mb-[30px] flex '>
            <button 
              className="
                bg-white 
                text-[#1B2238] 
                border-2 
                border-[#1B2238] 
                text-lg 
                py-4 
                px-8 
                rounded-xl 
                font-semibold 
                w-60 
                hover:bg-[#0fb6a1] 
                hover:border-[#0fb6a1] 
                hover:text-white 
                transition 
                duration-300 
                ease-in-out 
                shadow-lg
              " 
              onClick={launchWhatsAppSignup}
            >
              Registrar número de WhatsApp
            </button>

              <div className='md:flex hidden mb-[30px] justify-between gap-[2%]'></div>
            </div>
            <div className="flex w-full items-end justify-center">
              <button className=" text-[#1B2238] font-semibold mt-8 md:mt-0 md:text-base text-sm mb-20 rounded-3xl hover:underline hover:text-[#0f443e]" onClick={handleLogout}>Cerrar Sesión</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
