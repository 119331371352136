import { TextField } from "@mui/material"
import { useContext } from "react";
import { AiOutlineWechat, AiFillRobot, AiFillInfoCircle, AiOutlineCheck} from "react-icons/ai";
import { context } from "../../interfaces";
import { updateGeneralView } from "../../services/Analytics.services";
import userContext from "../../utils/userContext";

type viewItem = {
    section_icon_id: string;
    status: string;
    title: string;
    long_message: string;
}

type DetailType = {
    id: string;
    url: string;
    name: string;
    status: string;
    general_view: viewItem[]
}

type Props = {
    detailData: DetailType | null | undefined,
    fields: {
        name: string;
        url: string;
    },
    handleChange: (e: any) => void,
    paramsId: string
}

const GeneralView: React.FC<Props>  = ({detailData, fields, handleChange, paramsId}) => {

    const { agent } = useContext(userContext) as context;

    //@ts-ignore
    const updateData = async (body) => {
        try {
        const data = await updateGeneralView(paramsId, agent.company, body)
        console.log('actualizado',data)
        }
        catch {

        }
    }

    const submitChanges = (value:string, field:string) => {
        const dataToSend = {...detailData, [field]: value}
        delete dataToSend.general_view
        delete dataToSend.status
        delete dataToSend.id
        //ts-ignore
        updateData(dataToSend)
      }
      

    return(
        <div>
            <div className='w-full p-3'>
              <h4 className='text-2xl text-grey'>Vista General</h4>
              <div className='flex gap-4 mt-10'>
              <div className='flex w-1/2 items-center'>
                  <TextField 
                      id="name"
                      name="name"
                      label={detailData?.name}
                      className='mb-3 w-full'
                      onChange={(e) => handleChange(e)}
                      value={fields.name}
                      // disabled
                    />
                    <div className='flex flex-col'>
                      <button className={'bg-[#53b6a7] rounded-full p-2 mb-2 ml-2'} style={
                        fields.name !== detailData?.name ? {background: '#53b6a7'} : {background: '#c8c8c8' , pointerEvents: 'none'}
                      }>
                        <AiOutlineCheck 
                          onClick={() => submitChanges(fields.name, 'name')}
                          style={ { color: 'white', fontSize: 15}}
                        />
                      </button>
                    </div>
                </div>
                <div className='flex w-1/2 items-center'>
                  <TextField 
                      id="url"
                      name="url"
                      label="URL de la pagina"
                      className='mb-3 w-full'
                      onChange={(e) => handleChange(e)}
                      value={fields.url}
                      // disabled
                  />
                  <div className='flex flex-col'>
                    <button className='bg-[#53b6a7] rounded-full p-2 mb-2 ml-2'
                    style={
                      fields.url !== detailData?.url ? {background: '#53b6a7'} : {background: '#c8c8c8' , pointerEvents: 'none'}
                    }
                    >
                      <AiOutlineCheck
                        onClick={() => submitChanges(fields.name, 'url')} 
                        style={ { color: 'white', fontSize: 15}}
                      />
                    </button>
                  </div>
                </div>
              </div>
              <h3 className='text-xl text-grey mb-5 mt-5'>Proximos pasos</h3>
              <ul>
                {detailData?.general_view.map((item, index) => {
                  return(
                    <li className='flex gap-4 mb-4'>
                      {index === 0 && <AiOutlineWechat  style={{fontSize: 42, color: '#53b6a7', width: 80}}/>}
                      {index === 1 && <AiFillRobot  style={{fontSize: 42, color: '#53b6a7', width: 80}}/>}
                      {index === 2 && <AiFillInfoCircle  style={{fontSize: 42, color: '#53b6a7', width: 80}}/>}

                    <div className='w-full'>
                      <p className='text-lg mb-3 text-[#4d4d4d]'>{index + 1}. {item.title}</p>
                      <span>{item.long_message}</span>
                    </div>
                  </li>
                  )
                })}
              </ul>
            </div>
        </div>
    )
}

export default GeneralView