import gtLogo from "../images/gualdaTraining-Logo.png";
import gLogo from "../images/gualdemy-logo.png";

interface Props {
  fullName: string;
  size: number;
  fontSize: string;
  photo?: string;
}

const ProfilePicture: React.FC<Props> = ({
  fullName,
  size,
  fontSize,
  photo,
}) => {
  const intials = fullName
    ?.split(" ")
    .map((name) => name[0])
    .join("")
    .toUpperCase();
  return (
    <div
      className={`flex relative rounded-full bg-principalColor text-${fontSize} text-white h-${size} items-center`}
    >
      <div className={`w-${size} text-center`}>{intials}</div>
      {photo && (
        <div className="absolute mt-9 ml-6 rounded-full bg-gray-300 w-6 p-1">
          <img
            src={photo}
            alt=""
          />
        </div>
      )}
    </div>
  );
};

export default ProfilePicture;
