import { TextField } from "@mui/material"
import { AiOutlineCheck } from "react-icons/ai"
import { Message } from "../../interfaces/analytics.interface"

type Props = {
	messages: Message[],
	handleChangeMessages: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: string) => void,
	updateMessagesData: (messageId: string) => Promise<void>
}

const MessagesView:React.FC<Props> = ({messages, handleChangeMessages, updateMessagesData}) => {

    return(
        <div className='w-full p-3'>
            <h4 className='text-2xl text-grey'>Mensajes</h4>
            <h3 className='text-xl text-grey mb-5 mt-5'>Son los mensajes con los que un usuario inicia la conversación en WhatsApp luego de hacer click en el botón de la página web.<br></br>
Escribe tres opciones que no difieran mucho entre sí, combinando distintos saludos, emojis y/o preguntas.<br></br>
Nuestro protocolo de atribución de leads usará inteligencia artificial para combinar estas alternativas y garantizar el trackeo preciso.</h3>
            <div className='flex flex-col gap-1 mt-10'>
            {messages.map((message, i) => 
                <div className='mb-6 flex items-center'>
                <TextField 
                    id="name"
                    name={`${message.id}`}
                    label={`Mensaje ${i + 1}`}
                    className=' w-full'
                    onChange={(e) => handleChangeMessages(e, message.id)}
                    key={message.id}
                    value={message.message}
                />
                <div className='flex flex-col'>
                    <button className='bg-[#53b6a7] rounded-full p-2 mb-2 ml-2'
                    onClick={() => updateMessagesData(message.id)}
                    style={
                        message?.change ? {background: '#53b6a7'} : {background: '#c8c8c8' , pointerEvents: 'none'}
                    }
                    >
                    <AiOutlineCheck 
                        style={ { color: 'white', fontSize: 15}}
                    />
                    </button>
                </div>
                </div>
            )}
            </div>
        </div>
    )
}

export default MessagesView