import "./Config.css"
import React, { useState, useEffect, useContext } from 'react';
import { Button, Card, CardContent, TextField, Typography, Grid, CircularProgress, Box, Container, IconButton, Fab, Modal, Fade, Backdrop, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import NavBar from '../components/NavBar/NavBar';
import userContext from "./../utils/userContext"
import {getAllProducts, editProducts, deleteProduct, addProduct} from '../services/products.service';
import { Product, Price, Parameter } from '../interfaces';

const ProductCard: React.FC<{ product: Product, requestDeleteProduct: (id: string) => void, onEdit: (product: Product) => void }> = ({ product, requestDeleteProduct, onEdit }) => {  
  const defaultColor = '#20cab4';

  const parameterEntries = product.parameters && Object.keys(product.parameters).length > 0
  ? Object.entries(product.parameters).map(([key, value]) => (
    <p key={key} style={{ backgroundColor: defaultColor }} className="text-gray-600 text-xs rounded m-1 p-1 w-auto">
    {key}
  </p>  
    ))
  : <p className="text-gray-600 text-xs">No hay parametros</p>;


  if (!product || !product.price) return null;
  return (
    <>    
    <Card className='h-full p-0' sx={{ width: 280, borderTop: `5px solid ${product.color}`, m: 2, boxShadow: 3, backgroundColor: 'white', color: 'white' }}>
      <CardContent className='h-28'>
        <p className="text-black mb-1 text-lg	">
          {product.name}
        </p>
        {product.information && (
          <Typography variant="body2" color="text.secondary">
            {product.information}
          </Typography>
        )}
        <p className="text-black text-sm" >
          ${product.price.ARS} / ${product.price.USD} usd
        </p>
        {parameterEntries && (
          <CardContent className="flex">
              {parameterEntries}
          </CardContent>
        )}
      </CardContent>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 10 }}>
        <IconButton onClick={() => onEdit(product)}>
          <EditIcon />
        </IconButton>

        <IconButton onClick={() => requestDeleteProduct(product._id)}>
          <DeleteIcon className='text-red-500' />
        </IconButton>

      </Box>
    </Card>
    </>
  );
};
  
const Products: React.FC = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [loadingProds, setLoadingProds] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [newProduct, setNewProduct] = useState<Product>({ _id: '', name: '', price: { ARS: 0, USD: 0 }, information: '', parameters: {}, color:'#20cab4' });
  const [open, setOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [deleteProductId, setDeleteProductId] = useState<string | null>(null);
  const [editProduct, setEditProduct] = useState<Product | null>(null);
  const [parameters, setParameters] = useState<{ key: string; value: string }[]>([{ key: '', value: '' }]);
  const { clientUser, agent, context } = useContext<any>(userContext);

  const handleOpen = (product: Product | null = null) => {
    const defaultColor = '#20cab4';
    setEditProduct(product);
  
    if (product) {
      const parameterArray = Object.entries(product.parameters).map(([key, value]) => ({ key, value }));
      setParameters(parameterArray);
    } else {
      setParameters([{ key: '', value: '' }]);
    }
  
    setNewProduct(product || { _id: '', name: '', price: { ARS: 0, USD: 0 }, information: '', parameters: {}, color: defaultColor });
    setOpen(true);
  };
  

  const handleClose = () => {
    setEditProduct(null);
    setOpen(false);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === 'ARS' || name === 'USD') {
      setNewProduct(prev => ({ ...prev, price: { ...prev.price, [name]: Number(value) } }));
    } else {
      setNewProduct(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleParameterChange = (index: number, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setParameters(prevParameters =>
      prevParameters.map((parameter, i) =>
        i === index ? { ...parameter, [name]: value } : parameter
      )
    );
  };
  
  const fetchProducts = async () => {
    setLoadingProds(true);     
    try {
      getAllProducts("products", agent.company)
      .then(data => {
        setProducts(Object.values(data));
      })
      .finally(() => {
        setLoadingProds(false)
      })
    } catch (error: any) {
      setError(error.message);
      setLoadingProds(false)
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const requestDeleteProduct = (productId: string) => {
    setDeleteProductId(productId);
    setConfirmOpen(true);
  };

  const onEdit = (product: Product) => {
    handleOpen(product);
  };

  const addParameterField = () => {
    setParameters(prevParameters => [...prevParameters, { key: '', value: '' }]);
  };

  const removeParameterField = (index: number) => {
    setParameters(prevParameters => prevParameters.filter((_, i) => i !== index));
  };

  const createProductObject = () => {
    const formattedParameters = parameters.reduce<{ [key: string]: string }>((acc, { key, value }) => {
      if (key && value) acc[key] = value;
      return acc;
    }, {}); 
  
    const productWithParameters: Product = {
      ...newProduct,
      parameters: formattedParameters,
    };
  
    if (editProduct) {
      editProducts("products", agent.company, productWithParameters, handleClose, fetchProducts);
    } else {
      addProduct("products", agent.company, productWithParameters, handleClose, fetchProducts);
    }
  };
  

  return (
    <div className='min-h-screen w-screen font-sans items-center justify-center text-black bg-white'>
      <NavBar />
      <div className="py-6 px-9 m-0 w-full">
        <Box className="h-screen" sx={{ backgroundColor: 'white', margin:"0px", width:"100%" }}>
          <div className="title-config w-full">
						<h2 className='text-white'>Productos</h2>
					</div>
          {
            loadingProds ? 
            (
              <div className='loading-table'>
                <CircularProgress color="success" />
              </div>
            )
            :
            (
              <Grid container className='h-60 w-full' spacing={2} justifyContent="center">
                {products.map((product) => (
                  <ProductCard key={product._id} product={product} requestDeleteProduct={requestDeleteProduct} onEdit={onEdit}/>
                ))}
              </Grid>
            )
          }
        </Box>

        
  
        {/* Botón flotante para añadir productos */}
        <Fab color="primary" aria-label="add" sx={{ position: 'fixed', bottom: 16, right: 16 }} onClick={() => handleOpen()}>
          <AddIcon />
        </Fab>
  
        {/* Modal para el formulario de añadir productos */}
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropProps={{
            timeout: 500,
          }}
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          <Fade in={open}>
            <Box sx={{ bgcolor: 'background.paper', boxShadow: 24, p: 4, borderRadius: 2 }}>
              <Typography color="text.secondary" variant="h6" component="h2" gutterBottom>
                Añadir Nuevo Producto
              </Typography>
              <Box component="form" noValidate autoComplete="off">
                <TextField
                  label="Nombre"
                  name="name"
                  value={newProduct.name}
                  onChange={handleInputChange}
                  sx={{ mb: 2 }}
                  fullWidth
                  required
                />
                <TextField
                  label="Precio ARS"
                  name="ARS"
                  type="text"
                  value={newProduct.price.ARS}
                  onChange={handleInputChange}
                  sx={{ mb: 2,  width: "49%", mr:"2%"}}
                  required
                />
                <TextField
                  label="Precio USD"
                  name="USD"
                  type="text"
                  value={newProduct.price.USD}
                  onChange={handleInputChange}
                  sx={{ mb: 2,  width: "49%"}}
                  required
                />
                <TextField
                  label="Información"
                  name="information"
                  value={newProduct.information}
                  onChange={handleInputChange}
                  multiline
                  rows={4}
                  fullWidth
                  sx={{ mb: 2 }}
                />
                <div className="mb-4 w-1/2">
                  <Typography color="text.secondary" component="p">Color del Producto</Typography>
                  <input
                    type="color"
                    name="color"
                    id="color"
                    value={newProduct.color || '#20cab4'}
                    onChange={handleInputChange}
                    className="mt-1 block w-full h-12 border-gray-300 rounded-md shadow-sm focus:border-teal-500 focus:ring-teal-500"
                    style={{padding: '0'}} 
                  />
                </div>
                <Typography color="text.secondary" variant="h6" component="p" gutterBottom>
                  Parametros
                </Typography>
                {parameters.map((parameter, index) => (
                  <Box key={index} sx={{ display: 'flex', mb: 2 }}>
                    <TextField
                      label="Key"
                      name="key"
                      value={parameter.key}
                      onChange={e => handleParameterChange(index, e)}
                      sx={{ mr: 1, flexGrow: 1 }}
                    />
                    <TextField
                      label="Value"
                      name="value"
                      value={parameter.value}
                      onChange={e => handleParameterChange(index, e)}
                      sx={{ mr: 1, flexGrow: 1 }}
                    />
                    <IconButton onClick={() => removeParameterField(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                ))}
                <Box sx={{ display: 'flex', mb: 2 }}>
                  <Button sx={{ color: 'text.secondary', width:"50%" }} startIcon={<AddIcon />} onClick={addParameterField}>
                    Añadir Parámetro
                  </Button>
                  <Button
                    sx={{ width: "50%" }}
                    variant="contained"
                    color="primary"
                    onClick={createProductObject} // Changed to call the new function
                  >
                    {editProduct ? 'Editar Producto' : 'Añadir Producto'}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Fade>
        </Modal>
        <Dialog
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirmar eliminación"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ¿Estás seguro de que deseas eliminar este producto?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmOpen(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={() => {
            if (deleteProductId) {
                deleteProduct("products", agent.company, deleteProductId, fetchProducts);
            }
            setConfirmOpen(false);
            }} color="primary" autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
      </div>
    </div>
  );
  
};

export default Products;
