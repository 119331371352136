import React from 'react';

function ViewAllSalesModal({ open, sales, onClose, allProducts }: any) {
  if (!open) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[999]">
      <div className="bg-white rounded-lg max-w-[40%] max-h-[80vh]  w-full overflow-y-auto shadow-xl">
        <div className="bg-teal-400 p-4 flex justify-between items-center">
          <h1 className="text-lg font-bold text-white">Detalle de Ventas</h1>
          <button onClick={onClose} className="text-white text-2xl font-bold">&times;</button>
        </div>
        <div className="p-4 space-y-4">
          {sales.map((sale: any) => (
            <div key={sale._id} className="p-4 bg-gray-50 rounded-lg shadow">
              <div className="flex justify-between items-center">
                <span className="text-sm font-semibold">Producto: {
                allProducts[sale.product_id] ? allProducts[sale.product_id].name : 'Producto'
                
                }</span>
                <span className="text-sm text-gray-500">{new Date(sale.created_at).toLocaleDateString()}</span>
              </div>
              <div className="mt-2">
                <p className="text-md text-teal-600">Cantidad: {sale.quantity}</p>
                <p className="text-md">Total: {sale.total_amount} {sale.currency}</p>
                <p className="text-md">Pagado: {sale.paid_amount} {sale.currency}</p>
                <p className="text-md">Cuotas: {sale.installments}</p>
                <p className="text-md">Observaciones: {sale.details.observation}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="bg-gray-100 p-4 rounded-b-lg flex justify-end">
          <button onClick={onClose} className="bg-teal-500 hover:bg-teal-600 text-white font-bold py-2 px-4 rounded">
            Cerrar
          </button>
        </div>
      </div>
    </div>
  );
}

export default ViewAllSalesModal;
