import React, { useContext, useEffect, useState} from 'react';
import { useParams } from "react-router-dom";
import NavBar from "../components/NavBar/NavBar";
import './Config.css'
import { AiFillHome, AiOutlineWechat, AiFillRobot, AiFillInfoCircle, AiOutlineSearch } from "react-icons/ai";
import { BsChevronRight } from "react-icons/bs";
import { TextField } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { deleteUtmCampaign, getAllUtmCampaigns, getAutomation, getFlows, getGeneralView, getMessages, getUtmCampaign, postUtmCampaign, updateAutomation, updateGeneralView, updateMessages, updateUtmCampaign } from '../services/Analytics.services';
import userContext from '../utils/userContext';
import { context } from '../interfaces';
import { toast, ToastContainer } from "react-toastify";
import Autocomplete from '@mui/material/Autocomplete';
import { getAllProducts, getAllTags } from '../services/Preview.service';
import Modal from '@mui/material/Modal';
import CampaignListItem from '../components/CampaignList';
import GeneralView from '../components/Analytics/GeneralView';
import MessagesView from '../components/Analytics/MessagesView';
import { AutomationForm, Campaign, Message, ProductFlow, Product, Tags } from '../interfaces/analytics.interface';
import { debounce } from '../utils/helpers';
import InputAdornment from '@mui/material/InputAdornment';
import { useScrollToBottom } from '../hooks/useScrollToBottom';
import AutomationView from '../components/Analytics/AutomationView';

const UTMFORM_BASE = {
  url: "",
  source: "",
  medium: "",
  campaign: "",
  term: "",
  content: "",
}

const CAMPAIGN_LIST = {
  campaigns: [],
  campaigns_per_page: 0,
  page: 0,
  total_pages: 0
}

type SearchCampaign = {
  page: number;
  value: string;
  result_per_page: number;
}

type viewItem = {
  section_icon_id: string;
  status: string;
  title: string;
  long_message: string;
}
type DetailType = {
  id: string;
  url: string;
  name: string;
  status: string;
  general_view: viewItem[]
}

type AditionalInfo = {
  details?: string,
  tags: Tags[]
}

type UtmForm = {
    _id?: string
    url: string;
    source: string;
    medium: string;
    campaign: string;
    term: string;
    content: string;
    aditional_info?: AditionalInfo;
}

type CampaignResponse = {
  campaigns: Campaign[],
  campaigns_per_page: number,
  page: number,
  total_pages: number,
  total?: number
}

const Detail: React.FC = () => {
  const { agent } = useContext(userContext) as context;

  const [optionSelected, setOptionSelected] = useState(1)
  const [fields, setFields] = useState({name: '', url: ''})
  const [messages, setMessages] = useState<Message[]>([])
  const [detailData, setDetailData] = useState<DetailType | null>()
  const [products, setProducts] = useState<Product[]>([])
  const [flows, setFlows] = useState<ProductFlow[]>([])
  const [tags, setTags] = useState<Tags[]>([])
  const [automationForm, setAutomationForm] = useState<AutomationForm>({
    product: {
      id: '',
      name: ''
    },
    flow: {
      id: '',
      name: ''
    },
    tags: [],
    highlight_description: ''
  })
  const [utmForm, setUtmForm] = useState<UtmForm>(UTMFORM_BASE)

  const [source, setSource] = useState([])
  const [addNewSource, setAddNewSource] = useState(false)

  const [medium, setMedium] = useState([])
  const [addNewMedium, setAddNewMedium] = useState(false)

  const [campaign, setCampaign] = useState([])
  const [addNewCampaign, setAddNewCampaign] = useState(false)
  const [loadingCampaign, setLoadingCampaign] = useState(false)

  const [openModal, setOpenModal] = useState(false);
  const [campaignsList, setCampaignsList] = useState<CampaignResponse>(CAMPAIGN_LIST)
  const [searchCampignData, setSearchCampaignData] = useState<SearchCampaign>({
    page: 1,
    value: '',
    result_per_page: 10
  })

  const { atBottom } = useScrollToBottom();

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

	const params = useParams()

  const getData = async () => {
		// setLoading(true)
		try {
			const data = await getGeneralView(params.id, agent.company)
			setDetailData(data)
      setFields({
        name: data.name,
        url: data.url
      })
			// setLoading(false)
      console.log(data)
		}
		catch {
			console.log('err')
			// setLoading(false)
		}
	}
  const getTagsData = async () => {
    const dataTags = await getAllTags(agent.company)
    setTags(dataTags)
  }

  const getProductData = async () => {
    const dataProducts = await getAllProducts(agent.company)
    setProducts(dataProducts)
  }

  const getFlowData = async () => {
    const dataFlows = await getFlows(agent.company)
    setFlows(dataFlows)
  }

  const getAutomationData = async () => {
    const data = await getAutomation(agent.company, params.id)
    setAutomationForm(data)
  }

	const getMessagesData = async () => {
		try {
			const data = await getMessages(params.id, agent.company)
      setMessages(data)
		}
		catch {

		}
	}

  const getUtmCapaignData = async () => {
    const data = await getUtmCampaign(agent.company)
    // setUtmForm(data)
    setSource(data.source)
    setMedium(data.medium)
    setCampaign(data.campaign)
  }

  const getAllCampaigns = async (search:SearchCampaign) => {
    setLoadingCampaign(true)
    try {
      const data = await getAllUtmCampaigns(agent.company, params.id, search)
      // console.log('campaignsList.campaigns: ', campaignsList.campaigns)
      console.log('data.campaigns: ', data.campaigns)
      let arrayCampaigns = campaignsList.campaigns.concat(data.campaigns)
      setLoadingCampaign(false)
      // const newListCampaign = arrayCampaigns.push(data.campaigns)
      console.log('arrayCampaigns: ', arrayCampaigns)
  
      setCampaignsList({...campaignsList, campaigns : arrayCampaigns, total_pages : data.total_pages, total: data.total})
    } catch{

    }
  }

  const sendCampaign = async (e:React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if(utmForm._id) {
      actionCampaignUtm('update', utmForm._id)
    } else {
      const data = await postUtmCampaign(agent.company, params.id, utmForm)
      toast.success(data.message, {
        autoClose: 2000,
      })
    }
      setUtmForm({
        url: "",
        source: "",
        medium: "",
        campaign: "",
        term: "",
        content: ""
      })
      handleCloseModal()
      setCampaignsList(CAMPAIGN_LIST)
      getAllCampaigns(searchCampignData)
  }

const updateMessagesData = async (messageId:string) => {
    let toBeSend = messages.find((msj) => msj.id === messageId)
    delete toBeSend?.change
    const data = await updateMessages(agent.company, params.id, messageId, toBeSend)
    console.log('mensajes actualizados', data)
    toast.success(data.message, {
      autoClose: 2000,
    })
    getMessagesData()
}
  const updateAutomationForm = async () => {
    const data = await updateAutomation(agent.company, params.id, automationForm)
    toast.success(data.message, {
      autoClose: 2000,
    })
    getAutomationData()
  }

  useEffect( () => {
    getData()
  }, [])

  useEffect ( () => {
    if(optionSelected === 2) {
      getMessagesData()
    }else if(optionSelected === 3) {
      getTagsData()
      // getProductData()
      getFlowData()
      getAutomationData()
    }else if (optionSelected === 4) {
      getTagsData()
      getUtmCapaignData()
      getAllCampaigns(searchCampignData)
    }
  }, [optionSelected])

  const handleChange = (e:any) => {

		setFields({...fields, [e.target.name] : e.target.value})
    
    if(e.target.name === 'producto' ) {
      setAutomationForm({...automationForm, product: {
        id: '',
        name: e.target.value
      }})
    }else if(e.target.name === 'flow') {
      setAutomationForm({...automationForm, flow: {
        id: '',
        name: e.target.value
      }})
    }
	}

  const handleChangeMessages = (e:any, id:string) => {
    let toBeUpdated = messages.map((message, i) => {
      if(message.id === id) {
        message.message = e.target.value
        message.change = true
        return message
      }
      else {
       return message
      }
    })

    setMessages(toBeUpdated)
  }

  const tagsChange = (e:any, values:any) => {
    const tagsToSend = values.map((value:Tags) => {
      return {
      id: value.id,
      name: value.name
      }
    })
    setAutomationForm({...automationForm, tags: tagsToSend})
  }

  const tagsCampaignChange = (e:any, values:any) => {
    const tagsToSend = values.map((value:Tags) => {
      return {
      id: value.id,
      name: value.name
      }
    })
    console.log('tags change: ', tagsToSend)
    setUtmForm({...utmForm, aditional_info: {
      tags: tagsToSend
    }})
  }

  const handleChangeCampaing = (e:any) => {
		setUtmForm({...utmForm, [e.target.name] : e.target.value})
  }
  //@ts-ignore
  const handleAutocompleteChangeCampaing = (e:any, values) => {
    console.log(' e.target.value: ',  values, e.target.id.replace(/-.*/,''))
    if(values === null){
      setUtmForm({...utmForm, [e.target.id.replace(/-.*/,'')] : values}) 
    }else {
      setUtmForm({...utmForm, [e.target.id.replace(/-.*/,'')] : values})
    }
  }

  useEffect( () => {
    setUtmForm({...utmForm, source : ''})
  }, [addNewSource])

  useEffect( () => {
    setUtmForm({...utmForm, medium : ''})
  }, [addNewMedium])

  useEffect( () => {
    setUtmForm({...utmForm, campaign : ''})
  }, [addNewCampaign])

  // Campaign List Functions

  const debouncedSearch = debounce((value: string) => {
    console.log('Buscar por:', value);
    getAllCampaigns({
      page: 1,
      value: value,
      result_per_page: 10
    })
  }, 1000);

  // Función que envuelve handleSearchBy con debounce
  const handleDebouncedSearch = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    debouncedSearch(e.target.value);
  };

  const copyToClipboard = (textToCopy:string) => {
    navigator.clipboard.writeText(textToCopy)
    toast.success("URL copiada!", {
        autoClose: 2000,
    })
  }
  const actionCampaignUtm = async (action:string, idCampaign:string) => {
    let data
    if(action === 'delete') {
      data = await deleteUtmCampaign(agent.company, params.id, idCampaign)
    }else {
      data = await updateUtmCampaign(agent.company, params.id, idCampaign, utmForm)
    }
    toast.success(data.message, {
      autoClose: 2000,
    })
    getAllCampaigns(searchCampignData)
    handleCloseModal()
  }

  const deleteCampaign = (id:string) => {
    actionCampaignUtm('delete', id)
  }

  const editCampaign = (id:string) => {
    handleOpenModal()
    const campaignSelected = campaignsList.campaigns.find((campaign) => campaign._id === id)
    if(campaignSelected) {
      setUtmForm({
        _id: campaignSelected._id,
        url: campaignSelected.url,
        source: campaignSelected.source,
        medium: campaignSelected.medium,
        campaign: campaignSelected.campaign,
        term: campaignSelected.term,
        content: campaignSelected.content,
      })
    }
  }


  useEffect(() => {
    console.log('cargar nuevas campañas 1 ')
    console.log('searchCampignData.page  ', searchCampignData.page )
    console.log('campaignsList.total', campaignsList)

    if(optionSelected === 4 && atBottom === true && searchCampignData.page < (campaignsList.total as number)) {
      console.log('cargar nuevas campañas 2')
      setSearchCampaignData({
        ...searchCampignData,
        page: searchCampignData.page + 1
      })
      getAllCampaigns({
        ...searchCampignData,
        page: searchCampignData.page + 1
      })
    }
  }, [atBottom]);

    return (
			<div className="min-h-screen w-screen font-sans items-center justify-center text-black bg-[white]">
				<NavBar />
        <div style={{padding: '25px 35px'}} className='flex relative'>
          {/* Sidebar */}
          <div className='w-2/6 '>
            <ul className='p-5'>
              <li 
                onClick={() => setOptionSelected(1)} 
                style={optionSelected === 1 ?  {background: '#53b6a7', color: 'white'} : {}}
                className='flex p-3 pl-5 pr-5 mb-4 rounded-xl shadow-lg  overflow-hidden bg-white w-full justify-between items-center cursor-pointer	hover:scale-[1.05] transition-transform	'
              >
                <div className='flex items-center'>
                  <div style={{width: 40, height: 40, background: '#65e0cd53', padding: 10, borderRadius: '50%' }} className='flex items-center justify-center'>
                    <AiFillHome  
                      style={ { color: optionSelected === 1 ? 'white' : '#53b6a7', fontSize: 22}}
                    />
                  </div>
                  <p 
                    className='text-base text-gray-700 ml-3'
                    style={ { color: optionSelected === 1 ? 'white' : ''}}
                  >Vista General</p>
                </div>
                <BsChevronRight 
                  style={ { color: optionSelected === 1 ? 'white' : '#8c8c8c', fontSize: 15}}
                />
              </li>
              <li 
                onClick={() => setOptionSelected(2)} 
                style={optionSelected === 2 ?  {background: '#53b6a7', color: 'white'} : {}}
                className='flex p-3 pl-5 pr-5 mb-4 rounded-xl shadow-lg  overflow-hidden bg-white w-full justify-between items-center cursor-pointer	hover:scale-[1.05] transition-transform	'
              >
                <div className='flex items-center'>
                  <div style={{width: 40, height: 40, background: '#65e0cd53', padding: 10, borderRadius: '50%' }} className='flex items-center justify-center'>
                    <AiOutlineWechat  
                      style={ { color: optionSelected === 2 ? 'white' : '#53b6a7', fontSize: 22}}
                    />
                  </div>
                  <p 
                    className='text-base text-gray-700 ml-3'
                    style={ { color: optionSelected === 2 ? 'white' : ''}}
                  >Mensajes</p>
                </div>
                <BsChevronRight 
                  style={ { color: optionSelected === 2 ? 'white' : '#8c8c8c', fontSize: 15}}
                />
              </li>
              <li 
                onClick={() => setOptionSelected(3)} 
                style={optionSelected === 3 ?  {background: '#53b6a7', color: 'white'} : {}}
                className='flex p-3 pl-5 pr-5 mb-4 rounded-xl shadow-lg  overflow-hidden bg-white w-full justify-between items-center cursor-pointer	hover:scale-[1.05] transition-transform	'
              >
                <div className='flex items-center'>
                  <div style={{width: 40, height: 40, background: '#65e0cd53', padding: 10, borderRadius: '50%' }} className='flex items-center justify-center'>
                    <AiFillRobot  
                      style={ { color: optionSelected === 3 ? 'white' : '#53b6a7', fontSize: 22}}
                    />
                  </div>
                  <p 
                    className='text-base text-gray-700 ml-3'
                    style={ { color: optionSelected === 3 ? 'white' : ''}}
                  >Automatizacion</p>
                </div>
                <BsChevronRight 
                  style={ { color: optionSelected === 3 ? 'white' : '#8c8c8c', fontSize: 15}}
                />
              </li>
              <li 
                onClick={() => setOptionSelected(4)} 
                style={optionSelected === 4 ?  {background: '#53b6a7', color: 'white'} : {}}
                className='flex p-3 pl-5 pr-5 mb-4 rounded-xl shadow-lg  overflow-hidden bg-white w-full justify-between items-center cursor-pointer	hover:scale-[1.05] transition-transform	'
              >
                <div className='flex items-center'>
                  <div style={{width: 40, height: 40, background: '#65e0cd53', padding: 10, borderRadius: '50%' }} className='flex items-center justify-center'>
                    <AiFillInfoCircle  
                      style={ { color: optionSelected === 4 ? 'white' : '#53b6a7', fontSize: 22}}
                      />
                  </div>
                  <p className='text-base text-gray-700 ml-3'
                    style={ { color: optionSelected === 4 ? 'white' : ''}}
                  >Campañas UTM</p>
                </div>
                <BsChevronRight 
                  style={ { color: optionSelected === 4 ? 'white' : '#8c8c8c', fontSize: 15}}
                />
              </li>
            </ul>
          </div>
          {/* Content */}
          <div className='w-4/6'>
          {optionSelected === 1 && (
            <GeneralView handleChange={handleChange} fields={fields} detailData={detailData} paramsId={params.id as string}/>
          )}
          {optionSelected === 2 && (
            <MessagesView messages={messages} handleChangeMessages={handleChangeMessages} updateMessagesData={updateMessagesData}/>
          )}
          {optionSelected === 3 && (
            //@ts-ignore
            <AutomationView products={products} 
              automationForm={automationForm} 
              handleChange={handleChange} 
              tags={tags} flows={flows} 
              tagsChange={tagsChange} 
              setAutomationForm={setAutomationForm}
              updateAutomationForm={updateAutomationForm}
            />
          )}
          {optionSelected === 4 && (
            <div className='w-full p-3'>
            <h4 className='text-2xl text-grey'>Campañas UTM</h4>
            <h3 className='text-xl text-grey mb-5 mt-5'>Cada estrategia de marketing o anuncio que genere trafico a la pagina web deberia tener su campaña espeficica de parametros UTM.
             De este modo Chatty podra identificar no solo la pagina web, sino tambien como llego el usuario a la pagina web.</h3>
            <div className='flex justify-between mt-5'>
              <button 
                className='text-sm bg-[#53b6a7] text-white p-2 pl-3 pr-3 rounded' 
                onClick={() => {
                  setUtmForm(UTMFORM_BASE)
                  handleOpenModal()
                }}
              >Nueva Campaña</button>
              <div className='flex relative items-center justify-center'>
                <TextField 
                  id="url"
                  name="url"
                  className='1/4 pl-3 border-none outline-none shadow-transparent	'
                  onChange={(e) => handleDebouncedSearch(e)}
                  placeholder={'Buscar por URL'}
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                          <AiOutlineSearch style={{fontSize: 22}}/>
                      </InputAdornment>
                    ),
                  }}
                />

              </div>
            </div>
            <div className='mt-5'>
              {campaignsList.campaigns.length !== 0 ? campaignsList.campaigns.map((campaign) =>  <CampaignListItem copyToClipboard={copyToClipboard} data={campaign} deleteCampaign={deleteCampaign} editCampaign={editCampaign}/>) : <p className='text-[#cacaca]'>No hay campañas</p>}
            </div>
            {/* {(campaignsList.total as number) <= 10 || campaignsList.total === undefined && ( */}
            { loadingCampaign && (
              <div className='flex items-center w-full justify-center'>
                <img src='/loading.gif' />
              </div>
            )}
             <Modal
                open={openModal}
                onClose={handleCloseModal}
                className={"flex items-center justify-center"}
              >
                <div className='bg-white w-[40%] h-3/4 flex overflow-hidden items-center rounded p-5 flex-col justify-between'>
                  <div className='relative w-full h-full'>
                    <h4 className='text-2xl text-black mb-5 mt-5'>Agregar nueva campaña UTM</h4>
                    <form 
                    className='overflow-y-auto overflow-x-hidden h-[90%]'
                    onSubmit={(e) => sendCampaign(e)}>
                        <div className='flex mt-5 flex-col'>
                          <h3 className='w-full mb-3 text-black'>Nombre de la URL (*)</h3>
                          <h3 className='w-full mb-5'>Elige un nombre facil de identificar.</h3>
                          <div className='w-full'>
                            <TextField 
                              id="url"
                              name="url"
                              className='mb-3 w-full'
                              onChange={(e) => handleChangeCampaing(e)}
                              value={utmForm.url}
                              placeholder={'Escribe el nombre'}
                              required
                            />
                          </div>
                        </div>
                        <div className='mt-5'>
                          <hr className='mb-5'></hr>
                          <h3 className='w-full mb-3 text-black'>Source (*)</h3>
                          <h3 className='mb-5 w-full'>Identifica el origen del trafico. Mientras mas especifico mejor. Ejemplo: Meta, Google, Instagram. </h3>
                          <div className='flex w-full'>
                          <div className='w-full'>
                              <div className='flex gap-5 items-center'>
                                {addNewSource === false && (
                                  <>
                                    <Autocomplete
                                      className='w-full'
                                      disablePortal
                                      id="source"
                                      options={source}
                                      onChange={handleAutocompleteChangeCampaing}
                                      defaultValue={utmForm.source}
                                      renderInput={(params) => <TextField {...params} label="Selecciona una opción" required />}
                                    />                            
                                </>
                                )}
                                {addNewSource === false && <p>ó</p>}
                                <div className={addNewSource === true ? 'w-full' : 'w-1/3'}>
                                  {addNewSource === true ? (
                                    <div className='flex gap-2'>
                                      <TextField 
                                        id="source"
                                        name="source"
                                        placeholder='Ingrese el source'
                                        className='mb-3 w-2/3'
                                        onChange={(e) => handleChangeCampaing(e)}
                                        value={utmForm.source}
                                        required
                                      />
                                      <button
                                        className='text-sm bg-[#3DE8CF] text-white p-2 pl-3 pr-3 rounded'
                                        onClick={() => setAddNewSource(false)}
                                      >
                                        Elegir de los existentes
                                      </button>
                                    </div>
                                  ) : (
                                    <button
                                      className='text-sm bg-[#3DE8CF] text-white p-2 pl-3 pr-3 rounded'
                                      onClick={() => setAddNewSource(true)}
                                    >
                                      Agregar nuevo
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='mt-5'>
                          <hr className='mb-5'></hr>
                          <h3 className='w-full mb-3 text-black'>Medium (*)</h3>
                          <h3 className='mb-5 w-full'>Especifica el tipo o la naturaleza del medio a través del cual llega el tráfico. Ejemplo: paid media, organic.</h3>
                          <div className='flex w-full'>
                            <div className='w-full'>
                              <div className='flex gap-5 items-center'>
                                {addNewMedium === false && (
                                  <Autocomplete
                                      className='w-full'
                                      disablePortal
                                      id="medium"
                                      options={medium}
                                      defaultValue={utmForm.medium}
                                      onChange={handleAutocompleteChangeCampaing}
                                      renderInput={(params) => <TextField {...params} label="Selecciona una opción" required />}
                                    /> 
                                )}
                                {addNewMedium === false && <p>ó</p>}
                                <div className={addNewMedium === true ? 'w-full' : 'w-1/3'}>
                                  {addNewMedium === true ? (
                                    <>
                                    <div className='flex gap-2'>
                                      <TextField 
                                        id="medium"
                                        name="medium"
                                        label='Medium'
                                        placeholder='Ingrese el medium'
                                        className='mb-3 w-2/3'
                                        onChange={(e) => handleChangeCampaing(e)}
                                        value={utmForm.medium}
                                        required
                                      />
                                      <button
                                        className='text-sm bg-[#3DE8CF] text-white p-2 pl-3 pr-3 rounded'
                                        onClick={() => setAddNewMedium(false)}
                                      >
                                        Elegir de los existentes
                                      </button>
                                    </div>
                                    </>
                                  ) : (
                                    <button
                                      className='text-sm bg-[#3DE8CF] text-white p-2 pl-3 pr-3 rounded'
                                      onClick={() => setAddNewMedium(true)}
                                    >
                                      Agregar nuevo
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='mt-5'>
                          <hr className='mb-5'></hr>
                          <h3 className='w-full mb-3 text-black'>Campaign (*)</h3>
                          <h3 className='mb-5 w-full'>Identifica la campaña de marketing o la promoción que se está ejecutando.Ejemplo: Black Friday, Oferta Navideña.</h3>
                          <div className='flex w-full'>
                            <div className='w-full'>
                              <div className='flex gap-5 items-center'>
                                {addNewCampaign === false && (
                                  <Autocomplete
                                    className='w-full'
                                    disablePortal
                                    id="campaign"
                                    options={campaign}
                                    defaultValue={utmForm.campaign}
                                    onChange={handleAutocompleteChangeCampaing}
                                    renderInput={(params) => <TextField {...params} label="Selecciona una opción" required />}
                                    /> 
                                )}
                                {addNewCampaign === false && <p>ó</p>}
                                <div className={addNewCampaign === true ? 'w-full' : 'w-1/3'}>
                                  {addNewCampaign === true ? (
                                    <>
                                      <div className='flex gap-2'>
                                        <TextField 
                                          id="campaign"
                                          name="campaign"
                                          label='Campaign'
                                          placeholder='Ingrese una campaign'
                                          className='mb-3 w-2/3'
                                          onChange={(e) => handleChangeCampaing(e)}
                                          value={utmForm.campaign}
                                          required
                                        />
                                        <button
                                          className='text-sm bg-[#3DE8CF] text-white p-2 pl-3 pr-3 rounded w-1/3'
                                          onClick={() => setAddNewCampaign(false)}
                                        >
                                          Elegir de los existentes
                                        </button>
                                      </div>
                                    </>
                                  ) : (
                                    <button
                                      className='text-sm bg-[#3DE8CF] text-white p-2 pl-3 pr-3 rounded'
                                      onClick={() => setAddNewCampaign(true)}
                                    >
                                      Agregar nuevo
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr className='mb-5 mt-5'></hr>
                        <div className='flex mt-5 flex-col'>
                          <h3 className='w-full mb-3 text-black'>Term</h3>
                          <h3 className='mb-5 w-full'>Se utiliza en campañas pagas de motores de búsqueda para identificar términos o palabras clave.</h3>
                          <div className='w-full'>
                            <TextField 
                              id="term"
                              name="term"
                              label="Term"
                              placeholder='Ingrese un Term'
                              className='mb-3 w-full'
                              onChange={(e) => handleChangeCampaing(e)}
                              value={utmForm.term}
                            />
                          </div>
                        </div>
                        <hr className='mb-5 mt-5'></hr>
                        <div className='flex mt-5 flex-col'>
                        <div className='w-full'>
                            <h3 className='w-full mb-3 text-black'>Content</h3>
                            <h3 className='mb-5 w-full'>Diferencia entre contenidos o enlaces similares dentro de la misma campaña, útil para pruebas A/B.  (*)</h3>
                            <TextField 
                              id="content"
                              name="content"
                              label='Content'
                              placeholder='Ingrese un Content'
                              className='mb-3 w-full'
                              onChange={(e) => handleChangeCampaing(e)}
                              value={utmForm.content}
                            />
                          </div>
                        </div>
                        <hr className='mb-5 mt-5'></hr>
                        <h3 className='text-xl text-black mt-5'>Etiquetas</h3>
                        <Autocomplete
                          multiple
                          id="tags-standard"
                          options={tags}
                          getOptionLabel={(option) => option.name}
                          onChange={tagsCampaignChange}
                          defaultValue={automationForm.tags}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              label="Buscar tags"
                              placeholder="Favorites"
                              className='outline-none'
                            />
                          )}
                        />
                        <div className='flex justify-end w-full mt-10 mb-5'>
                          <button 
                            type='submit'
                            className='text-sm bg-[#3DE8CF] text-white p-2 pl-3 pr-3 rounded mr-5 min-w-[120]' 
                          >
                            Guardar
                          </button>
                          <button 
                            className='text-sm bg-[#e8623d] text-white p-2 pl-3 pr-3 rounded min-w-[120]' 
                            onClick={() => {
                              handleCloseModal()
                              setUtmForm({
                                url: "",
                                source: "",
                                medium: "",
                                campaign: "",
                                term: "",
                                content: ""
                              })
                              }}>
                            Cancelar
                          </button>
                        </div>
                    </form>
                  </div>
                </div>
              </Modal>
            </div>
            
          )}
          </div>
			  </div>
        <ToastContainer 
          position="top-right"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          toastStyle={{ 
            border: '1px solid white',
            backgroundColor: "#212944" 
          }}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    )
};

export default Detail;
