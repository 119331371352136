import { useContext, useEffect, useState } from "react";
import { DataGrid, GridRowId, GridToolbar } from "@mui/x-data-grid";
import TabsConfig from "../TabsConfig/TabsConfig";
import { TABELS, TABLES_ANALITYCS } from "../../models/table";
import CircularProgress from "@mui/material/CircularProgress";
import ActionsConfig from "../ActionsConfig/ActionsConfig";
import { useParams } from "react-router-dom";
import userContext from "../../utils/userContext";
import { toast, ToastContainer } from "react-toastify";

interface Table {
  content: string;
  id: string;
  name: string;
  split_content: string[];
}

type Props = {
  data: Table[];
  loading: boolean;
  tableGetData: () => Promise<void>;
};
const TableConfigAnalitycs: React.FC<Props> = ({
  data,
  loading,
  tableGetData,
}) => {
  const params = useParams();
  const { agent } = useContext<any>(userContext);

  const [columnsNames, setColumnsNames] = useState<any[]>([]);
  const [selectedRow, setSelectedRow] = useState<Table>();
  const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);

  useEffect(() => {
    if (data.length) {
      const columnObj = Object.getOwnPropertyNames(data[0]).map((item) => {
        return {
          field: item,
          headerName: item.toUpperCase().replace("_", " "),
          minWidth: 200,
          width: 400,
        };
      });
      setColumnsNames(columnObj);
    }
  }, [data]);

  const onRowsSelectionHandler = (ids: string[]) => {
    const selectionSet = new Set(selectionModel);
    const result = ids.filter((s) => !selectionSet.has(s));
    const selected = data.filter((row) => result[0] == row.id);
    setSelectionModel(result);
    setSelectedRow(selected[0]);
  };
  return (
    <div style={{ height: 400, width: "100%" }}>
      <TabsConfig data={TABLES_ANALITYCS} isAnalitycs={true} />
      {params.table === "smart_messages" && (
        <p className="text-white text-lg my-5">
          Smart Messages funciona con Topics (grupos de mensajes).
          <br />
          Cada mensaje dentro de un grupo es distinto, pero posee una misma
          intención y significado, lo que los hace intercambiables.
          <br />
          Cuando selecciones Smart Messages como método de identificación de una
          fuente de origen, tendrás que seleccionar un grupo de mensajes que
          será administrado por Chatty, asignando de forma inteligente a cada
          usuario que haga click para enviar un WhatsApp, un mensaje distinto.
        </p>
      )}
      <ActionsConfig
        tableSelected={params.table || ""}
        selectedRow={selectedRow}
        tableGetData={tableGetData}
      />
      {!loading ? (
        <DataGrid
          rows={data}
          columns={columnsNames}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 50 },
            },
          }}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          pageSizeOptions={[5, 10]}
          checkboxSelection
          rowSelectionModel={selectionModel}
          //@ts-ignore
          onRowSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
        />
      ) : (
        <div className="loading-table">
          <CircularProgress color="success" />
        </div>
      )}
    </div>
  );
};

export default TableConfigAnalitycs;
