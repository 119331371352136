import React, { useEffect, useState, useContext, useMemo, useRef} from "react";
import { getAllTags } from "../../services/Preview.service";
import { addTagToChat, removeTagToChat } from "../../services/Tags.service";
import { MdExpandMore, MdExpandLess } from "react-icons/md"; // Importa los íconos de Material Design
import { Badge, Button } from "flowbite-react";
import { RiCloseFill } from "react-icons/ri";
import { FaPlus } from "react-icons/fa";
import { GiBasket } from "react-icons/gi";
import { DropdownItem } from "flowbite-react/lib/esm/components/Dropdown/DropdownItem";
import { useMutation } from "@tanstack/react-query";
import userContext from "../../utils/userContext";
import Tag from "../../interfaces/tag.interface";
import './Tags.css'

type TagData = {
  assign: boolean,
  tag_id: string
}
interface Props {
  conversationTags: Tag[],
  setTagDropdownIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  tagDropdownIsOpen: boolean;
  setActiveTagsTab: any;
  setRefetchPreview: React.Dispatch<React.SetStateAction<boolean>>;
  isBroaderPreview?: boolean;
}

const Tags: React.FC<Props> = ({
  conversationTags,
  setTagDropdownIsOpen,
  tagDropdownIsOpen,
  setActiveTagsTab,
  setRefetchPreview,
  isBroaderPreview = false
}) => {
  const { clientUser, agent, context } = useContext<any>(userContext);

  const [allTags, setAllTags] = useState<Tag[]>([])
  const [searchValue, setSearchValue] = useState<string>("");
  const [allTagDropdownIsOpen, setAllTagDropdownIsOpen] = useState<boolean>(true);
  const [activeTags, setActiveTags] = useState<any>([]);
  const inputDropdownSearch = useRef<HTMLInputElement | null>(null)
  
  const removeTag = (tagId: string) => {  
    setActiveTags((prevTags: Tag[]) =>
      prevTags.filter((tag: Tag) => tag.id !== tagId)
    );
  };

  const addTag = (tag: Tag) => {
    const tagExists = activeTags.some((t: Tag) => t.id === tag.id);
    if (!tagExists) {
      setActiveTags((prevTags: Tag[]) => [...prevTags, tag]);
    }
  };

  const UpdateTags = useMutation({
    mutationFn: (data:TagData) => {
      const { assign , tag_id } = data
      if(assign) {
        return addTagToChat(agent.id ,clientUser.conversation_id, tag_id, agent.company ).finally( () => {
          setRefetchPreview(true)
        })
      } else {
        return removeTagToChat(agent.id ,clientUser.conversation_id, tag_id, agent.company ).finally( () => {
          setRefetchPreview(true)
        })
      }
    }
  })

  const unactiveTags = useMemo(() => {
    return allTags.filter((tag:Tag) => {
      if (Array.isArray(activeTags) && activeTags.length) {
        const matchingTag = activeTags.find(
          (conversationTag) => conversationTag.id === tag.id
        );
        return !matchingTag || matchingTag.is_active === 0;
      }
      return allTags;
    });
  }, [conversationTags, activeTags, addTag, removeTag]);

  const filteredUnactiveTags = useMemo(() => {
    return unactiveTags.filter((tag:Tag) => !activeTags?.includes(tag.id));
  }, [unactiveTags, activeTags]);

  useEffect(() => {
    if (allTagDropdownIsOpen && inputDropdownSearch.current) {
      inputDropdownSearch.current.focus();
    }
  }, [allTagDropdownIsOpen]);

  useEffect(() => {
    setActiveTags(conversationTags);
  }, [conversationTags]);
  
  useEffect(() => {
    getAllTags(agent.company).then((data) => {
      setAllTags(data);
    });
  },[])

  useEffect ( ()=> {
    if(tagDropdownIsOpen) {
      if(inputDropdownSearch.current) {
        inputDropdownSearch.current.focus()
      }
    }
  }, [tagDropdownIsOpen])

  return (
    <div
      className={`${ context ==='admin' && "pointer-events-none"
      } ml-auto mr-auto w-full tagsHeight`}
    >
      
      <div className="border-b border-[#2f3652] pb-3 pt-3 px-5 relative">
          <div
            onClick={() => setAllTagDropdownIsOpen(!allTagDropdownIsOpen)}
            className={`flex text-[#acb8c0] tracking-wide ${!allTagDropdownIsOpen ? "mb-3" : ""} text-lg justify-start items-center w-full text-center cursor-pointer` }
          >
            Etiquetas
          {allTagDropdownIsOpen  ? <MdExpandLess className="absolute right-5" /> : <MdExpandMore className="absolute right-5" />}
        </div>
        { allTagDropdownIsOpen && (
          <>
          {activeTags.length > 0 && (
            <div
              className="mr-2"
              onClick={(event) => {
                event.stopPropagation();
                setTagDropdownIsOpen(true);
              }}
            >
                <div
                  onClick={() => setTagDropdownIsOpen(true)}
                  className="grid grid-cols-3 cursor-pointer"
                >
                  {activeTags?.map((t: any) => (
                        <div
                          onClick={() => setTagDropdownIsOpen(true)}
                          className="text-white flex p-2 rounded-md m-2 cursor-pointer ease-in-out transform items-center justify-center align-middle "
                          key={t.id}
                          style={{background: t.color}}
                        >
                              <div
                                className="w-full overflow-hidden cursor-default text-center text-xs text-ellipsis whitespace-nowrap justify-center m-0"
                                key={t.id}
                              >
                                {t.name}
                              </div>
                              <Button
                                style={{
                                  color: 'white !important',
                                  width: "16px",
                                  height: "16px",
                                  position: "absolute",
                                  top: "0px",
                                  right: '0px',
                                  backgroundColor: "transparent"
                                }}
                                pill={true}
                              >
                                {!isBroaderPreview && (
                                    <RiCloseFill
                                      onClick={(event) => {
                                        if(!isBroaderPreview) {
                                          event.stopPropagation();
                                          UpdateTags.mutate({assign: false ,tag_id: t.id})
                                          removeTag(t.id);
                                        }
                                      }}
                                      style={{
                                        color: "white",
                                      }}
                                    />
                                )}
                              </Button>
                        </div>
                  ))}
                </div>
            </div>
          )}
          {!tagDropdownIsOpen && context === 'agent' && !isBroaderPreview && (
            <div className="flex justify-center align-middle text-center absolute right-12 top-2">
              <button
                className="rounded w-7 h-7 flex items-center justify-center text-[#acb8c0] border border-[#acb8c0]"
                onClick={(event) => {
                  event.stopPropagation();
                  setTagDropdownIsOpen(true);
                }}
              >
                +
              </button>
            </div>
          )}
          {tagDropdownIsOpen ? (
          <div className="flex justify-center align-middle my-2 ">
            <div
              className="rounded-2xl z-50 shadow-md active:shadow-xl transition duration-300 ease-in-out transform hover:shadow-lg border-solid border-gray-200 border-2 tags-dropdown-container bg-white"
              style={{
                maxHeight: "300px",
                overflowY: "auto",
                width: "250px",
              }}
              onClick={(event) => event.stopPropagation()}
            >
              <input
                type="text"
                className="w-full py-2 px-3 text-black rounded-md border-2 border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-200 focus:border-transparent "
                placeholder="Buscar tags"
                value={searchValue}
                ref={inputDropdownSearch}
                onClick={(event) => event.stopPropagation()}
                onChange={(
                  event: React.ChangeEvent<HTMLInputElement> | any
                ) => {
                  event.stopPropagation();
                  setSearchValue(event.target.value);
                }}
              />
              <Button
                className="hover:border-black"
                style={{
                  width: "16px",
                  height: "16px",
                  position: "absolute",
                  top: "13px",
                  right: `6px`,
                  backgroundColor: "#d3dbd3",
                  overflow: "hidden",
                }}
                //@ts-ignore
                onClick={(event) => {
                  event.stopPropagation();
                  setSearchValue("");
                }}
              >
                <div className="flex text-center">
                  <GiBasket className="text-black w-4 h-4" />
                </div>
              </Button>
              {searchValue.length !== 0
                ? filteredUnactiveTags
                    ?.filter((tag: any) =>
                      tag.name.toLowerCase().includes(searchValue.toLowerCase())
                    )
                    .map((t: any) => (
                      <div
                        onClick={async (event) => {
                          event.stopPropagation();
                          UpdateTags.mutate({assign: !t.is_active ,tag_id: t.id})
                          addTag({ ...t, is_active: 1 });
                          setSearchValue("");
                        }}
                      >
                        <DropdownItem key={t.id} className="justify-center">
                          <div className="flex justify-center align-middle">
                            <button className="text-black w-full">{t.name}</button>
                          </div>
                        </DropdownItem>
                      </div>
                    ))
                : filteredUnactiveTags?.map((t: any) => (
                    <div
                      onClick={async (event) => {
                        event.stopPropagation();
                        UpdateTags.mutate({assign: true ,tag_id: t.id})
                        addTag({ ...t, is_active: 1 });
                        setSearchValue("");
                      }}
                    >
                      <DropdownItem key={t.id} className="justify-center">
                        <div className="flex justify-center align-middle">
                          <button className="text-black w-full">{t.name}</button>
                        </div>
                      </DropdownItem>
                    </div>
                  ))}
            </div>
          </div>
          ) : (
            <></>
          )}
          </>
        )}
      </div>
    </div>
  );
};

export default Tags;
