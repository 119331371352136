import { axiosConfig } from "./axios.config";

const agents = async (company) => {

    const res = await axiosConfig.get(`${process.env.REACT_APP_API_NEW_URL}/rrhh/${company}/agents`)

    return res.data;
}

const addAgent = async (data, company) => {
    const res = await axiosConfig.post(`${process.env.REACT_APP_API_NEW_URL}/rrhh/${company}/hire`,
    data
    );
    return res.data;
};

const deleteAgent = async (company, id) => {
  const res = await axiosConfig.post(`${process.env.REACT_APP_API_NEW_URL}/rrhh/${company}/fire`, {"id": id});
  return res.data;
};

export { agents, addAgent, deleteAgent }