import { Modal } from "flowbite-react";
import React, { useContext } from "react";
import userContext from "../utils/userContext";
import FullScreenLoader from "./FullScreenLoader";

interface DataInterface {
  person_id: number | null;
  property: string;
  value: number;
  conversation_id: number;
}

interface Props {
  content?: string;
  value: string;
  btnValue: string;
  data?: {
    person_id: number | null;
    property: string;
    value: number;
    conversation_id: number;
  };
  setIsToggled?: React.Dispatch<React.SetStateAction<boolean>>;
  isToggled?: boolean;
  activity?: string;
  fn?: any;
  bodyStyle?: React.CSSProperties;
  headerStyle?: React.CSSProperties;
  noCancel?: boolean;
}

const OpenModal: React.FC<Props> = ({
  content,
  value,
  btnValue,
  data,
  setIsToggled,
  isToggled,
  fn,
  activity,
  bodyStyle,
  headerStyle,
  noCancel,
}) => {
  const { isModalOpen, setModalOpen } = useContext(userContext) as any;
  const closeModal = () => {
    if (isToggled !== undefined && setIsToggled) {
      setIsToggled(!isToggled);
    }
    setModalOpen({ ...isModalOpen, [value]: false });
  };

  return (
    <Modal
      show={!!isModalOpen[value]}
      size="md"
      popup={true}
      onClose={() => closeModal()}
    >
      <Modal.Header style={headerStyle} />
      <Modal.Body style={bodyStyle}>
        <div className="text-center">
          {content && content.length > 0 ? (
            <h3 className="mb-5 text-lg font-normal text-gray-400">
              {content}
            </h3>
          ) : (
            <FullScreenLoader />
          )}
          <div className="flex justify-center gap-4">
            <button
              className="hover:bg-[#049380] rounded-lg text-sm w-32 py-3 bg-[#0fb6a1] text-white"
              onClick={() => fn()}
              // onClick={() => action()}
            >
              {btnValue}
            </button>
            {!noCancel && (
              <button
                className="hover:bg-[#b42134] rounded-lg text-sm w-32 py-3 bg-[#db2840] text-white"
                onClick={() => closeModal()}
              >
                No, cancelar
              </button>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default OpenModal;
