import { axiosConfig } from "./axios.config";

const getConditions = (companyId) => {
  return axiosConfig.get(
    `${process.env.REACT_APP_API_COPILOT}/conditions/${companyId}/`
  );
};

const updateCondition = (condition) => {
  return axiosConfig.put(
    `${process.env.REACT_APP_API_COPILOT}/conditions/${condition._id}/`,
    condition
  );
};

const createCondition = (condition) => {
  return axiosConfig.post(
    `${process.env.REACT_APP_API_COPILOT}/conditions/`,
    condition
  );
};

const duplicateCondition = (condition) => {
  const requestObject = {
    ...condition,
    title: `${condition.title} (Duplicado)`,
  };

  return createCondition(requestObject);
};

const deleteCondition = (conditionId) => {
  return axiosConfig.delete(
    `${process.env.REACT_APP_API_COPILOT}/conditions/${conditionId}/`
  );
};

const getLabel = (companyId, type, value) => {
  return axiosConfig.get(
    `${process.env.REACT_APP_API_V3}/dropdowns/${companyId}/label/${type}/${value}`
  );
};

export {
  getConditions,
  updateCondition,
  createCondition,
  duplicateCondition,
  deleteCondition,
  getLabel,
};
