// GrLocationPin
// FaRegHandshake
// MdOutlineMarkChatUnread
// MdOutlineTimer
// MdOutlineMarkChatUnread
// IoCartOutline
// MdOutlineAttachMoney
// BiWorld
// FaRegUser
// IoPricetagsOutline
// IoTimeOutline
// send_response: BsSend

// send_template : BsSendCheck

// send_messages:  TbMessage

// add_tag: TbTag

// remove_tag: TbTagOff

// add_product: BsCart2

// remove_product: BsCartX

// add_highlight : RiStickyNoteAddLine

// archive: TbArchive

// unarchive: TbArchiveOff

// assign_to_copilot: BsPersonAdd

// desassign_agent: BsPersonDash

// mark_as_unread: MdOutlineMarkChatUnread

// mark_as_read: MdOutlineMarkChatRead
import { CSSProperties } from "react";
import { BiWorld } from "react-icons/bi";
import {
  BsCart2,
  BsCartX,
  BsPersonAdd,
  BsPersonDash,
  BsSend,
  BsSendCheck,
} from "react-icons/bs";
import { FaRegHandshake, FaRegUser } from "react-icons/fa";
import { GrLocationPin } from "react-icons/gr";
import {
  IoCartOutline,
  IoPricetagsOutline,
  IoTimeOutline,
} from "react-icons/io5";
import {
  MdOutlineAttachMoney,
  MdOutlineMarkChatRead,
  MdOutlineMarkChatUnread,
  MdOutlineRoute,
  MdOutlineTimer,
} from "react-icons/md";
import {
  TbArchive,
  TbArchiveOff,
  TbMessage,
  TbTag,
  TbTagOff,
} from "react-icons/tb";
import { RiStickyNoteLine } from "react-icons/ri";

export const renderIcon = (
  iconName: string,
  style: CSSProperties,
  defaultIcon?: JSX.Element
): JSX.Element | null => {
  switch (iconName) {
    case "GrLocationPin":
      return <GrLocationPin style={style} />;
    case "FaRegHandshake":
      return <FaRegHandshake style={style} />;
    case "MdOutlineMarkChatUnread":
      return <MdOutlineMarkChatUnread style={style} />;
    case "MdOutlineTimer":
      return <MdOutlineTimer style={style} />;
    case "IoCartOutline":
      return <IoCartOutline style={style} />;
    case "MdOutlineAttachMoney":
      return <MdOutlineAttachMoney style={style} />;
    case "BiWorld":
      return <BiWorld style={style} />;
    case "FaRegUser":
      return <FaRegUser style={style} />;
    case "IoPricetagsOutline":
      return <IoPricetagsOutline style={style} />;
    case "IoTimeOutline":
      return <IoTimeOutline style={style} />;
    case "BsSend":
      return <BsSend style={style} />;
    case "BsSendCheck":
      return <BsSendCheck style={style} />;
    case "TbMessage":
      return <TbMessage style={style} />;
    case "BsCart2":
      return <BsCart2 style={style} />;
    case "BsCartX":
      return <BsCartX style={style} />;
    case "RiStickyNoteAddLine":
      return <RiStickyNoteLine style={style} />;
    case "TbArchive":
      return <TbArchive style={style} />;
    case "TbArchiveOff":
      return <TbArchiveOff style={style} />;
    case "BsPersonAdd":
      return <BsPersonAdd style={style} />;
    case "BsPersonDash":
      return <BsPersonDash style={style} />;
    case "MdOutlineMarkChatRead":
      return <MdOutlineMarkChatRead style={style} />;
    case "TbTag":
      return <TbTag style={style} />;
    case "TbTagOff":
      return <TbTagOff style={style} />;
    case "MdOutlineRoute":
      return <MdOutlineRoute style={style} />;
    default:
      return defaultIcon || null;
  }
};
