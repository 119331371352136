import { axiosConfig } from "./axios.config";

const getExtractors = () => {
  return axiosConfig.get(`${process.env.REACT_APP_API_COPILOT}/extractors/types/`);
};

const getExtractorValues = (extractorId, companyId) => {
  return axiosConfig.get(
    `${process.env.REACT_APP_API_COPILOT}/extractors/values/${extractorId}/${companyId}`
  );
};

export { getExtractors, getExtractorValues };
