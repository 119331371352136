import { useMutation } from "@tanstack/react-query";
import { Spinner } from "flowbite-react";
import { useContext, useEffect, useState } from "react";
// import { postHighlight } from "../api";
import { addHighlight } from "../services/Highlights.service";
import { context } from "../interfaces";
import { NoteProps } from "../interfaces/noteProps.interface";
import userContext from "../utils/userContext";
import { IoMdSend } from "react-icons/io";
import moment from "moment";

type Note = {
  agent_id: string,
  chat_id: string,
  title: string,
  description: string, 
  date: string
}


export const Note: React.FC<NoteProps> = ({ setShowForm, refetchData }) => {
  const { agent, clientUser, setIsAlert, isAlert, context } = useContext(
    userContext
  ) as context;

  const [note, setNote] = useState<Note | null>(null);

  const sendNote = (e: any) => {
    e.preventDefault();
    handlePostHighlight.mutate();
  };

  const onChange = (event: React.SyntheticEvent): void => {
    const { name, value } = event.target as HTMLButtonElement;
    if (clientUser.conversation_id) {
      setNote({
        ...note,
        chat_id: clientUser.conversation_id,
        //@ts-ignore
        agent_email: agent.id,
        [name]: value,
        date: moment(new Date).format()
      });
    }
  };

  const handlePostHighlight = useMutation(() => addHighlight(note, agent.company, 'agent_workspace'), {
    onSuccess: (res) => {
      setIsAlert({
        ...isAlert,
        isOpen: true,
        title: res.message,
        type: "success",
      });
      setShowForm(false);
      setNote(null);
      refetchData()
    },
    onError: (err: any) => {
      setIsAlert({
        ...isAlert,
        isOpen: true,
        title: err.response.data.message,
        type: "failure",
      });
      setShowForm(false);
    },
  });

  useEffect(() => {
    console.log('clientuser update')
    setNote(null);
    // setShowForm(false)
  }, [clientUser]);

  return (
    <div className="flex justify-center mt-10">
      <form onSubmit={(e) => sendNote(e)}>
        <div className="mb-5">
          <p className="text-gray-600">Título</p>
          <input
            name="title"
            value={note?.title}
            placeholder="Ingrese un título"
            className="w-full p-2 rounded-xl border-[1px] border-gray-400 mt-3 bg-white"
            onChange={onChange}
          ></input>
        </div>
        <div className="mb-5">
          <p className="text-gray-600">Descripción</p>
          <textarea
            name="description"
            value={note?.description}
            placeholder="Ingrese la descripción"
            className="mt-3 rounded-xl w-full border-gray-400 bg-white"
            onChange={onChange}
          ></textarea>
        </div>
        <div className="w-20 h-12 m-auto rounded-xl flex align-middle hover:bg-cyan-300 bg-cyan-400 hover:border-[#0fb6a1] transition-all duration-300">
          <Spinner hidden={handlePostHighlight.isLoading ? false : true} />
          <button
            className={`${
              handlePostHighlight.isLoading && "ml-2"
            } cursor-pointer w-20 flex items-center justify-center bg-transparent`}
            type="submit"
          >
            <div className="flex items-center justify-center">
              <IoMdSend className="text-white text-center" size={22} />
            </div>
          </button>
        </div>
      </form>
    </div>
  );
};
