import React, { useState } from "react";
import {
  MenuItem,
  Autocomplete,
  TextField
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

 const MultipleSelectChip = ({data, defaultSelected, onchange, name = 'tags', multiple = true, disabled=false}) => {
  return (
    <>
      <MultiAutocomplete data={data} onchange={onchange} name={name} multiple={multiple} disabled={disabled}/>
    </>
  );
}

const MultiAutocomplete = ({data, defaultSelected, onchange, name, multiple, disabled}) => {

  return (
    <Autocomplete
      sx={{ m: 1, width: '100%' }}
      multiple={multiple}
      id="tags-standard"
      options={data}
      disabled={disabled}
      getOptionLabel={(option) => {
        if(name === 'Impure Ads') {
          return(option.pure_ad_name)
        } else {
          return (option.name)
        }
      }}
      defaultValue={defaultSelected}
      disableCloseOnSelect
      onChange={(e,values) => onchange(values)}
      renderOption={(props, option, { selected }) => (
        <MenuItem
          key={option.name}
          value={name === 'Impure Ads' ? option : option.value}
          sx={{ justifyContent: "space-between" }}
          {...props}
        >
          {name === 'Impure Ads' ? option.pure_ad_name : option.name}
          {selected ? <CheckIcon color="info" /> : null}
        </MenuItem>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={name}
          placeholder="Tag ID"
        />
      )}
    />
  );
};


export default MultipleSelectChip