import { useContext, useEffect, useState } from "react";

import { useDocumentClick } from "../../hooks/useDocumentClick";
import { getResponses, postResponses } from "../../services/responses.service";
import userContext from "../../utils/userContext";
import MessageInput from "../MessageInput/MessageInput";
import { FileMultimedia } from "../MessageInput/Multimedia/Multimedia";

import { MessageItem } from "./MessageItem";
export interface ContentMessageText {
  body: string;
}

export interface ContentMessageImage {
  url: string;
  caption: string;
  mime_type: string;
  sha256?: string;
}
export interface MessageResponse {
  type: string;
  content: ContentMessageText | ContentMessageImage;
}
export interface Response {
  name: string;
  messages: MessageResponse[];
}
interface ModalContentProps {
  setResponsesForm: React.Dispatch<
    React.SetStateAction<{
      name: string;
      messages: MessageResponse[];
    }>
  >;
  responsesForm: Response;
}

const ModalContent = ({
  setResponsesForm,
  responsesForm,
}: ModalContentProps) => {
  const { agent } = useContext<any>(userContext);

  const [fastAnswersIsOpen, setFastAnswersIsOpen] = useDocumentClick(
    ".fast-answers-container",
    false
  );
  const [importedFilesSelected, setImportedFilesSelected] = useState<
    FileMultimedia[]
  >([]);
  const [messagesResponses, setMessagesResponses] = useState<MessageResponse[]>(
    responsesForm.messages
  );

  const handleSendMessage = (text: string) => {
    let dataMessages = [...messagesResponses];

    if (importedFilesSelected.length !== 0) {
      importedFilesSelected.forEach((file) => {
        const messageType =
          file.type === "image" || file.type === "video" ? file.type : "text";

        const messageContent = {
          url: file.url,
          caption: text,
          mime_type: file.mime_type,
        };

        dataMessages.push({
          type: messageType,
          content: messageContent,
        });
      });
    } else {
      dataMessages.push({
        type: "text",
        content: {
          body: text,
        },
      });
    }
    setImportedFilesSelected([]);
    setMessagesResponses(dataMessages);
    setResponsesForm({
      ...responsesForm,
      messages: dataMessages,
    });
  };

  useEffect(() => {
    setMessagesResponses(responsesForm.messages);
  }, [responsesForm.messages]);

  console.log("importedFilesSelected: ", importedFilesSelected);

  return (
    <>
      <h3 className="text-xl font-medium text-white text-left p-2">
        Respuesta pre-armada | Response
      </h3>
      <div className="w-full flex flex-col p-2 gap-5">
        <div className="w-1/3 flex flex-col">
          <label className="text-sm text-left text-[#a3aeb8] mb-1">
            Nombre*
          </label>
          <input
            className="py-2 w-full px-3 outline-none rounded border border-[#576080] focus:border-[#576080] text-[#09f8e8] bg-[#1a2238]"
            id="name"
            name="name"
            type="text"
            onChange={(e) =>
              setResponsesForm({ ...responsesForm, name: e.target.value })
            }
            value={responsesForm.name}
          />
        </div>
        <div className="w-full flex flex-col gap-3">
          <label className="text-sm text-left text-[#a3aeb8] mb-1">
            Mensajes*
          </label>
          <span className="text-sm text-[#7b838b] text-left">
            Crea los mensajes que serán enviados a un usuario al utilizar la
            respuesta pre-armada y utiliza parámetros para personalizarlos.
            Debes crear al menos un mensaje.
          </span>
          <div className="w-full relative h-[50vh] min-h-[500px] flex flex-col rounded border border-[#576080] overflow-hidden">
            <div className="h-full bg-[#1a2238] overflow-y-auto flex flex-col px-6 py-4 items-end gap-4 justify-start">
              {messagesResponses.map((message) => (
                <MessageItem message={message} />
              ))}
            </div>
            <MessageInput
              onSendMessage={handleSendMessage}
              setFastAnswersIsOpen={setFastAnswersIsOpen}
              fastAnswersIsOpen={fastAnswersIsOpen}
              importedFilesSelected={importedFilesSelected}
              setImportedFilesSelected={setImportedFilesSelected}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export { ModalContent };
