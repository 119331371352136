import { useState } from "react";
import { useNavigate } from "react-router-dom";
import UserContext from "./userContext";
import { clientUserInterface, modalInterface } from "../interfaces";

const UserAuth: React.FC<{ children: React.ReactNode }> = ({
  children,
}): JSX.Element => {
  const navigate = useNavigate();

  const [isAlert, setIsAlert] = useState<{
    isOpen: boolean;
    title: string;
    type: string;
  }>({
    isOpen: false,
    title: "",
    type: "",
  });

  const [agent, setAgent] = useState({
    id: "0",
    email: "",
    fullName: "",
    company: window.localStorage.getItem("company_id"),
    isAdmin: false,
  });

  const [context, setContext] = useState<any>(
    window.localStorage.getItem("context")
  );

  const [isModalOpen, setModalOpen] = useState<modalInterface>({
    bot_on: false,
    bot_off: false,
    assignment: false,
    session: false,
    archived_on: false,
    archived_off: false,
  });

  const [fastAnswersModal, setFastAnswersModal] = useState<boolean>(false);

  const [clientUser, setClientUser] = useState<clientUserInterface>({
    name: "",
    img: "",
    person_id: null,
    conversation_id: null,
    bot_enabled: null,
    is_archived: null,
    phone_number: null,
    metadata_phone_number_id: null,
    chat_assignment: null,
  });

  return (
    <UserContext.Provider
      value={{
        setAgent,
        agent,
        setIsAlert,
        isAlert,
        context,
        setContext,
        isModalOpen,
        setModalOpen,
        clientUser,
        setClientUser,
        //@ts-ignore
        fastAnswersModal,
        setFastAnswersModal,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserAuth;
