import { useEffect, useState } from "react";

export type useScrollToBottomReturn = {
    atBottom: boolean;
};

export function useScrollToBottom(): useScrollToBottomReturn {
  const [atBottom, setAtBottom] = useState(false);

	const handleScroll = () => {
		console.log('hice scroll')
		if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
			return setAtBottom(true)
		} 
		setAtBottom(false)
	}

		useEffect(() => {

			window.addEventListener('scroll', handleScroll)

			return () => {
				window.removeEventListener('scroll', handleScroll);
			};
		}, [])


  return {
    atBottom
  };
}
