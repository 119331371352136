import React, { useEffect, useState, useContext, useRef } from 'react';
import { addProductToChat, deleteProductFromChat, getAllProducts } from '../services/products.service';
import { getChat } from '../services/ChatsActions.service';
import userContext from '../utils/userContext';
import CloseIcon from '@mui/icons-material/Close';


function InterestProducts({ interestProducts, setInterestProducts }: any) {
  const { agent, clientUser, context } = useContext<any>(userContext);
  const [productSelectorVisible, setProductSelectorVisible] = useState(false);
  const [allProducts, setAllProducts] = useState([]);
  const selectorRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        function handleClickOutside(event: any) {
          if (productSelectorVisible && selectorRef.current && !selectorRef.current.contains(event.target)) {
            setProductSelectorVisible(false);
          }
        }
    
        document.addEventListener('mousedown', handleClickOutside);
    
        return () => document.removeEventListener('mousedown', handleClickOutside);
      }, [productSelectorVisible]);

    useEffect(() => {
        if (productSelectorVisible) {
            getAllProducts("products", agent.company) 
            .then((response) => {
                setAllProducts(response); 
            })
            .catch((error) => {
                console.log(error);
            });
        }
    }, [productSelectorVisible, agent.company]);

    const handleAddProduct = (productId: any) => {
        const productToAdd = allProducts[productId];

        addProductToChat("products", agent.company, productId,clientUser.conversation_id, agent.id)
        .then((response) => {
            console.log("add")
            setInterestProducts((currentProducts: any) => [...currentProducts, productToAdd]);
            setProductSelectorVisible(false);
        })
        .catch((error) => {
            console.log(error);
        });
    };

    const handleRemoveProduct = (productId: any) => {
        deleteProductFromChat(agent.company, productId, clientUser.conversation_id, agent.id)
        .then((response) => {
            setInterestProducts((currentProducts: any) => currentProducts.filter((product: any) => product._id !== productId)); 
        })
        .catch((error) => {
            console.log(error);
        });
    };

  return (
    <>
    {context === 'agent' && 
        <button 
            onClick={() => {context === "agent" && setProductSelectorVisible(!productSelectorVisible)}} 
            className="rounded w-7 h-7 flex items-center justify-center text-[#acb8c0] border border-[#acb8c0] absolute right-12 top-4"
        >
            +
        </button>
    }
    <div className="flex flex-wrap justify-start gap-4 p-3">
        <div className="flex flex-wrap justify-center items-start gap-2">
        {interestProducts.length > 0 ? (
            interestProducts.map((product: any) => (
            <div key={product._id} className="relative flex items-center w-24 bg-[#131824] shadow-md rounded-lg overflow-hidden">
                <div className="absolute cross-interest-products right-1">
                    {context === 'agent' && (
                        <button className='h-1' onClick={() => handleRemoveProduct(product._id)}>
                            <CloseIcon sx={{ fontSize: 12 }} />
                        </button>
                    )}
                </div>
                <div className="w-4 h-14 flex justify-center items-center"
                    style={{ backgroundColor: product.color }}
                > 
                </div>
                <p className="p-2 text-xs text-black">{product.name}</p>
            </div>
            ))
        ) : (
            <></>
        )}
        {productSelectorVisible && (
            <div ref={selectorRef} className="absolute z-10 bg-white shadow-md rounded-lg overflow-auto right-12" style={{ maxHeight: '200px', width: '150px' }}>
                {Object.entries(allProducts)
                .filter(([productId, product]: any) => !interestProducts.some((interestProduct: any) => interestProduct._id === productId))
                .map(([productId, product]: any) => (
                    <div
                    key={productId}
                    className="p-2 hover:bg-gray-100 cursor-pointer text-black"
                    onClick={() => handleAddProduct(productId)}
                    >
                    {product.name}
                    </div>
                ))}
            </div>
        )}
        </div>
    </div>
</>
  );
}

export default InterestProducts;
