import { Button, Modal } from "flowbite-react";
import "./../FormConfig/Form.css";
import ModalParameters from "./ModalParameters";
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import FormConfig from "../FormConfig/FormConfig";
import AddSource from "./AddSource";
import {
  deleteSourceById,
  getSourceById,
} from "../../services/Analytics.services";
import userContext from "../../utils/userContext";
import { context } from "../../interfaces";
import { toast, ToastContainer } from "react-toastify";
import TopicConfig from "../TopicConfig/TopicConfig";

type Props = {
  show: string | boolean;
  onClose: (value: React.SetStateAction<string | boolean>) => void;
  tableSelected: string;
  action: string;
  selectedRow?: {
    content: string;
    id: string;
    name: string;
    split_content: string[];
  };
  tableGetData: () => Promise<void>;
};

const FORM = {
  agent_email: "",
  name: "",
  type: "",
  source_type: "PureAd",
  description: "",
  tags: [],
  products: [],
  flow: [],
  ad_id: "",
  trackeable: true,
  category: "",
  categorySelect: "",
  trigger: "",
  source_checker: "",
  topic_id: "",
  whatsapp_url: "",
};

const ModalConfig: React.FC<Props> = ({
  show,
  tableSelected,
  action,
  selectedRow = {},
  onClose,
  tableGetData,
}) => {
  //@ts-ignore
  const { agent } = useContext(userContext as context);
  const [sourcesForm, setSourcesForm] = useState(FORM);

  const sourceModify = async (id: string) => {
    try {
      const data = await getSourceById(agent.company, id);
      setSourcesForm({
        ...data,
        categorySelect: data.category,
        source_type: data.type,
      });
    } catch {}
  };
  const removeSource = async (id: string) => {
    try {
      const data = await deleteSourceById(agent.company, id, agent.email);
      toast.success(data, {
        autoClose: 1500,
      });
      setTimeout(() => {
        tableGetData();
        onClose(false);
      }, 1500);
    } catch {
      toast.error("Error al eliminar source", {
        autoClose: 2000,
      });
    }
  };

  useEffect(() => {
    if (action === "edit" && tableSelected === "fuentes_de_origen") {
      //@ts-ignore
      sourceModify(selectedRow.id);
    }
  }, [action]);

  const deleteSource = () => {
    //@ts-ignore
    removeSource(selectedRow.id);
  };

  return (
    <>
      <div className="modal-container">
        <Modal
          size={`${
            tableSelected === "responses"
              ? action == "delete"
                ? "2xl"
                : "7xl"
              : "2xl"
          }`}
          popup={true}
          show={true}
          onClose={() => onClose(false)}
        >
          <Modal.Header
            className={`${
              tableSelected === "responses"
                ? action == "delete"
                  ? ""
                  : "modalHeader"
                : ""
            } [&_h3]:text-white [&_h3]:pl-2 [&_h3]:pt-2`}
            onClick={async (event) => {
              event.stopPropagation();
            }}
          >
            {/* {(tableSelected === "fuentes_de_origen" && action !== 'delete') ? 'Add source' : 'Eliminar Source'} */}
          </Modal.Header>
          <Modal.Body
            className="body-modal !pt-2"
            onClick={async (event) => {
              event.stopPropagation();
            }}
          >
            <div
              className="text-center w-full fast-answers-container flex"
              onClick={async (event) => {
                event.stopPropagation();
              }}
            >
              <div className="w-full">
                {tableSelected !== "fuentes_de_origen" &&
                  tableSelected !== "smart_messages" && (
                    <FormConfig
                      action={action}
                      tableSelected={tableSelected}
                      selectedRow={selectedRow}
                      tableGetData={tableGetData}
                      closeModal={() => onClose(false)}
                    />
                  )}
                {tableSelected === "smart_messages" && (
                  <TopicConfig
                    selectedRow={selectedRow}
                    tableGetData={tableGetData}
                    closeModal={() => onClose(false)}
                  />
                )}
                {tableSelected === "fuentes_de_origen" &&
                  action !== "delete" && (
                    <div>
                      <AddSource
                        closeModal={() => onClose(false)}
                        dataForm={sourcesForm}
                        setTableReload={tableGetData}
                      />
                    </div>
                  )}
                {tableSelected === "fuentes_de_origen" &&
                  action === "delete" && (
                    <div className="flex flex-col w-full py-3">
                      <p className="text-white text-xl my-3">
                        Esta seguro que desea eliminar el Source?
                      </p>
                      <div className="flex gap-3 w-full items-center justify-center mt-3">
                        <button
                          className="bg-[#ff5758] rounded text-white px-6 py-2 text-sm"
                          onClick={() => onClose(false)}
                        >
                          Cancelar
                        </button>
                        <button
                          className="bg-[#20cab4] rounded text-white px-6 py-2 text-sm"
                          onClick={() => deleteSource()}
                        >
                          Continuar
                        </button>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        toastStyle={{
          border: "1px solid white",
          backgroundColor: "#212944",
        }}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default ModalConfig;
