import { useEffect, useState } from "react";
import { Workflow } from "../../interfaces/workflow.interface";
import { MdOutlineRoute } from "react-icons/md";
import logo from "../../images/Chatty_Logo01.png";
import { useNavigate } from "react-router-dom";
import { Skeleton } from "@mui/material";

interface WorkflowSidebarProps {
  workflows: Array<Workflow>;
  onSelect: (workflowId: string) => void;
  onNewWorkflow: () => void;
  selectedWorkflowIndex: number;
}

export const WorkflowSidebar: React.FC<WorkflowSidebarProps> = ({
  workflows,
  onSelect,
  onNewWorkflow,
  selectedWorkflowIndex,
}) => {
  const navigate = useNavigate();

  const [_workflows, setWorkflows] = useState<Array<Workflow>>([]);
  const [selectedItem, setSelectedItem] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<string>("");

  useEffect(() => {
    setSelectedItem(selectedWorkflowIndex);
  }, [selectedWorkflowIndex]);

  useEffect(() => {
    setWorkflows(workflows);
  }, [workflows]);

  const handleSelect = (workflowId: string) => {
    const index = workflows.findIndex(
      (workflow) => workflow._id === workflowId
    );
    setSelectedItem(index);
    onSelect(workflows[index]._id!);
  };

  const filteredWorkflows = _workflows.filter((workflow) =>
    workflow.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="drawer drawer-open text-white overflow-y-scroll">
      <div className="drawer-side">
        <div className="sticky top-0 bg-darkerBackground p-4 w-full h-16 flex flex-row gap-2 items-center z-10 cursor-default">
          <img
            src={logo}
            className="h-14 cursor-pointer"
            onClick={() => navigate("/home")}
            alt="chatty logo"
          />
          <MdOutlineRoute size={40} />
          <p className="text-xl">Workflows</p>
        </div>
        <div className="p-2 bg-darkerBackground">
          <input
            type="text"
            placeholder="Buscar workflows"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full p-2 rounded text-black bg-mainBackground text-white"
          />
        </div>
        <ul className="menu bg-mainBackground text-base-content min-h-full pr-2">
          {filteredWorkflows.length ? (
            filteredWorkflows.map((workflow, index) => (
              <div
                onClick={() => handleSelect(workflow._id!)}
                key={workflow._id}
                className={`cursor-pointer text-white flex flex-row gap-2 p-2
                  hover:bg-gray-800
                  ${
                    workflows.findIndex((w) => w._id === workflow._id) ===
                    selectedItem
                      ? "border-l-4 border-principalColor bg-gray-800"
                      : "bg-mainBackground"
                  }`}
              >
                <div className="w-full flex flex-row">
                  <div className="w-2/12">
                    <MdOutlineRoute
                      style={{ color: "#aab6be", fontSize: 36 }}
                    />
                  </div>
                  <div className="w-10/12">
                    <p>{workflow.title}</p>
                    <p className="text-xs">
                      {workflow.description.length > 70
                        ? workflow.description.substring(0, 70) + "..."
                        : workflow.description}
                    </p>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="p-4">
              {[...Array(5)].map((_, i) => (
                <div key={i} className="flex flex-row gap-4 p-2">
                  <Skeleton variant="rounded" width={80} height={80} />
                  <Skeleton variant="rounded" width={700} height={40} />
                </div>
              ))}
            </div>
          )}
        </ul>
        <div className="sticky bottom-0 bg-darkerBackground p-2 w-full h-16 flex flex-row gap-2 items-center z-10 cursor-default flex justify-center">
          <button
            className="bg-principalColor hover:bg-cyan-500 text-white py-2 px-4 rounded"
            onClick={() => {
              onNewWorkflow();
            }}
          >
            Nuevo workflow
          </button>
        </div>
      </div>
    </div>
  );
};
