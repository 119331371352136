import {
  FormControl,
  InputLabel,
  Menu,
  TextareaAutosize,
  TextField,
  Tooltip,
} from "@mui/material";
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {
  getSourceCheckerList,
  getSourcesCategories,
  getSourcesSchema,
  getSourcesType,
  getTopics,
  postSources,
  putSources,
} from "../../services/Analytics.services";
import userContext from "../../utils/userContext";
import Checkbox from "@mui/material/Checkbox";
import { AiFillCloseCircle } from "react-icons/ai";
import SearchAutocomplete from "../SearchAutocomplete/SearchAutocomplete";
import { getAllTags } from "../../services/Preview.service";
import { Tag } from "../../interfaces";
import { toast } from "react-toastify";
import { getFlows, getProductsDropdown } from "../../services/Tabs.service";
import WorkflowItem from "../Workflows/WorkflowItem";
import { MdClose } from "react-icons/md";
import { TiInfoLarge } from "react-icons/ti";
import { TbWorldWww } from "react-icons/tb";
import { IoIosOptions } from "react-icons/io";
import { TiFlowChildren } from "react-icons/ti";

export interface UtmParametersType {
  base_url: string;
  utm_source: string;
  utm_medium: string;
  utm_campaign: string;
  utm_term: string;
  utm_content: string;
}

type FormSources = {
  agent_email: string;
  name: string;
  type: string;
  source_type: string;
  description: string;
  tags: any[];
  products: any[];
  flow: any[];
  ad_id: string;
  trackeable: boolean;
  category: string;
  categorySelect: string;
  trigger: string;
  source_checker: string;
  topic_id?: string;
  topic_name?: string;
  whatsapp_url: string;
  utm_parameters?: UtmParametersType;
};

type Props = {
  closeModal: (value: React.SetStateAction<boolean>) => void;
  dataForm: FormSources;
  setTableReload: () => Promise<void>;
};
type RepeatType = {
  count: number;
  delay_sec: number;
  till_done: boolean;
};
interface WorkflowType {
  actions: string[];
  companyId: string;
  description: string;
  repeat: RepeatType;
  title: string;
  version: number;
  _id: string;
}
interface ProductType {
  id: string;
  name: string;
}
const AddSource: React.FC<Props> = ({
  closeModal,
  dataForm,
  setTableReload,
}) => {
  const [form, setForm] = useState(dataForm);
  const [typeOptions, setTypeOptions] = useState([]);
  const [methodOptions, setMethodOptions] = useState([]);
  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const [topicsOptions, setTopicsOptions] = useState([]);
  const [isAutomation, setIsAutomation] = useState(false);
  const [isUTM, setIsUTM] = useState(false);
  const [tags, setTags] = useState<Tag[]>([]);
  const [products, setProducts] = useState<Tag[]>([]);
  const [workflows, setWorkflows] = useState<WorkflowType[]>([]);

  const { agent } = useContext(userContext) as any;

  const [anchorElTag, setAnchorElTag] = React.useState<null | HTMLElement>(
    null
  );
  const openTag = Boolean(anchorElTag);
  const [anchorElProd, setAnchorElProd] = React.useState<null | HTMLElement>(
    null
  );
  const openProd = Boolean(anchorElProd);
  const [anchorElWorkflow, setAnchorElWorkflow] =
    React.useState<null | HTMLElement>(null);
  const openWorkflow = Boolean(anchorElWorkflow);
  const [selectedTags, setSelectedTags] = useState(dataForm.tags || []);
  const [selectedWorkflows, setSelectedWorkflows] = useState(
    dataForm.flow || []
  );
  const [selectedProducts, setSelectedProducts] = useState(
    dataForm.products || []
  );

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | SelectChangeEvent
    >,
    isUtmParam = false
  ) => {
    if (isUtmParam) {
      setForm({
        ...form,
        //@ts-ignore
        utm_parameters: {
          ...form.utm_parameters,
          //@ts-ignore
          [e.target.name]: e.target.value,
        },
      });
    } else {
      setForm({
        ...form,
        //@ts-ignore
        [e.target.name]:
          //@ts-ignore
          e.target.name === "trackeable" ? e.target.checked : e.target.value,
      });
    }
  };

  const addSource = async (body: any) => {
    const process = toast.loading("Agregando Source...");
    try {
      let response = await postSources(agent.company, body);
      toast.update(process, {
        render: response.message,
        type: "success",
        isLoading: false,
        autoClose: 1000,
      });
      setTimeout(() => {
        setTableReload();
        closeModal(false);
      }, 1500);
    } catch (e) {
      toast.update(process, {
        //@ts-ignore
        render: e.response.data.message,
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
    }
  };
  const updateSource = async (body: any) => {
    const process = toast.loading("Por favor espere...");

    try {
      let response = await putSources(agent.company, body, body.id);

      toast.update(process, {
        render: response.message,
        type: "success",
        isLoading: false,
        autoClose: 1000,
      });
      setTimeout(() => {
        setTableReload();
        closeModal(false);
      }, 1500);
    } catch (e) {
      toast.update(process, {
        //@ts-ignore
        render: e.response.data.message,
        type: "error",
        isLoading: false,
        autoClose: 2000,
      });
    }
  };

  const getCategoriesSource = async () => {
    try {
      let response = await getSourcesCategories(agent.company);
      setCategoriesOptions(response);
    } catch {}
  };

  const getTopicsSource = async () => {
    try {
      let response = await getTopics(agent.company);
      setTopicsOptions(response);
    } catch {}
  };

  const getSourceChecker = async (type: string) => {
    try {
      let response = await getSourceCheckerList(type);
      setMethodOptions(
        response.map((respon: any) => {
          return {
            value: respon.type,
            label: respon.label,
            description: respon.description,
          };
        })
      );
    } catch {}
  };

  useEffect(() => {
    getSourceChecker(form.source_type);
  }, [form.source_type, form.type]);

  const getSchema = async (isEdit: boolean) => {
    try {
      let response = await getSourcesSchema(agent.company);
      if (!isEdit) {
        const filteredSources = response.filter(
          //@ts-ignore
          (respon) =>
            respon.type !== "topic_default_source" &&
            respon.type !== "whatsapp_default_source"
        );
        setTypeOptions(filteredSources);
      } else {
        setTypeOptions(response);
      }
    } catch {}
  };

  const sendForm = () => {
    let formToSend = form;

    formToSend.tags = selectedTags.map((tag: Tag) => tag.id);
    formToSend.products = selectedProducts.map((prod: ProductType) => prod.id);
    formToSend.flow = selectedWorkflows.map((flow: WorkflowType) => flow._id);

    if (formToSend.category === "") {
      formToSend.category = form.categorySelect;
    }

    formToSend.type = form.source_type;
    //@ts-ignore
    delete formToSend.categorySelect;
    //@ts-ignore
    delete formToSend.source_type;
    //@ts-ignore
    if (formToSend.id) {
      updateSource(formToSend);
    } else {
      addSource(formToSend);
    }
  };

  const getAllFlows = async () => {
    try {
      const data = await getFlows(agent.company);
      setWorkflows(data);
    } catch (e) {
      console.log("Error fetching workflows:", e);
    }
  };

  useEffect(() => {
    getAllFlows();
    getAllTags(agent.company).then((data) => {
      setTags(data);
    });
    getProductsDropdown(agent.company).then((data) => {
      setProducts(data);
    });
    getCategoriesSource();
    getTopicsSource();
    setForm({ ...dataForm, agent_email: agent.email });
    setSelectedProducts(dataForm.products);
    setSelectedTags(dataForm.tags);
    setSelectedWorkflows(dataForm.flow);
    let isEdit = false;
    if (dataForm.type !== "") {
      isEdit = true;
    }
    getSchema(isEdit);
  }, [dataForm]);

  const onTagSelect = (tag: any) => {
    //@ts-ignore
    if (!selectedTags.some((p: any) => p.id === tag.id)) {
      //@ts-ignore
      setSelectedTags([...selectedTags, tag]);
      handleCloseTag();
    } else {
      toast.error("El tag ya está en la lista.", {
        autoClose: 2000,
      });
    }
  };

  const removeTag = (id: string) => {
    setSelectedTags(selectedTags.filter((tag: Tag) => tag.id !== id));
  };

  const removeWorkflow = (id: string) => {
    setSelectedWorkflows(
      selectedWorkflows.filter((workflow: WorkflowType) => workflow._id !== id)
    );
  };

  const removeProduct = (id: string) => {
    setSelectedProducts(
      selectedProducts.filter((prod: ProductType) => prod.id !== id)
    );
  };

  const onProductSelect = (product: any) => {
    //@ts-ignore
    if (!selectedProducts.some((p: any) => p.id === product.id)) {
      //@ts-ignore
      setSelectedProducts([...selectedProducts, product]);
      handleCloseProd();
    } else {
      toast.error("El producto ya está en la lista.", {
        autoClose: 2000,
      });
    }
  };

  const onWorkflowSelect = (workflowItem: any) => {
    //@ts-ignore
    if (!selectedWorkflows.some((p: any) => p._id === workflowItem._id)) {
      //@ts-ignore
      setSelectedWorkflows([...selectedWorkflows, workflowItem]);
      handleCloseWorkflow();
    } else {
      toast.error("El workflow ya está en la lista.", {
        autoClose: 2000,
      });
    }
  };

  const handleClickTag = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElTag(event.currentTarget);
  };
  const handleCloseTag = () => {
    setAnchorElTag(null);
  };

  const handleCloseWorkflow = () => {
    setAnchorElWorkflow(null);
  };
  const handleClickWorkflow = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElWorkflow(event.currentTarget);
  };

  const handleClickProd = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElProd(event.currentTarget);
  };
  const handleCloseProd = () => {
    setAnchorElProd(null);
  };

  return (
    <div className="flex gap-5 border-t border-[#1a2238]">
      <div className="border-r pr-5 pt-5 border-[#1a2238] min-w-[160px]">
        <button className="flex items-start justify-start gap-2">
          <IoIosOptions
            className={`text-[24px] ${
              isAutomation || isUTM ? "text-white" : "text-[#20cab4]"
            }`}
          />
          <h2
            className={`text-left ${
              isAutomation || isUTM ? " text-white" : " text-[#20cab4]"
            } mb-5`}
            onClick={() => {
              setIsAutomation(false);
              setIsUTM(false);
            }}
          >
            Configuración
          </h2>
        </button>
        {form.source_type === "utm_source" && (
          <button className="flex items-start justify-start gap-2">
            <TbWorldWww
              className={`text-[24px] ${
                !isUTM ? "text-white" : "text-[#20cab4]"
              }`}
            />
            <h2
              className={`text-left ${
                !isUTM ? " text-white" : " text-[#20cab4]"
              } mb-5`}
              onClick={() => {
                setIsAutomation(false);
                setIsUTM(true);
              }}
            >
              UTMs
            </h2>
          </button>
        )}
        <button className="flex items-start justify-start gap-2">
          <TiFlowChildren
            className={`text-[24px] ${
              !isAutomation ? "text-white" : "text-[#20cab4]"
            }`}
          />
          <h2
            className={`text-left ${
              !isAutomation ? " text-white" : " text-[#20cab4]"
            } mb-5`}
            onClick={() => {
              setIsUTM(false);
              setIsAutomation(true);
            }}
          >
            Automatización
          </h2>
        </button>
      </div>
      <div className="py-5 min-w-[450px] pr-5">
        {!isAutomation && !isUTM && (
          <>
            <h2 className="text-left text-white mb-5">
              Añadir source - fuente de origen
            </h2>
            <div className="flex flex-col">
              <label className="text-sm text-[#a3aeb8] mb-3 text-left">
                Tipo de source
              </label>
              <Select
                notched
                labelId="source_type"
                id="source_type"
                value={form.source_type}
                name="source_type"
                label="Tipo de fuente de origen"
                //@ts-ignore
                onChange={(e) => handleChange(e)}
                className="mb-5 input-source"
                style={{
                  pointerEvents:
                    form.source_type === "whatsapp_default_source" ||
                    form.source_type === "topic_default_source"
                      ? "none"
                      : "all",
                }}
              >
                {typeOptions.map((option: any, i: number) => (
                  <MenuItem key={i} value={option.type}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
              <label className="text-sm text-[#a3aeb8] mb-3 text-left">
                Nombre
              </label>
              <TextField
                id="name"
                name="name"
                onChange={(e) => handleChange(e)}
                value={form.name}
                className="mb-5 input-source"
                style={{
                  pointerEvents:
                    form.source_type === "whatsapp_default_source" ||
                    form.source_type === "topic_default_source"
                      ? "none"
                      : "all",
                }}
                // disabled={}
                InputLabelProps={{ shrink: true }}
              />
              <h2 className="text-left text-white mb-5 mt-5">
                Configuracion específica
              </h2>
              {form.source_type === "pure_ad" ? (
                <>
                  <label className="text-sm text-[#a3aeb8] mb-3 text-left">
                    ID del anuncio (ad id)
                  </label>
                  <TextField
                    id="ad_id"
                    name="ad_id"
                    onChange={(e) => handleChange(e)}
                    value={form.ad_id}
                    className="mb-5 input-source"
                    InputLabelProps={{ shrink: true }}
                  />
                </>
              ) : form.source_type === "utm_source" ||
                form.source_type === "whatsapp_default_source" ||
                form.source_type === "topic_default_source" ? (
                <>
                  <label className="text-sm text-[#a3aeb8] mb-3 text-left">
                    Metodo de identificacion de mensajes
                  </label>
                  <Select
                    notched
                    labelId="source_checker"
                    id="source_checker"
                    value={form.source_checker}
                    name="source_checker"
                    label="Metodo de identificacion de mensajes"
                    placeholder="Selecciona un metodo de identificacion de mensajes"
                    //@ts-ignore
                    onChange={(e) => handleChange(e)}
                    className="mb-5 input-source"
                    style={{
                      pointerEvents:
                        form.source_type === "whatsapp_default_source" ||
                        form.source_type === "topic_default_source"
                          ? "none"
                          : "auto",
                    }}
                  >
                    {methodOptions.map((option: any) => {
                      return (
                        <MenuItem value={option.value}>{option.label}</MenuItem>
                      );
                    })}
                  </Select>
                  {}
                  {form.source_checker === "smart_messages" && (
                    <>
                      <label className="text-sm text-[#a3aeb8] mb-3 text-left">
                        Topic - grupo de mensajes
                      </label>
                      {methodOptions.map((option, i) => {
                        //@ts-ignore
                        if (option.value === form.source_checker) {
                          return (
                            <div
                              key={i}
                              className="rounded bg-[#bdbdbd1a] p-3 w-full mb-3"
                            >
                              <p className="text-sm text-[#a3aeb8]">
                                {/* @ts-ignore */}
                                {option.description}
                              </p>
                            </div>
                          );
                        }
                        return "";
                      })}
                      <Select
                        notched
                        labelId="topic_id"
                        id="topic_id"
                        value={form.topic_id}
                        name="topic_id"
                        label="Topic"
                        style={{
                          //@ts-ignore
                          pointerEvents:
                            form.source_type === "topic_default_source" ||
                            form.source_type === "whatsapp_default_source"
                              ? "none"
                              : "all",
                        }}
                        //@ts-ignore
                        onChange={(e) => handleChange(e)}
                        className="mb-5 input-source"
                      >
                        {topicsOptions.map((option: any) => (
                          <MenuItem value={option._id}>{option.name}</MenuItem>
                        ))}
                      </Select>
                      {form.whatsapp_url !== "" && (
                        <>
                          <label className="text-sm text-[#a3aeb8] mb-3 text-left">
                            WhatsApp Link
                          </label>
                          <TextField
                            id="whatsapp_url"
                            name="whatsapp_url"
                            onChange={(e) => handleChange(e)}
                            value={form.whatsapp_url}
                            className="mb-5 input-source"
                            InputLabelProps={{ shrink: true }}
                          />
                        </>
                      )}
                    </>
                  )}
                  {/* {form.source_type === 'topic_default_source' && (
										<>
										<label className="text-sm text-[#a3aeb8] mb-3 text-left">Grupo de mensajes base (topic)</label>
										<TextField
											id="topic_name"
											name='topic_name'
											onChange={(e) => handleChange(e)}
											value={form.topic_name}
											className='mb-5 input-source'
											InputLabelProps={{ shrink: true }}
										/>
										</>
									)} */}
                </>
              ) : form.source_type === "other_source" &&
                form.source_checker === "smart_messages" ? (
                <>
                  <label className="text-sm text-[#a3aeb8] mb-3 text-left">
                    Metodo de identificacion de mensajes
                  </label>
                  <Select
                    notched
                    labelId="source_checker"
                    id="source_checker"
                    value={form.source_checker}
                    name="source_checker"
                    label="Metodo de identificacion de mensajes"
                    placeholder="Selecciona un metodo de identificacion de mensajes"
                    //@ts-ignore
                    onChange={(e) => handleChange(e)}
                    className="mb-5 input-source"
                  >
                    {methodOptions.map((option: any) => {
                      if (option.value === "smart_messages") {
                        return (
                          <MenuItem value={option.value}>
                            {option.label}
                          </MenuItem>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </Select>
                  {form.source_checker === "smart_messages" && (
                    <>
                      <label className="text-sm text-[#a3aeb8] mb-3 text-left">
                        Topic - grupo de mensajes
                      </label>
                      {methodOptions.map((option, i) => {
                        //@ts-ignore
                        if (option.value === form.source_checker) {
                          return (
                            <div
                              key={i}
                              className="rounded bg-[#bdbdbd1a] p-3 w-full mb-3"
                            >
                              <p className="text-sm text-[#a3aeb8]">
                                {/* @ts-ignore */}
                                {option.description}
                              </p>
                            </div>
                          );
                        }
                        return "";
                      })}
                      <Select
                        notched
                        labelId="topic_id"
                        id="topic_id"
                        value={form.topic_id}
                        name="topic_id"
                        label="Topic"
                        //@ts-ignore
                        onChange={(e) => handleChange(e)}
                        className="mb-5 input-source"
                      >
                        {topicsOptions.map((option: any) => (
                          <MenuItem value={option._id}>{option.name}</MenuItem>
                        ))}
                      </Select>
                      {form.whatsapp_url !== "" && (
                        <>
                          <label className="text-sm text-[#a3aeb8] mb-3 text-left">
                            WhatsApp Link
                          </label>
                          <TextField
                            id="whatsapp_url"
                            name="whatsapp_url"
                            onChange={(e) => handleChange(e)}
                            value={form.whatsapp_url}
                            className="mb-5 input-source"
                            InputLabelProps={{ shrink: true }}
                          />
                        </>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  <label className="text-sm text-[#a3aeb8] mb-3 text-left">
                    Metodo de identificacion de mensajes
                  </label>
                  <Select
                    notched
                    labelId="source_checker"
                    id="source_checker"
                    value={form.source_checker}
                    name="source_checker"
                    label="Metodo de identificacion de mensajes"
                    placeholder="Selecciona un metodo de identificacion de mensajes"
                    //@ts-ignore
                    onChange={(e) => handleChange(e)}
                    className="mb-5 input-source"
                    style={{
                      pointerEvents:
                        form.source_type === "topic_default_source" ||
                        form.source_type === "whatsapp_default_source"
                          ? "none"
                          : "all",
                    }}
                  >
                    {methodOptions.map((option: any) => (
                      <MenuItem value={option.value}>{option.label}</MenuItem>
                    ))}
                  </Select>
                  {form.source_checker !== "" &&
                    methodOptions.map((option, i) => {
                      //@ts-ignore
                      if (option.value === form.source_checker) {
                        return (
                          <div
                            key={i}
                            className="rounded bg-[#bdbdbd1a] p-3 w-full mb-3"
                          >
                            <p className="text-sm text-[#a3aeb8]">
                              {/* @ts-ignore */}
                              {option.description}
                            </p>
                          </div>
                        );
                      }
                      return "";
                    })}
                  {form.source_type !== "other_source" &&
                    form.source_checker !== "smart_messages" && (
                      <>
                        <p className="text-white text-[14px] mb-2 text-left pt-3 border-t border-[#bdbdbd42]">
                          Categoria
                        </p>
                        <>
                          <label className="text-sm text-[#a3aeb8] mb-3 text-left">
                            Selecciona una de las categorias existentes
                          </label>
                          <FormControl className="add-source-select">
                            <div className="flex gap-3 items-center justify-center">
                              <Select
                                notched
                                labelId="categorySelect"
                                id="categorySelect"
                                value={form.categorySelect}
                                name="categorySelect"
                                label="Categoria"
                                disabled={
                                  form.source_type === "topic_default_source" ||
                                  form.source_type === "whatsapp_default_source"
                                    ? true
                                    : false
                                }
                                //@ts-ignore
                                onChange={(e) => handleChange(e)}
                                className="input-source w-full"
                              >
                                {categoriesOptions.map((option: any) => (
                                  <MenuItem value={option}>{option}</MenuItem>
                                ))}
                              </Select>
                              {form.categorySelect !== "" && (
                                <button
                                  className="w-[14px] h-full flex"
                                  onClick={() =>
                                    setForm({ ...form, categorySelect: "" })
                                  }
                                >
                                  <AiFillCloseCircle />
                                </button>
                              )}
                            </div>
                          </FormControl>
                        </>
                        {form.categorySelect === "" && (
                          <>
                            <label className="text-sm text-[#a3aeb8] mb-3 text-left mt-3">
                              Crea una nueva categoria
                            </label>
                            <TextField
                              id="category"
                              name="category"
                              disabled={
                                form.source_type === "topic_default_source" ||
                                form.source_type === "whatsapp_default_source"
                                  ? true
                                  : false
                              }
                              onChange={(e) => handleChange(e)}
                              value={form.category}
                              className="mb-5 input-source"
                            />
                          </>
                        )}
                      </>
                    )}
                  <label className="text-sm text-[#a3aeb8] mt-5 mb-3 text-left border-t border-[#bdbdbd42] pt-3">
                    Escribe un mensaje disparador
                  </label>
                  <TextareaAutosize
                    id="trigger"
                    name="trigger"
                    onChange={handleChange}
                    disabled={
                      form.source_type === "topic_default_source" ||
                      form.source_type === "whatsapp_default_source"
                        ? true
                        : false
                    }
                    value={form.trigger}
                    className="mb-5 input-source bg-[#1a2238] text-[14px] text-[#43f8e8] rounded"
                  />
                  {form.whatsapp_url !== "" && (
                    <>
                      <label className="text-sm text-[#a3aeb8] mb-3 text-left">
                        WhatsApp Link
                      </label>
                      <TextField
                        id="whatsapp_url"
                        name="whatsapp_url"
                        onChange={(e) => handleChange(e)}
                        value={form.whatsapp_url}
                        className="mb-5 input-source"
                        disabled={
                          form.source_type === "topic_default_source" ||
                          form.source_type === "whatsapp_default_source"
                            ? true
                            : false
                        }
                        InputLabelProps={{ shrink: true }}
                      />
                    </>
                  )}
                  <label className="text-sm text-[#a3aeb8] mt-5 text-left">
                    Trackear como fuente
                  </label>
                  <div className="flex gap-2 items-center justify-start">
                    <Checkbox
                      checked={form.trackeable}
                      name="trackeable"
                      disabled={
                        form.source_type === "topic_default_source" ||
                        form.source_type === "whatsapp_default_source"
                          ? true
                          : false
                      }
                      id="trackeable"
                      // color="success"
                      // label="Trackear"
                      inputProps={{ "aria-label": "controlled" }}
                      onChange={(e) => handleChange(e)}
                      defaultChecked
                    />
                    <p className="text-sm text-[#09f8e8]">Trackear</p>
                  </div>
                </>
              )}
              <div className="flex justify-end w-full mt-5 gap-5">
                <button
                  className="bg-[#ff5758] rounded text-white px-6 py-2 text-sm"
                  onClick={() => closeModal(false)}
                >
                  Descartar
                </button>
                <button
                  className="bg-[#20cab4] rounded text-white px-6 py-2 text-sm"
                  onClick={() => setIsAutomation(true)}
                >
                  Siguiente
                </button>
                <button
                  className="bg-[#20cab4] rounded text-white px-6 py-2 text-sm"
                  onClick={() => sendForm()}
                >
                  Confirmar
                </button>
              </div>
            </div>
          </>
        )}
        {isAutomation && (
          <>
            <h2 className="text-left text-white mb-5">Automatización</h2>
            <div className="flex flex-col relative">
              <div className="flex flex-col relative mb-3">
                <label className="text-sm text-[#a3aeb8] mb-3 text-left">
                  Añade etiquetas
                </label>
                <button
                  aria-controls={openTag ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openTag ? "true" : undefined}
                  onClick={handleClickTag}
                  className="rounded w-5 h-5 flex items-center justify-center text-[#acb8c0] border border-[#acb8c0] absolute right-0 top-0"
                >
                  +
                </button>
                <Menu
                  anchorEl={anchorElTag}
                  open={openTag}
                  onClose={handleCloseTag}
                >
                  <SearchAutocomplete
                    placeholder="Buscar etiquetas"
                    data={tags}
                    //@ts-ignore
                    onSelect={onTagSelect}
                    type="tag"
                  />
                </Menu>
                <div className="w-full">
                  {/* @ts-ignore */}
                  {selectedTags.map((tag: Tag, i) => (
                    <div
                      key={i}
                      className="relative ml-2 mb-2 float-left rounded px-2 pr-7 py-1 bg-[#49526f]"
                    >
                      <button
                        className="absolute top-2 right-1"
                        onClick={() => removeTag(tag.id)}
                      >
                        <MdClose style={{ fontSize: 18, color: "white" }} />
                      </button>
                      <span className="text-white text-sm">{tag.name}</span>
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex flex-col relative mb-3">
                <label className="text-sm text-[#a3aeb8] mb-3 text-left">
                  Añade productos
                </label>
                <button
                  aria-controls={openProd ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openProd ? "true" : undefined}
                  onClick={handleClickProd}
                  className="rounded w-5 h-5 flex items-center justify-center text-[#acb8c0] border border-[#acb8c0] absolute right-0 top-0"
                >
                  +
                </button>
                <Menu
                  anchorEl={anchorElProd}
                  open={openProd}
                  onClose={handleCloseProd}
                >
                  <SearchAutocomplete
                    placeholder="Buscar productos"
                    data={products}
                    //@ts-ignore
                    onSelect={onProductSelect}
                    type="tag"
                  />
                </Menu>
                <div className="w-full">
                  {/* @ts-ignore */}
                  {selectedProducts.map((prod: ProductType, i) => (
                    <div
                      key={i}
                      className="relative ml-2 mb-2 float-left rounded px-2 pr-7 py-1 bg-[#49526f]"
                    >
                      <button
                        className="absolute top-2 right-1"
                        onClick={() => removeProduct(prod.id)}
                      >
                        <MdClose style={{ fontSize: 18, color: "white" }} />
                      </button>
                      <span className="text-sm text-white">{prod.name}</span>
                    </div>
                  ))}
                </div>
              </div>
              <label className="text-sm text-[#a3aeb8] mb-3 text-left">
                Añade una nota
              </label>
              <TextareaAutosize
                id="description"
                name="description"
                onChange={handleChange}
                value={form.description}
                className="mb-5 input-source bg-[#1a2238] text-[14px] text-[#43f8e8] rounded"
              />
              <div className="flex flex-col relative mb-3">
                <label className="text-sm text-[#a3aeb8] mb-3 text-left">
                  Añade workflows
                </label>
                <button
                  aria-controls={openWorkflow ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openWorkflow ? "true" : undefined}
                  onClick={handleClickWorkflow}
                  className="rounded w-5 h-5 flex items-center justify-center text-[#acb8c0] border border-[#acb8c0] absolute right-0 top-0"
                >
                  +
                </button>
                <Menu
                  anchorEl={anchorElWorkflow}
                  open={openWorkflow}
                  onClose={handleCloseWorkflow}
                >
                  <SearchAutocomplete
                    placeholder="Buscar workflows"
                    data={workflows}
                    //@ts-ignore
                    onSelect={onWorkflowSelect}
                    type="workflow"
                  />
                </Menu>
                <div className="w-full">
                  {/* @ts-ignore */}
                  {selectedWorkflows.map((workflowItem: WorkflowType, i) => {
                    return (
                      <WorkflowItem
                        flow={workflowItem}
                        key={i}
                        deleteWorkflow={() => removeWorkflow(workflowItem._id)}
                      />
                      // <span className="ml-2 mb-2 float-left rounded text-white text-sm px-2 py-1 bg-[#49526f]">{workflowItem.title}</span>
                    );
                  })}
                </div>
              </div>
              <div className="flex justify-end w-full mt-5 gap-5">
                <button
                  className="bg-[#ff5758] rounded text-white px-6 py-2 text-sm"
                  onClick={() => closeModal(false)}
                >
                  Descartar
                </button>
                <button
                  className="bg-[#20cab4] rounded text-white px-6 py-2 text-sm"
                  onClick={() => setIsAutomation(false)}
                >
                  Atras
                </button>
                <button
                  className="bg-[#20cab4] rounded text-white px-6 py-2 text-sm"
                  onClick={() => sendForm()}
                >
                  Confirmar
                </button>
              </div>
            </div>
          </>
        )}
        {isUTM && (
          <>
            <h2 className="text-left text-white mb-5">Configuracion UTMs</h2>
            <div className="flex flex-col relative">
              <div className="flex flex-col relative mb-3">
                <label className="text-sm text-[#a3aeb8] mb-3 text-left">
                  URL
                </label>
                <TextField
                  id="base_url"
                  name="base_url"
                  onChange={(e) => handleChange(e, true)}
                  value={form.utm_parameters?.base_url}
                  className="mb-5 input-source"
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className="flex flex-col relative mb-3 py-2">
                <div className="flex w-full">
                  <label className="text-sm text-[#a3aeb8] mb-3 text-left mr-3">
                    Source
                  </label>
                  <Tooltip
                    title={
                      "Identifica el origen del tráfico. Mientras más especifico, mejor. Ejemplo: Meta, Google, Instagram."
                    }
                    placement="right"
                  >
                    <div>
                      <TiInfoLarge className="text-[20px] text-[#acb8c0]" />
                    </div>
                  </Tooltip>
                </div>
                <TextField
                  id="utm_source"
                  name="utm_source"
                  onChange={(e) => handleChange(e, true)}
                  value={form.utm_parameters?.utm_source}
                  className="mb-5 input-source"
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className="flex flex-col relative mb-3">
                <div className="flex w-full">
                  <label className="text-sm text-[#a3aeb8] mb-3 text-left mr-3">
                    Medium
                  </label>
                  <Tooltip
                    title={
                      "Especifica el tipo o la naturaleza del medio a través del cual llega el tráfico. Ejemplo: paid media, organic."
                    }
                    placement="right"
                  >
                    <div>
                      <TiInfoLarge className="text-[20px] text-[#acb8c0]" />
                    </div>
                  </Tooltip>
                </div>
                <TextField
                  id="utm_medium"
                  name="utm_medium"
                  onChange={(e) => handleChange(e, true)}
                  value={form.utm_parameters?.utm_medium}
                  className="mb-5 input-source"
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className="flex flex-col relative mb-3">
                <div className="flex w-full">
                  <label className="text-sm text-[#a3aeb8] mb-3 text-left mr-3">
                    Campaign
                  </label>
                  <Tooltip
                    title={
                      "Identifica la campaña de marketing o la promoción que se está ejecutando. Ejemplo: Black Friday, Oferta Navideña."
                    }
                    placement="right"
                  >
                    <div>
                      <TiInfoLarge className="text-[20px] text-[#acb8c0]" />
                    </div>
                  </Tooltip>
                </div>
                <TextField
                  id="utm_campaign"
                  name="utm_campaign"
                  onChange={(e) => handleChange(e, true)}
                  value={form.utm_parameters?.utm_campaign}
                  className="mb-5 input-source"
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className="flex flex-col relative mb-3">
                <div className="flex w-full">
                  <label className="text-sm text-[#a3aeb8] mb-3 text-left mr-3">
                    Term
                  </label>
                  <Tooltip
                    title={
                      "Se utiliza en campañas pagas de motores de búsqueda para identificar términos o palabras clave."
                    }
                    placement="right"
                  >
                    <div>
                      <TiInfoLarge className="text-[20px] text-[#acb8c0]" />
                    </div>
                  </Tooltip>
                </div>
                <TextField
                  id="utm_term"
                  name="utm_term"
                  onChange={(e) => handleChange(e, true)}
                  value={form.utm_parameters?.utm_term}
                  className="mb-5 input-source"
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className="flex flex-col relative mb-3">
                <div className="flex w-full">
                  <label className="text-sm text-[#a3aeb8] mb-3 text-left mr-3">
                    Content
                  </label>
                  <Tooltip
                    title={
                      "Diferencia entre contenidos o enlaces similares dentro de la misma campaña, útil para prueba A/B."
                    }
                    placement="right"
                  >
                    <div>
                      <TiInfoLarge className="text-[20px] text-[#acb8c0]" />
                    </div>
                  </Tooltip>
                </div>{" "}
                <TextField
                  id="utm_content"
                  name="utm_content"
                  onChange={(e) => handleChange(e, true)}
                  value={form.utm_parameters?.utm_content}
                  className="mb-5 input-source"
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className="flex justify-end w-full mt-5 gap-5">
                <button
                  className="bg-[#ff5758] rounded text-white px-6 py-2 text-sm"
                  onClick={() => closeModal(false)}
                >
                  Descartar
                </button>
                <button
                  className="bg-[#20cab4] rounded text-white px-6 py-2 text-sm"
                  onClick={() => sendForm()}
                >
                  Confirmar
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AddSource;
