import { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import AddTemplate from "../components/AddTemplate/AddTemplate";
import ModalTemplate from "../components/AddTemplate/ModalTemplate";
import NavBar from "../components/NavBar/NavBar";
import TableConfig from "../components/TableConfig/TableConfig"
import { getPlantillas } from "../services/Plantillas.config";
import { getSourcesTable, getTable } from "../services/Tables.service";
import userContext from "../utils/userContext";
import './Config.css'

const PlantillasNew: React.FC = () => {
	const params = useParams()
	const [tableData, setTableData] = useState([])
	const [loading, setLoading] = useState(true)
	const [showModal, setShowModal] = useState(false)
	const { agent } = useContext<any>(userContext);

	const getData = async () => {
		setLoading(true)
		try {
			const data = await getPlantillas(agent.company)
			const dataToAdd = data.campaigns
			setTableData(dataToAdd)
			setLoading(false)
		}
		catch {
			console.log('err')
			setLoading(false)
		}
	}

	useEffect(()=> {
		getData()
	}, [params.table, agent.company])

    return (
			<div className="min-h-screen w-screen font-sans items-center justify-center text-black bg-[#212944]">
				<NavBar />
				<div style={{padding: '25px 35px'}}>
					<div className="title-config">
						<h2>Plantillas</h2>
					</div>
					<p className="text-white mt-2 mb-6 text-lg ">Envía plantillas a un único destinatario o crea campañas masivas de plantillas</p>
					<button 
              onClick={() => setShowModal(true)} 
              className="px-5 py-3 text-white rounded-sm bg-[#35cab4]">
                Nuevo envio
            </button>
					<div className="mt-3 flex flex-col">
						<h3 className="text-white text-xl">Campañas de plantillas</h3>
						<p className="text-white mt-2 mb-3 text-lg">Consulta el estado de las campañas de plantillas enviadas.<br/>
						Selecciona una campaña para pausarla / continuarla / descargar la lista de destinatarios junto al resultado de cada envío.</p>
					</div>
					{showModal && (<ModalTemplate onClose={() => setShowModal(false)} refetchTable={getData}/>)}
					<TableConfig data={tableData} loading={loading} tableGetData={getData} isTemplates={true}/>
				</div>
			</div>
    )
};

export default PlantillasNew;