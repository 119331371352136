import { Autocomplete, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material"
import { Product } from "../../interfaces"
import { AutomationForm, ProductFlow, Tags } from "../../interfaces/analytics.interface"
import Tag from "../../interfaces/tag.interface"

type Props = {
    automationForm: AutomationForm,
    handleChange: (e: any) => void,
    products: Product[],
    flows: ProductFlow[],
    tags: Tags[],
    tagsChange: (e: any, values:any) => void,
    setAutomationForm: React.Dispatch<React.SetStateAction<AutomationForm>>,
    updateAutomationForm: () => Promise<void>
}

const AutomationView: React.FC<Props> = ({automationForm, handleChange, products, flows, tags, tagsChange, setAutomationForm, updateAutomationForm}) => {
    return(
        <div className='w-full p-3'>
            <h4 className='text-2xl text-grey'>Automatizacion</h4>
            <h3 className='text-xl text-grey mb-5 mt-5'>Selecciona un producto, flow, highlight y etiquetas que se añadiran automaticamente a cada usuario que provenga de esta pagina.</h3>
            <div className='flex gap-4 mt-10'>
                <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Producto</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="producto"
                    name="producto"
                    value={automationForm.product.name}
                    label="producto"
                    onChange={handleChange}
                >
                {products.map((product) => <MenuItem value={product._id}>{product.name}</MenuItem>)}
                </Select>
                </FormControl>
                <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Copilot Flow</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="flow"
                    name="flow"
                    value={automationForm.flow.name}
                    label="flow"
                    onChange={handleChange}
                >
                    {flows.map((flow) => <MenuItem value={flow.id}>{flow.name}</MenuItem>)}
                </Select>
                </FormControl>
            </div>
            <h3 className='text-xl text-grey mb-5 mt-5'>Etiquetas</h3>
            <span className='text-[#a5a5a5] block mb-5'>Las etiquetas seran añadidas ademas de las que esten dentro del flow seleccionado.</span>
            <Autocomplete
            multiple
            id="tags-standard"
            options={tags}
            getOptionLabel={(option) => option.name}
            onChange={tagsChange}
            defaultValue={automationForm.tags}
            renderInput={(params) => (
                <TextField
                {...params}
                variant="standard"
                label="Buscar tags"
                placeholder="Favorites"
                className='outline-none'
                />
            )}
            />
            <h3 className='text-xl text-grey mb-5 mt-5'>Highlight</h3>
            <span className='text-[#a5a5a5] block mb-2'>Siempre que un usuario provenga de esta pagina web se añadira un Highlight.</span>
            <TextField
            placeholder="El usuario inicio una conversacion desde la fuente indicada como ...."
            className='w-full mt-2'
            value={automationForm.highlight_description}
            onChange={(e) => setAutomationForm({...automationForm, 'highlight_description': e.target.value})}
            multiline
            rows={2}
            maxRows={4}
            />
            <div className='flex justify-end mt-5'>
            <button 
                className='text-sm bg-[#3DE8CF] text-white p-2 pl-3 pr-3 rounded' 
                onClick={() => updateAutomationForm()}>Guardar cambios</button>
            </div>
        </div>
    )
}

export default AutomationView