import axios from "axios";

const getFiles = async (company) => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_V3}/multimedia/${company}/all`
  );
  return response;
};

const uploadFile = async (file, company) => {
  const formData = new FormData();
  formData.append("file", file);

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_V3}/multimedia/${company}/upload_file`,
      {
        method: "POST",
        body: formData,
      }
    );

    if (!response.ok) {
      throw new Error("Error al subir el archivo");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error en la solicitud de subida:", error);
    throw error;
  }
};

const deleteFile = async (company, key) => {
  console.log(company, key);
  const response = await axios.delete(
    `${process.env.REACT_APP_API_V3}/multimedia/${company}/${key}`
  );
  return response;
};

export { getFiles, uploadFile, deleteFile };
