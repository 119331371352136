import React, { useEffect, useState, useRef, useContext } from "react";
import { DropdownItem } from "flowbite-react/lib/esm/components/Dropdown/DropdownItem";
import { getAllTags } from "../../services/Preview.service";
import { GiBasket } from "react-icons/gi";
import { BsFillClockFill, BsListUl } from "react-icons/bs";
import { AiOutlinePlus } from "react-icons/ai";
import { Button } from "flowbite-react";
import Tag from "../../interfaces/tag.interface";
import './FilterTagsDropdown.css'
import userContext from "../../utils/userContext";
import {context} from '../../interfaces'
import { AiOutlineStar } from "react-icons/ai";
import { MdMarkChatUnread } from "react-icons/md";
import { FaEllipsisH } from "react-icons/fa";
import { BsCart2 } from "react-icons/bs";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { getAllProducts } from "../../services/products.service";

interface Props {
  conversationId: number;
  setTagFilterIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  tagFilterIsOpen: boolean;
  setConversationsFilterByTag: any;
  searchValue: any;
  setSearchValue: any;
  tagSelectedComplete: any;
  setTagSelectedComplete: any;
  setFilterSelection: React.Dispatch<React.SetStateAction<boolean>>;
  setShowUnread: React.Dispatch<React.SetStateAction<boolean>>;
  showUnread: boolean;  
  expandSearch: React.Dispatch<React.SetStateAction<boolean>>;
  openSearch: boolean;
  filterSelection: boolean;
  setStarredFilter: React.Dispatch<React.SetStateAction<number>>;
  starredFilter: number;
  setTimeLeftFilter:React.Dispatch<React.SetStateAction<number>>;
  timeLeftFilter:number;
  setProductsFilter:React.Dispatch<React.SetStateAction<string[]>>;
  productsFilter:string[]
}
const FilterTagsDropdown: React.FC<Props> = ({
  conversationId,
  tagFilterIsOpen,
  setTagFilterIsOpen,
  setConversationsFilterByTag,
  searchValue,
  setSearchValue,
  tagSelectedComplete,
  setTagSelectedComplete,
  setFilterSelection,
  filterSelection,
  setShowUnread,
  showUnread,
  openSearch,
  expandSearch,
  setStarredFilter,
  starredFilter,
  setTimeLeftFilter,
  timeLeftFilter,
  setProductsFilter,
  productsFilter
}) => {
  const {agent, context, clientUser} = useContext(userContext) as context;
  const [allTags, setAllTags] = useState<Tag[]>([]);
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [openSlide, setopenSlide] = useState<boolean>(false); 
  const [showMenuProducts, setShowMenuProducts] = React.useState<null | HTMLElement>(null);
  const [products, setProducts] = useState([])

  const dropdown = useRef<HTMLDivElement>(null)
  const $ref = useRef<HTMLDivElement>(null)
  const inputTags = useRef<HTMLInputElement>(null)

  const open = Boolean(showMenuProducts);

  const openMenuProducts = (event: React.MouseEvent<HTMLButtonElement>) => {
    setShowMenuProducts(event.currentTarget);
  };

  const closeMenuProducts = () => {
    setShowMenuProducts(null);
  };

  useEffect(() => {
      getAllProducts("products", agent.company) 
      .then((response) => {
        console.log('productos: ', response)
        setProducts(response); 
      })
      .catch((error) => {
          console.log(error);
      });
  }, []);

  const filterProducts = (id:string) => {
    console.log('filtrar producto')
    let array = productsFilter
    if(productsFilter.includes(id)) {
      const index = array.indexOf(id);
      if (index > -1) {
        array.splice(index, 1); 
      }
    } else {
      array.push(id)
    }
    console.log('array para enviar: ', array)
    setProductsFilter(array)
    setShowSpinner(true);
    setTagSelectedComplete([]);
    setFilterSelection(false)
    setStarredFilter(0)
    setShowUnread(false)
  }

  const filterUnread = (event: any) => {
    event.stopPropagation();
    setShowSpinner(true);
    setTagSelectedComplete([]);
    setFilterSelection(false)
    setStarredFilter(0)
    setShowUnread(!showUnread);
  };

  const filterStarred = (event: any) => {
    console.log('filter starred')
    event.stopPropagation();
    setShowSpinner(true);
    setTagSelectedComplete([]);
    setFilterSelection(false)
    setShowUnread(false)
    setStarredFilter(starredFilter !== 0 ? 0 : 1);
  };

  const filterTimeLeft = (event: any) => {
    console.log('filter starred')
    event.stopPropagation();
    setShowSpinner(true);
    setTagSelectedComplete([]);
    setFilterSelection(false)
    setShowUnread(false)
    setStarredFilter(0)
    setTimeLeftFilter(timeLeftFilter !== 0 ? 0 : 1);
  };

  const lastButtonAction = (e: any) => {
      if(openSearch) {
        expandSearch(false)
      } else {
        e.stopPropagation();
        setTagFilterIsOpen(!tagFilterIsOpen);
      }
  }

  useEffect(() => {
    if(tagFilterIsOpen) {
      if(inputTags.current){
        inputTags.current.focus()
      }
    }
  }, [tagFilterIsOpen])

  useEffect(() => {

    if (showSpinner) {
      const timeout = setTimeout(() => {
        setShowSpinner(false);
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [showSpinner]);

  useEffect(() => {
    getAllTags(agent.company).then((data) => {
      setAllTags(data);
    });
  }, [conversationId]);
 
  useEffect(()=> {
    document.addEventListener('mousedown',closeOpenMenus)
    return ()=> {
      document.removeEventListener('mousedown',closeOpenMenus)
    }
  })

  const closeOpenMenus = (e:any)=>{
    if(dropdown.current && openSlide && !dropdown.current.contains(e.target)){
      setopenSlide(false)
    }
  }

  const removeFilters = (type:string) => {
    if(type === 'selection') {
      // setShowUnread(false)
      setTagSelectedComplete([]);
      setFilterSelection(true)
    }
    else if(type === 'tag') {
      setFilterSelection(false)
      setShowUnread(false)
    }
    else if(type === 'all'){
      setFilterSelection(false)
      setShowUnread(false)
      setTagSelectedComplete([]);
    }
    setSearchValue("");
    setShowSpinner(false)
  }

  useEffect( ()=> {
    
    if(openSearch) {
      removeFilters('all')
    }
  }, [openSearch])

  return (
    <div className={`${openSearch ? 'expand' : ''} relative items-center justify-end align-middle z-10 containerFilterBtn`} ref={$ref}>
      <div className="flex items-center justify-end align-middle gap-2" >
        <button 
          className='border-2 border-[#35cab4] flex justify-center align-center btnFilter' 
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={openMenuProducts}
        >
          <BsCart2 style={{color: '#576080'}}/>
        </button>
        <Menu
          id="basic-menu"
          anchorEl={showMenuProducts}
          open={open}
          onClose={closeMenuProducts}
          className='[&_ul]:!bg-[#212944] [&_ul]:!p-0'
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {Object.keys(products).map((keyName, i) => {
            return(
              <MenuItem 
                key={i}
                //@ts-ignore
                onClick={() => filterProducts(products[keyName]._id)}
                className='!text-white'>
                  {/* @ts-ignore */}
                  {productsFilter.includes(products[keyName]._id) && <span className="absolute bg-[#33c48d] w-2 h-2 top-3 left-1 rounded-full"></span>}
                  {/* @ts-ignore */}
                  <p className={productsFilter.includes(products[keyName]._id) && '!text-[#33c48d]'}>{products[keyName].name}</p>
              </MenuItem>
          )})}
        </Menu>
        <button
          className={`${showUnread ? 'active' : ''} border-2 border-[#35cab4] flex justify-center align-center btnFilter`}
          onClick={filterUnread}
          title="Filtrar por leídos"
        >
          <MdMarkChatUnread />
        </button>
        <button 
          className={`${timeLeftFilter !== 0 ? 'active' : ''} border-2 border-[#35cab4] flex justify-center align-center btnFilter`}
          title="Filtrar por tiempo"
          onClick={filterTimeLeft}
        >
          <BsFillClockFill />
        </button>
        <button
          className={`${starredFilter !== 0 ? 'active' : ''} border-2 border-[#35cab4] flex justify-center align-center btnFilter`}
          onClick={filterStarred}
          title="Mostrar destacados"
        >
          <AiOutlineStar />
        </button>
        <button
          className={`${tagFilterIsOpen ? 'active' : ''} border-2 border-[#35cab4] flex justify-center align-center btnFilter`}
          onClick={(event) => lastButtonAction(event)}
          title={`${openSearch ? 'Mas filtros' : 'Filtrar por tags'}`}
        >
          {openSearch ? (
            <AiOutlinePlus />
          ) : (
            <FaEllipsisH />
          )}
        </button>
      
      </div>
      <div className="ml-auto mr-auto w-full">
        <div className="mx-2 relative">
          {tagFilterIsOpen ? (
            <div className="absolute rounded-2xl border-b-4 right-0 top-full mt-2 w-48 max-h-72 overflow-hidden tags-filter-container">
              <div className="shadow-md active:shadow-xl transition duration-300 ease-in-out transform hover:shadow-lg border-solid border-gray-200 border-2 bg-white rounded-t-2xl overflow-y-auto">
                <input
                  type="text"
                  className="w-full py-2 px-3 text-black border-gray-300 rounded-t-2xl focus:outline-none focus:ring-2 focus:ring-indigo-200 focus:border-transparent"
                  placeholder="Buscar tags"
                  ref={inputTags}
                  value={searchValue}
                  onChange={(
                    event: React.ChangeEvent<HTMLInputElement> | any
                  ) => {
                    console.log('este')
                    setSearchValue(event.target.value);
                  }}
                />
                <Button
                  style={{
                    width: "20px",
                    height: "20px",
                    position: "absolute",
                    top: "13px",
                    right: `6px`,
                    backgroundColor: "#d3dbd3",
                    overflow: "hidden",
                  }}
                  onClick={() => {
                    setSearchValue("");
                  }}
                >
                  <GiBasket className="text-black w-4 h-4" />
                </Button>
                <div
                  className="bg-red-300 hover:bg-red-300"
                  onClick={async (event) => {
                    event.stopPropagation();
                    setTagSelectedComplete([]);
                    setSearchValue("");
                    setTagFilterIsOpen(false);
                    setConversationsFilterByTag([]);
                    removeFilters('tag')
                  }}
                >
                  <DropdownItem key={"clear"} className="justify-center">
                    <div className="flex justify-center align-middle ">
                      <button className="w-full">Limpiar</button>
                    </div>
                  </DropdownItem>
                </div>
                <div className="dropdown-tags">
                  {searchValue.length !== 0
                    ? Array.isArray(allTags) &&
                    allTags
                        ?.filter((tag) =>
                          tag.name
                            .toLowerCase()
                            .includes(searchValue.toLowerCase())
                        )
                        .filter(
                          (tag) =>
                            !tagSelectedComplete
                              .map((t: any) => t.id)
                              .includes(tag.id)
                        )
                        .map((t, i) => (
                          <div
                            key={i}
                            onClick={async (event) => {
                              event.stopPropagation();
                              setTagSelectedComplete([
                                ...tagSelectedComplete,
                                t,
                              ]);
                              removeFilters('tag')
                              setSearchValue("");
                              setTagFilterIsOpen(false);
                            }}
                          >
                            <DropdownItem key={t.id} className="justify-center">
                                <button className="text-black w-full">{t.name}</button>
                            </DropdownItem>
                          </div>
                        ))
                    : Array.isArray(allTags) &&
                    allTags
                        ?.filter(
                          (tag) =>
                            !tagSelectedComplete
                              .map((t: any) => t.id)
                              .includes(tag.id)
                        )
                        .map((t, i) => (
                          <div
                            key={i}
                            onClick={async (event) => {
                              event.stopPropagation();
                              setTagSelectedComplete([
                                ...tagSelectedComplete,
                                t,
                              ]);
                              removeFilters('tag')
                              console.log('agrego')
                              setSearchValue("");
                              setTagFilterIsOpen(false);
                            }}
                          >
                            <DropdownItem key={t.id} className="justify-center">
                              <div className="flex justify-center align-middle">
                                <button className="text-black w-full">{t.name}</button>
                              </div>
                            </DropdownItem>
                          </div>
                        ))}
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default FilterTagsDropdown;