import React from 'react'
import { Tooltip } from "@mui/material"
import { AiOutlineClose } from "react-icons/ai"
import { MdOutlineRoute } from "react-icons/md"
import userContext from '../../utils/userContext'
import moment from 'moment'
import { MdDeleteOutline } from "react-icons/md";

const WorkflowItem = ({flow, deleteWorkflow}) => {
	const {context} = React.useContext(userContext);

    return (
<div className='flex mt-5 gap-2 items-stretch w-full justify-center'>
  <div className='border-[#ffffff6e] border rounded-xl px-2 py-2 flex items-center gap-2 relative'
		style={{
			width: context === 'agent' ? '80%' : '100%'
		}}>
    <MdOutlineRoute style={{color: '#aab6be', fontSize: 36, width: '20%'}}/>
    
    <div className='flex flex-col overflow-hidden pr-5 w-[80%]'>
      <p className='text-[#aab6be] text-base uppercase font-bold whitespace-nowrap text-ellipsis overflow-hidden w-full'>{flow.title}</p>
      <Tooltip title={flow.description} placement="bottom-end">
        <div>
          <span className='text-[#aab6be] text-sm whitespace-nowrap text-ellipsis overflow-hidden w-full'>{flow.description}</span>
        </div>
      </Tooltip>
      {flow.next_call && <span className='text-[#aab6be] text-sm'>Proxima iteracion {moment(flow.next_call).format("DD/MM/YYYY HH:MM") }</span>}
    </div>
  </div>

  {context === 'agent' && 
    <button 
      className='z-10 px-2 bg-[#acb8c0] rounded h-auto flex items-center justify-center w-[20%]'
      onClick={() => {
        deleteWorkflow(flow._id)
      }} 
    >
      <MdDeleteOutline className='text-[#212944] text-[25px]' />
    </button>
  }
</div>

		
    )
}

export default WorkflowItem