import { Close as CloseIcon } from '@mui/icons-material';
import React, { useRef, useEffect } from 'react';

// @ts-ignore
const SaleDetailsModal: React.FC<{ open: boolean, sale: any | null, onClose: () => void, allProducts: any }> = ({ open, sale, onClose, allProducts }) => {
    const modalRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
                onClose();
            }
        };

        // Agrega escuchadores de eventos cuando el modal está abierto
        document.addEventListener('mousedown', handleClickOutside);

        // Limpieza al desmontar el componente o cerrar el modal
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    if (!open || !sale) return null;

    return (
        <div className="fixed inset-0 z-50 overflow-auto bg-smoke-light flex" >
            <div 
                className="relative p-4 bg-white w-full max-w-md m-auto flex-col flex rounded-lg"
                ref={modalRef}
                // Quitar onClick={(e) => e.stopPropagation()} si existiera aquí previamente
            >
                <div className="flex justify-between items-center mb-4">
                    <h3 className="text-lg">Venta</h3>
                    <button onClick={onClose} className="text-red-500 hover:text-red-700">
                        <CloseIcon />
                    </button>
                </div>
                <div>
                    <p>Nombre del Producto: {allProducts[sale.product_id].name}</p>
                    <p>Cantidad: {sale.quantity}</p>
                    <p>Total: {sale.total_amount}</p>
                    <p>Pago: {sale.paid_amount}</p>
                    <p>Moneda: {sale.currency}</p>
                    <p>Cuotas: {sale.installments}</p>
                    <p>Detalles: {sale.details?.observation}</p>
                    <p>Fecha: {(new Date(sale.created_at).toISOString().split('T')[0])}</p>
                </div>
            </div>
        </div>
    );
};

export default SaleDetailsModal;
