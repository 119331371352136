import { axiosConfig } from "./axios.config";

const addTagToChat = async (agent_id, chat_id, tag_id, company) => {
    const res = await axiosConfig.post(`${process.env.REACT_APP_API_V3}/chat_workspace/${company}/agent_workspace/tag`,
    {
      "agent_email": agent_id,
      "chat_id": chat_id,
      "tag_id": tag_id
    }
    );
    
    return res.data;
};

const removeTagToChat = async (agent_id, chat_id, tag_id, company) => {
    const res = await axiosConfig.delete(`${process.env.REACT_APP_API_V3}/chat_workspace/${company}/agent_workspace/tag?tag_id=${tag_id}`,
      {
        data: {
          "agent_email": agent_id,
          "chat_id": chat_id,
        }
      }
    );
    
    return res.data;
};




export { addTagToChat, removeTagToChat }