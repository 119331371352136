import { axiosConfig } from "./axios.config";

const getTabs = async (company) => {

  const res = await axiosConfig.get(`${process.env.REACT_APP_API_ANALYTICS}tabs/${company}`
  );
  return res.data;
};

const getTemplate = async (company, tab) => {

    const res = await axiosConfig.get(`${process.env.REACT_APP_API_ANALYTICS}tab-template/${company}/${tab}`
    );
    return res.data;
  };

const getInfoLayout = async (company, tab) => {
    const res = await axiosConfig.post(`${process.env.REACT_APP_API_ANALYTICS}tab_data`,
    {
        "empresa": company,
        "tab": tab,
        "filters": {
            "globalDateFilter": {
            "startDate": "2023-01-01",
            "endDate": "2023-03-31"
            }
        }
    }
    );
    return res
}


const getFlows = async (company_id) => {
  const res = await axiosConfig.get(`${process.env.REACT_APP_API_COPILOT}/flows/${company_id}/?visible_filter=true`
  );

  return res.data;
};

const deleteFlow = async (flow_id) => {

  const res = await axiosConfig.delete(`${process.env.REACT_APP_API_COPILOT}/flows/${flow_id}/`)
  return res.data;
};

const postFlow = async (flow_id, body) => {

  const res = await axiosConfig.post(`${process.env.REACT_APP_API_COPILOT}/flows/${flow_id}/`, 
  body)
  return res.data;
};

const linkFlow = async (flow_id, chat_id, company_id) => {
  const res = await axiosConfig.post(`${process.env.REACT_APP_API_COPILOT}/links/`, {
    "flow_id": flow_id,
    "chat_id": chat_id,
    "company_id" : company_id
  })

  return res.data
}

const deleteLink = async (link_id) => {
  
  const res = await axiosConfig.delete(`${process.env.REACT_APP_API_COPILOT}/links/${link_id}`)

  return res.data
}

const getFlowsByChat = async (chat_id) => {
  const res = await axiosConfig.get(`${process.env.REACT_APP_API_COPILOT}/links/${chat_id}`)

  return res.data
}

const getProductsDropdown = async (company_id) => {
  const res = await axiosConfig.get(`${process.env.REACT_APP_API_V3}/dropdowns/${company_id}/products`)

  return res.data
}

export {
  getTabs,
  getTemplate,
  getInfoLayout,
  getFlows,
  deleteFlow,
  postFlow,
  linkFlow,
  getFlowsByChat,
  deleteLink,
  getProductsDropdown
}