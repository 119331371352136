import axios from 'axios';
const sendNumberRegistration = async (pn_id, wb_id, code, email, name, company_name) => {
    const res = await axios.post(`${process.env.REACT_APP_API_NEW_URL}/config/number-registration`,
    {
      "phone_number_id": pn_id,
      "waba_id": wb_id,
      "code": code,
      "root_user_email": email,
      "root_user_name": name ? name : "Usuario",
      "company_name": company_name ? company_name : "Empresa"
    }
    );
    return res.data;
}

export {
    sendNumberRegistration
}