import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Action,
  ActionField,
  ActionType,
} from "../../../interfaces/action.interface";
import {
  getActionTypes,
  getOptionLabel,
} from "../../../services/actions.service";
import { renderIcon } from "../../../utils/icons";
import FullScreenLoader from "../../FullScreenLoader";
import { BsPlay } from "react-icons/bs";

interface ViewActionProps {
  action: Action;
}

export const ViewAction: React.FC<ViewActionProps> = ({ action }) => {
  const [actionType, setActionType] = useState<ActionType | null>(null);
  const [optionLabels, setOptionLabels] = useState<{ [key: string]: string }>(
    {}
  );
  const [loading, setLoading] = useState<boolean>(true);

  const fetchActionType = useCallback(async () => {
    try {
      const actionTypes = await getActionTypes();
      const foundActionType = actionTypes.data.find(
        (at: ActionType) => at.type === action.type
      );
      setActionType(foundActionType || null);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    } catch (error) {
      console.error("Error fetching action types", error);
    }
  }, [action.type]);

  useEffect(() => {
    setLoading(true);

    fetchActionType();
  }, [fetchActionType]);

  useEffect(() => {
    const fetchOptionLabels = async () => {
      if (actionType?.fields) {
        const labels: { [key: string]: string } = {};
        const promises = actionType.fields.map((field) => {
          if (field.options && action.values[field.values[0].name]) {
            return getOptionLabel(
              actionType.options_key,
              action.values[field.values[0].name],
              action.companyId
            ).then((res) => {
              labels[field.values[0].name] = res.data;
            });
          }
        });
        await Promise.all(promises);
        setOptionLabels(labels);
      }
    };

    fetchOptionLabels();
  }, [actionType, action]);

  const renderField = useCallback(
    (field: ActionField, index: number) => {
      const value = action.values[field.values[0].name];
      const label = optionLabels[field.values[0].name] || value;

      return (
        <div className="flex flex-col gap-2 p-2" key={index}>
          <p className="text-md text-gray-500">{field.label}</p>
          {field.values[0].type === "string" && (
            <p className="text-xl">{label}</p>
          )}
          {field.values[0].type === "list_string" &&
            value?.map((item: string) => (
              <div
                className="text-md bg-gray-800 rounded-lg p-6"
                key={Math.random()}
              >
                {item}
              </div>
            ))}
          {field.values[0].type === "bool" && (
            <p className="text-xl">{value === true ? "Verdadero" : "Falso"}</p>
          )}
        </div>
      );
    },
    [action.values, optionLabels]
  );

  return action && actionType && !loading ? (
    <div className="flex flex-col gap-2">
      <div className="header flex flex-row items-center justify-start gap-2">
        <div className="avatar w-2/12 text-center">
          <div className="w-8/12 m-auto p-1">
            {renderIcon(
              actionType.icon,
              { width: "100%", height: "100%" },
              <BsPlay style={{ width: "100%", height: "100%" }}></BsPlay>
            )}
          </div>
        </div>
        <div className="w-10/12">
          <p className="text-md text-gray-500">{actionType.label}</p>
          <p className="text-xl">{action.title}</p>
        </div>
      </div>

      {actionType?.fields?.map((field, index) => renderField(field, index))}
    </div>
  ) : (
    <div className="">
      <FullScreenLoader isLocal />
    </div>
  );
};
