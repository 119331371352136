import { axiosConfig } from "./axios.config";

const getAreas = async ( company) => {
  const res = await axiosConfig.get(`${process.env.REACT_APP_API_V3}/templates/${company}/permitted_areas`
  );
  return res.data;
};

const getAgents = async ( company) => {
  const res = await axiosConfig.get(`https://api-demo.letschatty.com/v1/rrhh/${company}/available_agents`
  );
  return res.data;
};

const getPlantillas = async ( company) => {
    // const res = await axiosConfig.get(`${process.env.REACT_APP_API_V3}/templates/${company}/all_templates`
        const res = await axiosConfig.get(`${process.env.REACT_APP_API_V3}/templates/${company}/templates_workspace/campaigns`
    );
    return res.data;
}

const getPlantillasForm = async ( company) => {
  const res = await axiosConfig.get(`${process.env.REACT_APP_API_V3}/templates/${company}/all_templates`
  );
  return res.data;
}

const getCsvExample = async (company) => {
  const res = await axiosConfig.get(`${process.env.REACT_APP_API_V3}/templates/${company}/templates_workspace/campaigns/example`)
}

const sendPlantillas = async (body, company) => {
  const res = await axiosConfig.post(`${process.env.REACT_APP_API_V3}/templates/${company}/send_template`, 
  body
  );
  return res.data;
}

const downloadCampaing = async (company, id) => {
  const res = await axiosConfig.get(`${process.env.REACT_APP_API_V3}/templates/${company}/templates_workspace/campaigns/${id}`)
  return res.data
}

const pauseCampaing = async (company, id) => {
  const res = await axiosConfig.post(`${process.env.REACT_APP_API_V3}/templates/${company}/templates_workspace/campaigns/${id}/pause`)
  return res.data
}

const resumeCampaing = async (company, id) => {
  const res = await axiosConfig.post(`${process.env.REACT_APP_API_V3}/templates/${company}/templates_workspace/campaigns/${id}/resume`)
  return res.data
}

const addPlantillaSingle = async (company, body) => {
  const res = await axiosConfig.post(`${process.env.REACT_APP_API_V3}/templates/${company}/templates_workspace`, 
  body
  );
  return res.data;
}

const addPlantillaMultiple = async (company, body) => {
  const res = await axiosConfig.post(`${process.env.REACT_APP_API_V3}/templates/${company}/templates_workspace/csv_template_campaign`, 
  body,
  {
    headers: {
      "Content-Type": "multipart/form-data",
    }
  }
  );
  return res.data;
}
  

export { getAreas, getPlantillas, sendPlantillas, downloadCampaing, pauseCampaing, resumeCampaing, getPlantillasForm, getAgents, addPlantillaSingle, addPlantillaMultiple, getCsvExample }