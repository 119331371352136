import { useEffect, useState } from "react";
import {
  ActionConditionProps,
  ActionConditionType,
} from "../../interfaces/actionCondition.interface";
import { MdEdit } from "react-icons/md";
import { BsPlay, BsThreeDotsVertical } from "react-icons/bs";
import { IoEyeOutline } from "react-icons/io5";
import { RxCrossCircled } from "react-icons/rx";
import { LuAlertCircle } from "react-icons/lu";
import { ImInfo } from "react-icons/im";
import { renderIcon } from "../../utils/icons";
import { GiChoice } from "react-icons/gi";

interface OptionItem {
  name: string;
  icon: any;
  callback: () => void;
  hideOnView?: boolean;
  critical?: boolean;
  critical_name?: string;
}

export const ActionCondition: React.FC<ActionConditionProps> = ({
  id,
  type,
  edit_mode,
  title,
  subtitle,
  description,
  icon,
  is_critical,
  draggableElement,
  on_remove,
  on_toggle_critical,
  on_open_modal,
}) => {
  const [item, setItem] = useState({} as ActionConditionProps);

  useEffect(() => {
    setItem(
      {
        id,
        type,
        edit_mode,
        title,
        subtitle,
        description,
        icon,
        is_critical,
      }!
    );
  }, [id]);

  const actionOptions: OptionItem[] = [
    {
      name: "Ver acción",
      icon: <IoEyeOutline />,
      callback: () => {
        on_open_modal && on_open_modal("view", id, edit_mode);
      },
    },
    {
      name: "Editar",
      icon: <MdEdit />,
      callback: () => {
        on_open_modal && on_open_modal("edit", id);
      },
    },
    {
      name: "Desvincular acción",
      icon: <RxCrossCircled />,
      callback: () => {
        on_remove && on_remove(id);
      },
      hideOnView: true,
    },
  ];

  const conditionOptions: OptionItem[] = [
    {
      name: "Ver condición",
      icon: <IoEyeOutline />,
      callback: () => {
        on_open_modal && on_open_modal("view", id, edit_mode);
      },
    },
    {
      name: "Editar la condición",
      icon: <MdEdit />,
      callback: () => {
        on_open_modal && on_open_modal("edit", id, edit_mode);
      },
    },
    {
      name: "Desvincular condición",
      icon: <RxCrossCircled />,
      callback: () => {
        on_remove && on_remove(id);
      },
      hideOnView: true,
    },
    {
      name: "Convertir en crítica",
      icon: <LuAlertCircle />,
      callback: () => {
        on_toggle_critical && on_toggle_critical(id);
      },
      critical: true,
      critical_name: "Convertir en no crítica",
      hideOnView: true,
    },
  ];

  const dropdownOptions =
    type === ActionConditionType.CONDITION ? conditionOptions : actionOptions;

  return (
    <div className="bg-darkBackground text-white rounded-xl border-2 border-gray-500 p-2 text-left hover:border-principalColor cursor-pointer">
      <div className="flex flex-row items-center">
        {edit_mode && draggableElement}
        <div className="flex flex-row justify-between w-full">
          <div
            className="flex flex-row gap-4 w-11/12"
            onClick={() =>
              on_open_modal && on_open_modal("view", id, edit_mode)
            }
          >
            <div className="avatar w-2/12">
              <div className="w-8 m-auto">
                {renderIcon(
                  icon,
                  { width: "100%", height: "100%" },
                  type === ActionConditionType.CONDITION ? (
                    <GiChoice
                      style={{ width: "100%", height: "100%" }}
                    ></GiChoice>
                  ) : (
                    <BsPlay style={{ width: "100%", height: "100%" }}></BsPlay>
                  )
                )}
              </div>
            </div>
            <div className="w-10/12">
              <h1 className="text-md font-semibold">{title || "Sin nombre"}</h1>
              <h2 className="text-md text-gray-400">{subtitle || ""}</h2>
              {type === ActionConditionType.CONDITION && is_critical && (
                <span className="text-principalColor flex flex-row gap-1 items-center">
                  <span>Condición crítica</span>
                  <ImInfo size={20} />
                </span>
              )}
            </div>
          </div>
          <div className="w-1/12">
            <div className="dropdown dropdown-end w-min h-min">
              <div tabIndex={0} role="button" className="m-1">
                <BsThreeDotsVertical size={30} />
              </div>
              <ul
                tabIndex={0}
                className="dropdown-content dropdown-wf menu bg-base-100 rounded-box z-[1] w-52 p-2 shadow bg-mainBackground"
              >
                {dropdownOptions.map((option) => (
                  <li
                    key={option.name}
                    className={`${!edit_mode && option.hideOnView && "hidden"}`}
                  >
                    <a onClick={option.callback} className='text-white'>
                      {option.icon}
                      {is_critical && option.critical
                        ? option.critical_name
                        : option.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      {type === ActionConditionType.CONDITION &&
        description &&
        description.length && (
          <div
            className="collapse collapse-arrow w-full "
            onClick={(ev: any) => {
              ev.target.parentNode.children[0].checked =
                !ev.target.parentNode.children[0].checked;
            }}
          >
            <input type="checkbox" className="min-h-0" />
            <div className="collapse-title min-h-0 font-medium w-full p-0"></div>
            <div className="collapse-content">
              <p className="text-gray-300">{description}</p>
            </div>
          </div>
        )}
    </div>
  );
};
