import { Modal } from "flowbite-react";
import "./../FormConfig/Form.css"
import React, { useContext } from "react";
import userContext from "../../utils/userContext";
import { context } from "../../interfaces";
import { ToastContainer } from "react-toastify";
import AddTemplate from "./AddTemplate";
import AddTemplateFromChat from "./AddTemplateFromChat";

type Props = {
	onClose:(value: React.SetStateAction<string | boolean>) => void,
	isFromChat?: boolean,
	refetchTable?: () => Promise<void>
}

const ModalTemplate: React.FC<Props> = ({
    onClose,
		isFromChat = false,
		refetchTable
}) => {	
	//@ts-ignore
	const { agent } = useContext(userContext as context);

	return(
		<>
			<div className="modal-container">
					<Modal
						size={`2xl`}
						popup={true}
						show={true}
						onClose={() => onClose(false)}
					>
						<Modal.Header
							className={`[&_h3]:text-white [&_h3]:pl-2 [&_h3]:pt-2`}
							onClick={async (event) => {
								event.stopPropagation();
							}}
						>
              Envio de Plantillas
						</Modal.Header>
						<Modal.Body
							className="body-modal !pt-2"
							onClick={async (event) => {
								event.stopPropagation();
							}}
						>
							<div
								className="text-center w-full fast-answers-container flex"
								onClick={async (event) => {
									event.stopPropagation();
								}}
							>
								<div className="w-full">
										<div>
											{isFromChat ? (
													<AddTemplateFromChat closeModal={() => onClose(false)}/>
											) : (
												<AddTemplate refetchTable={refetchTable} closeModal={() => onClose(false)}/>
											)}
										</div>
								</div>
							</div>
						</Modal.Body>
					</Modal>
					
			</div>
			<ToastContainer 
				position="top-right"
				autoClose={3000}
				toastStyle={{ 
          border: '1px solid white',
          backgroundColor: "#212944" 
				}}
				hideProgressBar
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
		</>
	)
}

export default ModalTemplate