import { Avatar, Button, Dropdown, Modal } from "flowbite-react";
import { useContext, useState } from "react";
import { IoMdLogOut } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import userContext from "../utils/userContext";
import OpenModal from "./OpenModal";
import logo from "../images/Chatty_Logo01.png";
import { useMutation } from "@tanstack/react-query";
import { act_on_chats } from "../services/ChatsActions.service";
import { context, HeaderPreviewProps } from "../interfaces";
import {
  FcCallTransfer,
  FcFilingCabinet,
  FcOnlineSupport,
} from "react-icons/fc";
import { GoHubot } from "react-icons/go";
import { useAuth0 } from "@auth0/auth0-react";

export const HeaderPreview: React.FC<HeaderPreviewProps> = ({
  title,
  allowButton,
  refetchMyConversations,
  filterDropdownSelected,
  bgColor,
  setIsOpen,
  getChatsIsOpen,
  setGetChatsIsOpen,
  dropChatsIsOpen,
  setDropChatsIsOpen,
  setUserInfoIsOpen,
  userInfoIsOpen,
  previewFullData,
}) => {
  const { isModalOpen, setModalOpen, agent, isAlert, setIsAlert, context } =
    useContext(userContext) as context;
  //@ts-ignore
  const { logout } = useAuth0();
  const { setClientUser } = useContext(userContext) as any;

  const navigate = useNavigate();

  const handleMultipleAssignment = useMutation(
    () =>
      act_on_chats(
        agent.id,
        agent.company,
        "agent_workspace",
        "transfer_chats_to_agent",
        [],
        agent.email
      ),
    {
      onSuccess: (res) => {
        refetchMyConversations();
        setIsAlert({
          ...isAlert,
          isOpen: true,
          title: `Se le asignó ${res} chats correctamente`,
          type: "success",
        });
      },
      onError: (err) =>
        setIsAlert({
          ...isAlert,
          isOpen: true,
          title: "Ocurrió un error al tratar de asignar los chats",
          type: "failure",
        }),
      onMutate: () => {
        setModalOpen({ ...isModalOpen, assignment: false });
      },
    }
  );

  const handleLeaveMultipleChats = useMutation(
    () =>
      act_on_chats(
        agent.id,
        agent.company,
        "agent_workspace",
        "dessasign_agent_from_chats",
        []
      ),
    {
      onSuccess: (res) => {
        refetchMyConversations();
        setIsAlert({
          ...isAlert,
          isOpen: true,
          title: `Dejó ${res} chats correctamente`,
          type: "success",
        });
        setClientUser({});
      },
      onError: (err) =>
        setIsAlert({
          ...isAlert,
          isOpen: true,
          title: "Ocurrió un error al tratar de dejar los chats",
          type: "failure",
        }),
      onMutate: () => {
        setModalOpen({ ...isModalOpen, leave: false });
      },
    }
  );

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  const icon =
    filterDropdownSelected === "Esperando agente" ? (
      <FcCallTransfer size={24} className="bg-transparent" />
    ) : filterDropdownSelected === "Con agente" ? (
      <FcOnlineSupport size={24} />
    ) : filterDropdownSelected === "Atendidos" ? (
      <FcFilingCabinet size={24} />
    ) : filterDropdownSelected === "Supervisar Bot" ? (
      <GoHubot size={24} className="bg-white rounded" />
    ) : null;

  const onClickGetChats: any = () => {
    setGetChatsIsOpen(true);
    setModalOpen({ ...isModalOpen, assignment: true });
  };

  const onCloseGetChats = async () => {
    setGetChatsIsOpen(false);
  };

  const onClickDropChats: any = () => {
    setDropChatsIsOpen(true);
    setModalOpen({ ...isModalOpen, leave: true });
  };

  const onCloseDropChats = async () => {
    setDropChatsIsOpen(false);
  };

  const handleConfirmation = async () => {
    if (isModalOpen.assignment) {
      handleMultipleAssignment.mutate();
    } else if (isModalOpen.leave) {
      // console.log('aasdasdas')
      handleLeaveMultipleChats.mutate();
    }
    setGetChatsIsOpen(false);
    setDropChatsIsOpen(false);
  };

  return (
    <>
      <div
        className={`w-full flex justify-between items-center px-4 shadow-lg ${bgColor} py-2.5`}
        style={{ minHeight: "84px" }}
      >
        <img
          src={logo}
          className="h-14 cursor-pointer"
          onClick={() => navigate("/home")}
          alt="chatty logo"
        />
        <div className="flex flex-col items-center">
          <p className="flex text-[#f9f8f8] text-[22px]"> {title} </p>
          <span className=" text-gray-100 text-xs">{`No leidos ${previewFullData?.current_unread_chats} de ${previewFullData?.total_chats}`}</span>
          <div className={`${allowButton ? "flex content-between" : "hidden"}`}>
            <div className="get-chats-modal-container">
              <Modal
                size="xl"
                popup={true}
                show={getChatsIsOpen}
                onClose={onCloseGetChats}
              >
                <Modal.Header
                  onClick={async (event) => {
                    event.stopPropagation();
                  }}
                />

                <Modal.Body
                  onClick={async (event) => {
                    event.stopPropagation();
                  }}
                >
                  <div
                    className="text-center fast-answers-container"
                    onClick={async (event) => {
                      event.stopPropagation();
                    }}
                  >
                    <h3 className="mb-5 text-lg font-normaltext-gray-400">
                      ¿Está seguro que desea asignarse varios chats?
                    </h3>
                    <div className="flex justify-center gap-4">
                      <Button
                        className="bg-principalColor"
                        onClick={handleConfirmation}
                      >
                        Si, asignar
                      </Button>
                      <Button color="failure" onClick={onCloseGetChats}>
                        No, cancelar
                      </Button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
            <div className="drop-chats-modal-container">
              <Modal
                size="xl"
                popup={true}
                show={dropChatsIsOpen}
                onClose={onCloseDropChats}
              >
                <Modal.Header
                  onClick={async (event) => {
                    event.stopPropagation();
                  }}
                />
                <Modal.Body
                  onClick={async (event) => {
                    event.stopPropagation();
                  }}
                >
                  <div
                    className="text-center fast-answers-container"
                    onClick={async (event) => {
                      event.stopPropagation();
                    }}
                  >
                    <h3 className="mb-5 text-lg font-normaltext-gray-400">
                      ¿Está seguro que desea dejar todos los chats?
                    </h3>
                    <div className="flex justify-center gap-4">
                      <Button
                        className="bg-principalColor"
                        onClick={handleConfirmation}
                      >
                        Si, dejar
                      </Button>
                      <Button color="failure" onClick={onCloseDropChats}>
                        No, cancelar
                      </Button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
          </div>
          <div
            className={`flex gap-2 rounded-lg p-1 hover:bg-white hover:rounded-lg hover:p-1 transition duration-300 ease-in-out chat-filter-container`}
          >
            <h3
              className="text-principalColor font-bold rounded-lg"
              onClick={(event) => {
                event.stopPropagation();
                setIsOpen(true);
              }}
            >
              {filterDropdownSelected}
            </h3>
            <div>{icon}</div>
          </div>
        </div>
        <div
          className="flex items-center z-10 justify-end dropdown-upper text-black"
          style={{ zIndex: 999 }}
        >
          <div className={`dropdown dropdown-end`}>
            <label
              tabIndex={0}
              className="btn bg-transparent outline-none border-0 hover:bg-transparent"
              onClick={(event) => {
                event.stopPropagation();
                setUserInfoIsOpen(!userInfoIsOpen);
              }}
            >
              <Avatar
                className="absolute -z-10 text-[#ff6a3d] pointer-events-none"
                img={undefined}
                rounded={true}
                status="online"
                statusPosition="bottom-right"
              />
            </label>
            <ul
              tabIndex={0}
              className={`${
                userInfoIsOpen ? null : "hidden"
              } dropdown-content menu p-2 shadow rounded-box w-auto bg-white decoration-transparent`}
            >
              <Dropdown.Header className="text-[#1B2238] w-auto flex flex-col text-center">
                <span className="block text-sm truncate">{agent.fullName}</span>
                <span
                  className="block truncate text-sm font-medium"
                  title={agent.email}
                >
                  {agent.email}
                </span>
              </Dropdown.Header>
              <Dropdown.Item
                onClick={() => setModalOpen({ ...isModalOpen, session: true })}
              >
                <div className="p-0 justify-between bg-transparent hover:bg-transparent">
                  <p>Cerrar Sesión</p>
                  <IoMdLogOut size={24} className=" text-red-800" />
                </div>
              </Dropdown.Item>
              <OpenModal
                content="¿Está seguro que desea cerrar sesión?"
                value="session"
                btnValue="Sí, cerrar sesión"
                fn={() => handleLogout()}
              />
            </ul>
          </div>
        </div>
      </div>
      {context !== "admin" && (
        <div className="flex w-full justify-between gap-2 mt-1 px-4 ">
          <button
            onClick={async (event) => {
              event.stopPropagation();
              onClickGetChats();
            }}
            className="w-[55%] text-center items-center border py-1 mb-[10px] flex justify-center border-text-gray-100  hover:bg-[#1db9a5] rounded-lg text-gray-100 trainsition-all duration-300 text-[10px] get-chats-modal-container"
          >
            Asignarme 15 chats
          </button>
          <button
            onClick={async (event) => {
              event.stopPropagation();
              onClickDropChats();
            }}
            className="w-[55%] text-center items-center border py-1 mb-[10px] flex justify-center border-text-gray-100  hover:bg-[#1db9a5] rounded-lg text-gray-100 trainsition-all duration-300 text-[10px]"
          >
            Desasignarme todo
          </button>
          <div className="max-w-[40%]">
            {/* <p className="text-gray-100 text-[7px] w-full">{`Hay ${previewFullData.total_chats_unread_waiting_agent} chats no leidos esperando agente`}</p> */}
          </div>
        </div>
      )}
    </>
  );
};
