import { axiosConfig } from "./axios.config";

const getActions = (companyId) => {
  return axiosConfig.get(
    `${process.env.REACT_APP_API_COPILOT}/actions/${companyId}/`
  );
};

const updateAction = (action) => {
  return axiosConfig.put(
    `${process.env.REACT_APP_API_COPILOT}/actions/${action._id}/`,
    action
  );
};

const createAction = (action) => {
  return axiosConfig.post(
    `${process.env.REACT_APP_API_COPILOT}/actions/`,
    action
  );
};

const getActionTypes = () => {
  return axiosConfig.get(`${process.env.REACT_APP_API_V3}/resources/actions`);
};

const getActionFieldOptions = (actionId, companyId) => {
  return axiosConfig.get(
    `${process.env.REACT_APP_API_V3}/dropdowns/${companyId}/actions/${actionId}`
  );
};

const getOptionLabel = (resourceType, resourceIdentifier, companyId) => {
  return axiosConfig.get(
    `${process.env.REACT_APP_API_V3}/dropdowns/${companyId}/label/${resourceType}/${resourceIdentifier}`
  );
};

const deleteAction = (actionId) => {
  return axiosConfig.delete(
    `${process.env.REACT_APP_API_COPILOT}/actions/${actionId}/`
  );
};

export {
  getActions,
  updateAction,
  createAction,
  getActionTypes,
  getActionFieldOptions,
  getOptionLabel,
  deleteAction,
};
