// This hook mean no sense to me. This absolutly need to be refactor.

import { useState, useEffect } from "react";

export const useDocumentClick = (ref: any, initialState: any) => {
  const [isOpen, setIsOpen] = useState(initialState);

  useEffect(() => {
    const handleDocumentClick = (event: any) => {
      if (!event.target.closest(ref)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [ref]);

  return [isOpen, setIsOpen];
};
