import { useContext, useEffect } from "react";
// import userContext from "../utils/userContext";
import { useNavigate } from "react-router-dom";
import logo from "../../images/Chatty_Logo01.png";
import './NavBar.css'


const NavBar: React.FC = () => {
    const navigate = useNavigate();

    return (
			<div className="nav-bar">
				<img
                    src={logo}
                    className="h-14 cursor-pointer ml-2 mr-3"
                    onClick={() => navigate("/home")}
                    alt="chatty logo"
                />
			</div>
    )
};

export default NavBar;