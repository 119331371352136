import { useContext, useEffect, useRef, useState } from "react";
import { ActionConditionType } from "../../../interfaces/actionCondition.interface";
import { Condition } from "../../../interfaces/condition.interface";
import userContext from "../../../utils/userContext";
import { context } from "../../../interfaces";
import { getConditions } from "../../../services/conditions.service";
import { Action } from "../../../interfaces/action.interface";
import { getActions } from "../../../services/actions.service";
import { renderIcon } from "../../../utils/icons";
import { Skeleton } from "@mui/material";

interface ItemListProps {
  type: ActionConditionType;
  onSelect: (item: Condition | Action) => void;
  selectedId?: string;
  isUpdated: boolean;
  setIsUpdated: (updated: boolean) => void;
}

export const ItemList: React.FC<ItemListProps> = ({
  type,
  onSelect,
  selectedId,
  isUpdated,
  setIsUpdated,
}) => {
  const { agent } = useContext(userContext) as context;
  const listRef = useRef<HTMLDivElement>(null);

  const [items, setItems] = useState<Condition[] | Action[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [_selectedId, setSelectedId] = useState<string>(selectedId || "");
  const [isLoading, setIsLoading] = useState(false);

  const fetchConditions = async () => {
    const conditions = await getConditions(agent.company);
    const reversedConditions = conditions.data.reverse();
    setItems(reversedConditions);
    setIsLoading(false);
  };

  const fetchActions = async () => {
    const actions = await getActions(agent.company);
    const reversedActions = actions.data.reverse();
    setItems(reversedActions);
    setIsLoading(false);
  };

  const handleSelect = (item: Condition | Action) => {
    onSelect(item);
  };

  useEffect(() => {
    if (isUpdated) {
      setSelectedId(selectedId || "");
      setIsLoading(true);
      const fetchData = async () => {
        if (type === ActionConditionType.CONDITION) {
          await fetchConditions();
        } else {
          await fetchActions();
        }
        setIsUpdated(false);
      };
      fetchData();
    }
  }, [type, isUpdated]);

  const filteredItems = (items as any).filter((item: Condition | Action) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className={`flex flex-col gap-4 pr-2 `} ref={listRef}>
      <input
        type="text"
        placeholder="Buscar..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="p-2 border border-gray-300 rounded-md bg-mainBackground"
      />
      {isLoading ? (
        <div className="flex flex-col gap-4">
          {[...Array(5)].map((_, i) => (
            <Skeleton variant="rounded" height={85} key={i}></Skeleton>
          ))}
        </div>
      ) : (
        filteredItems.map((item: Condition | Action, index: number) => (
          <div
            onClick={(ev) => handleSelect(item)}
            key={index}
            className={`flex flex-row items-center justify-between gap-2 cursor-pointer  ${
              selectedId === item._id &&
              "bg-principalColor text-black rounded-xl"
            }`}
          >
            <div className="border rounded-xl border-gray-500 p-2 w-full hover:border-principalColor cursor-pointer">
              <div className="header flex flex-row items-center justify-start gap-2">
                <div className="avatar w-2/12 text-center">
                  <div className="w-8/12 p-1 m-auto ">
                    {renderIcon(item.icon, { width: "100%", height: "100%" })}
                  </div>
                </div>
                <div className="w-10/12">
                  <p className="text-base font-medium h-6 overflow-hidden">
                    {item.title}
                  </p>
                  <p className="text-base">
                    {type === "condition"
                      ? (item as Condition).extractor_label
                      : (item as Action).type}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
};
