import { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import NavBar from "../components/NavBar/NavBar";
import TableConfig from "../components/TableConfig/TableConfig";
import {
  getResponseTable,
  getSourcesTable,
  getTable,
} from "../services/Tables.service";
import userContext from "../utils/userContext";
import "./Config.css";

const Config: React.FC = () => {
  const params = useParams();
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { agent } = useContext<any>(userContext);

  const getData = async () => {
    setLoading(true);
    try {
      if (params.table === "fuentes_de_origen") {
        const data = await getSourcesTable(agent.company);
        //@ts-ignore
        setTableData(data);
      } else if (params.table === "responses") {
        const data = await getResponseTable(agent.company);
        //@ts-ignore
        setTableData(data);
      } else {
        const data = await getTable(params.table, agent.company);
        if (params.table === "other_sources" || params.table === "pure_ads") {
          let dataToAdd = data;
          //@ts-ignore
          const tagsName = dataToAdd.map((item) => {
            console.log(item.tags_id_and_name);
            const tag = item.tags_id_and_name.map((i: any) => {
              return i.name;
            });
            item.tags_id_and_name = tag;
            const itemToAdd = item;
            return itemToAdd;
          });
          //@ts-ignore
          dataToAdd = tagsName;
          //@ts-ignore
          setTableData(dataToAdd);
        } else if (params.table === "triggers") {
          //@ts-ignore
          const otherSource = data.map((item) => {
            if (item.other_source_id_and_name !== undefined) {
              item.other_source_id_and_name =
                item.other_source_id_and_name.name;
              const itemToAdd = item;
              return itemToAdd;
            } else {
              return "";
            }
          });
          //@ts-ignore
          const pure_ad = data.map((item) => {
            if (item.pure_ad_id_and_name !== undefined) {
              item.pure_ad_id_and_name = item.name;
              const itemToAdd = item;
              return itemToAdd;
            } else {
              return "";
            }
          });
          //@ts-ignore
          data = otherSource;
          //@ts-ignore
          data = pure_ad;
          setTableData(data);
        } else if (params.table === "impure_ads") {
          let dataToAdd = data;
          //@ts-ignore
          const tagsName = dataToAdd.map((item) => {
            console.log(item.pure_ad_id_and_name);
            const tag = item.pure_ad_id_and_name.map((i: any) => {
              return i.name;
            });
            item.pure_ad_id_and_name = tag;
            const itemToAdd = item;
            return itemToAdd;
          });
          //@ts-ignore
          dataToAdd = tagsName;
          //@ts-ignore
          setTableData(dataToAdd);
        } else {
          console.log("Tags dataToAdd: ", data);
          setTableData(data);
        }
      }
      setLoading(false);
    } catch {
      console.log("err");
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [params.table, agent.company]);

  return (
    <div className="min-h-screen w-screen font-sans items-center justify-center text-black bg-[#212944]">
      <NavBar />
      <div style={{ padding: "25px 35px" }}>
        <div className="title-config">
          <h2>
            Tabla de <b>{params.table?.toLowerCase().replace("_", " ")}</b>
          </h2>
        </div>
        <TableConfig
          data={tableData}
          loading={loading}
          tableGetData={getData}
        />
      </div>
    </div>
  );
};

export default Config;
