import * as React from "react";
import SearchAutocomplete from "../SearchAutocomplete/SearchAutocomplete";
import userContext from "../../utils/userContext";
import Menu from "@mui/material/Menu";
import {
  deleteFlow,
  deleteLink,
  getFlows,
  getFlowsByChat,
  linkFlow,
} from "../../services/Tabs.service";
import { MdOutlineRoute } from "react-icons/md";
import { AiOutlineClose } from "react-icons/ai";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import { Tooltip } from "@mui/material";
import WorkflowItem from "./WorkflowItem";

interface WorkflowType {
  actions: string[];
  companyId: string;
  description: string;
  repeat: RepeatType;
  title: string;
  version: number;
  _id: string;
}

type RepeatType = {
  count: number;
  delay_sec: number;
  till_done: boolean;
};

type FlowInChat = {
  chat_id: string;
  executed_step: number;
  flow_id: string;
  next_call: string;
  repeat_step: number;
  title: string;
  description: string;
  _id: string;
};

export default function WorkflowsTab({
  isBroaderPreview = false,
}: {
  isBroaderPreview?: boolean;
}) {
  const { context, agent, clientUser } = React.useContext<any>(userContext);
  const [workflowsList, setWorkflowsList] = React.useState<WorkflowType[]>([]);
  const [workflowsChatList, setWorkflowsChatList] = React.useState<
    FlowInChat[]
  >([]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getAllFlows = async () => {
    try {
      const data = await getFlows(agent.company);
      console.log("data getAllFlows: ", data);
      setWorkflowsList(data);
    } catch (e) {
      console.log("Error fetching workflows:", e);
    }
  };

  const getChatFlows = async () => {
    try {
      const data = await getFlowsByChat(clientUser.conversation_id);
      console.log("data: ", data);
      setWorkflowsChatList(data);
    } catch (e) {
      console.log("Error fetching workflows:", e);
    }
  };

  const deleteWorkflow = async (id: string) => {
    if (!isBroaderPreview) {
      const process = toast.loading("Por favor espere...");
      try {
        const data = await deleteLink(id);
        getChatFlows();
        toast.update(process, {
          render: data.message,
          type: "success",
          isLoading: false,
          autoClose: 2000,
        });
      } catch (e) {
        console.log("error en agents");
        toast.error("Error la realizar accion", {
          autoClose: 2000,
        });
      }
    }
  };

  const postWorkflow = async (workflow: WorkflowType) => {
    const process = toast.loading("Por favor espere...");
    handleClose();
    try {
      const data = await linkFlow(
        workflow._id,
        clientUser.conversation_id,
        agent.company
      );
      toast.update(process, {
        render: "Workflow agregado!",
        type: "success",
        isLoading: false,
        autoClose: 2000,
      });
      getChatFlows();
    } catch (e) {
      console.log("error en agents");
      toast.update(process, {
        render: "ocurrio un error",
        type: "error",
        isLoading: false,
        autoClose: 2000,
      });
    }
  };

  React.useEffect(() => {
    if (clientUser.conversation_id !== undefined) {
      getChatFlows();
    }
  }, [clientUser.conversation_id]);

  React.useEffect(() => {
    getAllFlows();
  }, []);

  return (
    <>
      {context === "agent" &&
        clientUser.conversation_id &&
        !isBroaderPreview && (
          <button
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            className="rounded w-7 h-7 flex items-center justify-center text-[#acb8c0] border border-[#acb8c0] absolute right-12 top-4"
          >
            +
          </button>
        )}
      <div className="w-full flex flex-col justify-center">
        {workflowsChatList.map((flow, i) => (
          <WorkflowItem flow={flow} key={i} deleteWorkflow={deleteWorkflow} />
        ))}
      </div>

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <SearchAutocomplete
          placeholder="Buscar workflow"
          data={workflowsList}
          onSelect={postWorkflow}
          type="workflow"
        />
      </Menu>

      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        toastStyle={{ 
          border: '1px solid white',
          backgroundColor: "#212944" 
				}}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}
