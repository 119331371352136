import { Button, Modal, Spinner } from "flowbite-react";
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { toast } from "react-toastify";
import userContext from "../../../utils/userContext";
import { CiSearch } from "react-icons/ci";
import { FiPaperclip, FiTrash2, FiUpload } from "react-icons/fi";
import {
  deleteFile,
  getFiles,
  uploadFile,
} from "../../../services/ChatMultimedia.service";
import classNames from "classnames";
import { renderFilePreview } from "../../../utils/helpers";
export interface FileMultimedia {
  name: string;
  is_starred: boolean;
  key: string;
  type: string;
  url: string;
  mime_type: string;
}

type Props = {
  onClose: (value: React.SetStateAction<string | boolean>) => void;
  importedFilesSelected: Dispatch<SetStateAction<FileMultimedia[]>>;
};

const ModalMultimedia: React.FC<Props> = ({
  onClose,
  importedFilesSelected,
}) => {
  //@ts-ignore
  const { agent } = useContext(userContext as context);

  const [files, setFiles] = useState<FileMultimedia[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<FileMultimedia[]>([]);
  const [selectedFile, setSelectedFile] = useState<File>();
  const [loading, setLoading] = useState(false);

  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState({
    x: 0,
    y: 0,
  });
  const [clickedFile, setClickedFile] = useState<FileMultimedia | null>(null);
  const [uploadFileScreen, setUploadFileScreen] = useState(false);
  const contextMenuRef = useRef<HTMLDivElement>(null);
  const [preview, setPreview] = useState<string | null>(null);
  const [fileUploaded, setFileUploaded] = useState<FileMultimedia | null>(null);

  // --> Dirty code start
  // Why is this code here? The component is re-rendering after a file is selected
  // Full Explanation in:
  // https://letschatty.atlassian.net/jira/software/projects/KANBAN/boards/1?selectedIssue=KANBAN-982
  useEffect(() => {
    const bodyRef: any = document.getElementById("bodyRef");

    if (bodyRef && typeof bodyRef.lastScrollTop !== 'undefined') {
      bodyRef.scrollTop = bodyRef.lastScrollTop;
      bodyRef.lastScrollTop = undefined;
    }
  }, [selectedFiles]);
  // --> Dirty code ends

  const handleContextMenu = (event: React.MouseEvent, file: FileMultimedia) => {
    event.preventDefault();

    setClickedFile(file);
    setIsContextMenuOpen(true);
    setContextMenuPosition({ x: event.pageX, y: event.pageY });
  };

  const getAllFiles = async () => {
    setLoading(true);
    try {
      const res = await getFiles(agent.company);
      setFiles(res.data);
      if (fileUploaded) {
        handleFileClick(fileUploaded as FileMultimedia);
      }
    } catch {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        contextMenuRef.current &&
        !contextMenuRef.current.contains(event.target as Node)
      ) {
        setIsContextMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (!uploadFileScreen) {
      handleRemoveFile();
      getAllFiles();
    }
  }, [uploadFileScreen]);

  const handleFileClick = (file: FileMultimedia) => {
    // --> Dirty code start
    // Why is this code here? The component is re-rendering after a file is selected
    // Full Explanation in:
    // https://letschatty.atlassian.net/jira/software/projects/KANBAN/boards/1?selectedIssue=KANBAN-982
    const bodyRef: any = document.getElementById("bodyRef");

    if (bodyRef) {
      bodyRef.lastScrollTop = bodyRef.scrollTop;
    }
    // Dirty code ends <---

    setSelectedFiles([file]);
    // setFileUploaded(null);
  };

  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (
        event.key === "Escape" ||
        event.key === "Esc" ||
        event.code === "Escape"
      ) {
        onClose(true);
      }
    };

    document.addEventListener("keydown", handleEsc);

    return () => {
      document.removeEventListener("keydown", handleEsc);
    };
  }, [onClose]);

  const handleUseFiles = () => {
    toast.success("Archivos importados");
    onClose(false);
    importedFilesSelected(selectedFiles);
    setSelectedFiles([]);
  };

  const handleFileUpload = async () => {
    console.log("selectedFile: ", selectedFile);
    if (!selectedFile) {
      console.log("No hay archivo seleccionado");
      return;
    }
    setLoading(true);
    try {
      const result = await uploadFile(selectedFile, agent.company);
      toast.success("Subido exitosamente");
      setSelectedFiles([result]);

      setUploadFileScreen(false);
    } catch (error) {
      toast.error("Error al subir el archivo");
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //@ts-ignore
    const file = e.target.files[0];
    setSelectedFile(file);

    if (file) {
      const previewUrl = URL.createObjectURL(file);
      setPreview(previewUrl);
    }
  };

  useEffect(() => {
    return () => {
      if (preview) {
        URL.revokeObjectURL(preview);
      }
    };
  }, [preview]);

  const formatFileSize = (size: number) => {
    if (size < 1024) return `${size} bytes`;
    if (size < 1024 * 1024) return `${(size / 1024).toFixed(2)} KB`;
    return `${(size / (1024 * 1024)).toFixed(2)} MB`;
  };

  const handleRemoveFile = () => {
    //@ts-ignore
    setSelectedFile(null);
    setPreview(null);
  };

  const handleDelete = async (file: FileMultimedia) => {
    try {
      const res = await deleteFile(agent.company, file.key);
      toast.warning(`${file.name} ha sido eliminado`);
      setIsContextMenuOpen(false);
      getAllFiles();
    } catch {}
  };

  const handleCopyLink = () => {
    if (clickedFile) {
      const filePath = clickedFile.url;
      navigator.clipboard
        .writeText(filePath)
        .then(() => {
          toast.info(`Link copiado: ${clickedFile.name}`);
          setIsContextMenuOpen(false);
        })
        .catch((error) => {
          console.error("Error al copiar el link:", error);
          toast.error("No se pudo copiar el link");
        });
    }
  };

  return (
    <>
      <div className="modal-container">
        <Modal
          size={"4xl"}
          popup={true}
          show={true}
          onClose={() => onClose(false)}
        >
          <Modal.Header
            className="[&_h3]:text-white [&_h3]:pl-2 [&_h3]:pt-2"
            onClick={(event) => event.stopPropagation()}
          >
            Multimedia
          </Modal.Header>
          <Modal.Body
            id="bodyRef"
            className="body-modal !pt-2"
            onClick={(event) => event.stopPropagation()}
          >
            <div className="min-h-[50vh] gap-4 flex relative">
              <div className="w-1/3 relative flex flex-col">
                <CiSearch className="text-[#43f8e8] text-xl absolute left-3 top-3" />
                <input
                  type="text"
                  placeholder="Buscador de archivos"
                  className="py-2 w-full px-3 !outline-none rounded pl-9 border-[#2c3551] border text-[#43f8e8] bg-[#1a2238]"
                />
                <div className="mt-3 flex flex-col gap-2 pt-3 border-t border-[#2c3551]">
                  <button className="w-full rounded-lg py-3 px-4 text-left bg-[#2c3551] text-[#43f8e8] border-[#43f8e8] text-lg">
                    Todos los archivos
                  </button>
                  <button className="w-full rounded-lg py-3 px-4 text-left  text-[#414553] text-lg border border-[#414553]">
                    Otra carpeta
                  </button>
                  <button className="w-full rounded-lg py-3 px-4 text-left  text-[#414553] text-lg border border-[#414553]">
                    Otra carpeta
                  </button>
                </div>
              </div>
              <div className="w-2/3 pl-6 px-4 border-l border-[#2c3551]">
                {uploadFileScreen ? (
                  <>
                    <h2 className="text-white mb-5 text-left">
                      Subir archivos
                    </h2>
                    <div className="flex justify-between">
                      {loading && (
                        <div className="w-full flex justify-center items-center">
                          <Spinner hidden={false} size="xl" />
                        </div>
                      )}
                      {preview && !loading && (
                        <div className="flex gap-3">
                          <div className="rounded-lg p-4 border border-[#43f8e8] bg-[#2c3551] w-2/4">
                            <img
                              src={preview}
                              alt="Vista previa"
                              className="mt-2 w-52 h-52 object-cover rounded overflow-hidden"
                            />
                          </div>
                          <div className="w-2/4 flex flex-col gap-3 py-2 justify-between">
                            <h4 className="text-white">
                              Información del archivo:
                            </h4>
                            <ul className="text-white flex gap-2 flex-col">
                              <li>
                                <strong>Nombre:</strong> {selectedFile?.name}
                              </li>
                              <li>
                                <strong>Tamaño:</strong>{" "}
                                {formatFileSize(selectedFile?.size as number)}
                              </li>
                            </ul>
                            <button
                              onClick={handleRemoveFile}
                              className="bg-[#20cab4] rounded text-white px-6 py-2 text-sm mt-5"
                            >
                              Seleccionar otro archivo
                            </button>
                          </div>
                        </div>
                      )}
                      {!preview && !loading && (
                        <div className="rounded-lg p-4 border border-[#2c3551] flex justify-center items-center flex-col gap-4">
                          <div>
                            <FiUpload size={60} />
                          </div>
                          <input
                            type="file"
                            onChange={(e) => handleFileChange(e)}
                          />
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <h2 className="text-white mb-5 text-left">
                      Todos los archivos
                    </h2>
                    {loading ? (
                      <div className="w-full h-full flex justify-center items-center">
                        <Spinner hidden={false} size="xl" />
                      </div>
                    ) : (
                      <div className="grid grid-cols-3 gap-5">
                        {files.length === 0 && (
                          <span>Aun no hay archivos agregados.</span>
                        )}
                        {files.map((file, i) => (
                          <div
                            key={`${file.name}-${i}`}
                            onClick={() => handleFileClick(file)}
                            onContextMenu={(event) =>
                              handleContextMenu(event, file)
                            }
                            className={`rounded-lg p-4 border flex flex-col gap-2 cursor-pointer justify-between ${
                              selectedFiles.some(
                                (selectedFile) => selectedFile.key === file.key
                              )
                                ? "border-[#43f8e8] bg-[#2c3551]"
                                : "border-[#2c3551] hover:border-[#43f8e8]"
                            }`}
                          >
                            {renderFilePreview(file)}

                            <p className="text-[#acb8c0] text-sm text-left w-full overflow-hidden text-ellipsis whitespace-nowrap">
                              {file.name}
                            </p>
                          </div>
                        ))}
                      </div>
                    )}
                  </>
                )}
                <div
                  className={classNames(
                    "w-full flex justify-end right-0 py-3 bg-[#212944]",
                    { "sticky -bottom-4": !uploadFileScreen },
                    { "absolute bottom-0": uploadFileScreen }
                  )}
                >
                  {!loading && (
                    <>
                      {uploadFileScreen ? (
                        <>
                          <button
                            className="bg-[#20cab4] rounded text-white px-6 py-2 text-sm mr-2"
                            onClick={() => setUploadFileScreen(false)}
                          >
                            Volver a listado
                          </button>
                          <button
                            className={`bg-[#20cab4] rounded text-white px-6 py-2 text-sm mr-2 ${
                              !selectedFile &&
                              "pointer-events-none !bg-slate-400"
                            }`}
                            onClick={handleFileUpload}
                          >
                            Confirmar
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            className="bg-[#20cab4] rounded text-white px-6 py-2 text-sm mr-2"
                            onClick={() => setUploadFileScreen(true)}
                          >
                            Subir nuevo
                          </button>
                          <button
                            className={`bg-[#20cab4] rounded text-white px-6 py-2 text-sm mr-2 ${
                              selectedFiles.length === 0 &&
                              "pointer-events-none !bg-slate-400"
                            }`}
                            onClick={handleUseFiles}
                          >
                            Utilizar Archivos
                          </button>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>

            {/* Menú contextual */}
            {isContextMenuOpen && (
              <div
                ref={contextMenuRef}
                className="dropdown-menu fixed bg-[#1a2238] border border-[#2c3551] rounded-lg overflow-hidden shadow-xl"
                style={{
                  top: contextMenuPosition.y,
                  left: contextMenuPosition.x,
                  zIndex: 1000,
                }}
              >
                <button
                  onClick={handleCopyLink}
                  className="flex gap-2 w-full p-4 py-2 hover:bg-[#2c3551]"
                >
                  <FiPaperclip />
                  <span className="text-xs">Copy Link</span>
                </button>
                <button
                  onClick={() => handleDelete(clickedFile as FileMultimedia)}
                  className="flex gap-2 w-full p-4 py-2 hover:bg-[#2c3551]"
                >
                  <FiTrash2 />
                  <span className="text-xs">Eliminar</span>
                </button>
              </div>
            )}
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default ModalMultimedia;
