import { useContext, useEffect, useRef, useState } from "react";
import { Workflow } from "../../interfaces/workflow.interface";
import userContext from "../../utils/userContext";
import { context } from "../../interfaces";
import { createWorkflow } from "../../services/workflows.service";
import { Switch } from "@mui/material";
import FullScreenLoader from "../FullScreenLoader";
import { MdOutlineRoute } from "react-icons/md";

interface NewWorkflowProps {
  onClose: () => void;
  onWorkflowCreated: (workflow: Workflow) => void;
}

export const NewWorkflow: React.FC<NewWorkflowProps> = ({
  onClose,
  onWorkflowCreated,
}) => {
  const { agent } = useContext(userContext) as context;

  const editForm = useRef<HTMLFormElement>({} as HTMLFormElement);
  const visibleCheckboxRef = useRef<HTMLInputElement>({} as HTMLInputElement);

  const [showLoader, setShowLoader] = useState(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    setShowLoader(true);
    event.preventDefault();
    const formData = new FormData(editForm.current);
    const title = formData.get("title") as string;
    const description = formData.get("description") as string;
    const visible = visibleCheckboxRef.current?.checked || false;

    const newWorkflow: Workflow = {
      companyId: agent.company!,
      title: title,
      description: description,
      visible: visible,
      conditions: [],
      actions: [],
      alternative_actions: [],
      executed_step: 0,
      version: 1,
      repeat: {
        count: 0,
        delay_sec: 0,
        till_done: false,
      },
    };

    createWorkflow(newWorkflow)
      .then((res) => {
        onWorkflowCreated(res.data);
        onClose();
      })
      .finally(() => setShowLoader(false));
  };

  return (
    <div className="bg-darkBackground text-white h-screen items-center pt-5 px-10 overflow-clip">
      {showLoader && <FullScreenLoader />}
      <div className="header flex flex-row justify-between items-start px-6 ">
        <div className="w-full">
          <form ref={editForm} onSubmit={handleSubmit}>
            <div className="">
              <div className="flex flex-row items-center gap-4 mb-12">
                <MdOutlineRoute size={80} />
                <p className="text-3xl">Nuevo workflow</p>
              </div>
              <div className="flex flex-row w-full gap-4 pr-6 items-center mb-4">
                <input
                  type="text"
                  placeholder="Nombre"
                  name="title"
                  className="input input-bordered w-full bg-mainBackground"
                />
                <div className="flex flex-row gap-2 items-center">
                  Visible
                  <Switch
                    name="visible"
                    inputRef={visibleCheckboxRef}
                    color="primary"
                  />
                </div>
              </div>
            </div>
            <textarea
              placeholder="Descripción"
              name="description"
              className="resize-none textarea textarea-bordered textarea-md w-full !h-24 bg-mainBackground"
            ></textarea>
            <div className="flex flex-row gap-2 justify-start mt-4">
              <button
                type="submit"
                className="btn bg-principalColor hover:bg-cyan-500 text-white "
              >
                Crear
              </button>
              <button
                onClick={onClose}
                className="btn"
                style={{ background: "#ff5757", color: "white" }}
              >
                Cancelar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
