import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { MdOutlineRoute } from "react-icons/md";
import userContext from "../../utils/userContext";

export default function SearchAutocomplete({
  disabled = false,
  placeholder,
  data = [],
  onSelect,
  type = "tag",
  autoOpen = true,
}) {
  const { context, agent, clientUser } = React.useContext(userContext);
  const ref = React.useRef(null);
  const [dataToDisplay, setDataToDisplay] = React.useState([]);

  React.useEffect(() => {
    const input = document.getElementById("search-with-select");
    if (input && autoOpen) {
      input.focus();
    }
  }, []);

  return (
    <Autocomplete
      ref={ref}
      id="search-with-select"
      sx={{ width: "23.5vw" }}
      className={`${type !== "workflow" ? "input-modified" : ""}`}
      options={data}
      autoHighlight
      openOnFocus
      disabled={disabled}
      onChange={(event, newValue) => onSelect(newValue)}
      getOptionLabel={(option) => (option.title ? option.title : option.name)}
      renderOption={(props, option) => {
        const { key, ...optionProps } = props;
        return (
          <Box
            key={option.key}
            component="li"
            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
            {...optionProps}
          >
            {type === "workflow" && option.title ? (
              <>
                <MdOutlineRoute
                  style={{ fontSize: 24, marginRight: "10px", width: "10%" }}
                />
                <div className="flex flex-col w-[90%] overflow-hidden">
                  <p className="whitespace-nowrap text-ellipsis overflow-hidden">
                    {option.title}
                  </p>
                  {option?.description && (
                    <span className="whitespace-nowrap text-ellipsis text-xs text-[#aab6be] ">
                      {option?.description}
                    </span>
                  )}
                </div>
              </>
            ) : (
              <p className="whitespace-nowrap text-ellipsis overflow-hidden">
                {option.name}
              </p>
            )}
          </Box>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={placeholder}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password",
          }}
        />
      )}
    />
  );
}
