import {Dispatch, SetStateAction, useContext, useEffect, useState} from 'react';
import { DataGrid,GridRowId, GridRowSelectionModel,  GridToolbar } from '@mui/x-data-grid';
import TabsConfig from '../TabsConfig/TabsConfig';
import { TABELS } from '../../models/table'
import CircularProgress from '@mui/material/CircularProgress';
import ActionsConfig from '../ActionsConfig/ActionsConfig';
import { useParams } from "react-router-dom";
import { downloadCampaing, pauseCampaing, resumeCampaing } from '../../services/Plantillas.config';
import userContext from '../../utils/userContext';
import { toast, ToastContainer } from "react-toastify";

interface Table { 
  content: string 
  id: string
  name: string
  split_content: string[]
}

type Props = {
  data : Table[]
  loading: boolean
  tableGetData: () => Promise<void>
  isTemplates?: boolean
}
const TableConfig: React.FC<Props> = ({data, loading, tableGetData, isTemplates = false}) => {
	const params = useParams()
	const { agent } = useContext<any>(userContext);

  const [columnsNames, setColumnsNames] = useState<any[]>([])
  const [selectedRow, setSelectedRow] = useState<Table>();
  const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);

  useEffect(()=> {
    if(data.length) {
      const columnObj = Object.getOwnPropertyNames(data[0]).map((item) => {
        return(
          { field: item, headerName: item.toUpperCase().replace('_', ' '), minWidth: 200, width: 400 }
        )
      })
      setColumnsNames(columnObj)
    }
  }, [data])


  const onRowsSelectionHandler = (ids:string[]) => {
      const selectionSet = new Set(selectionModel);
      const result = ids.filter((s) => !selectionSet.has(s));
      const selected = data.filter((row) => result[0] == row.id)
      
      console.log('result', result)
      console.log('selected', selected)
      setSelectionModel(result);
      setSelectedRow(selected[0])
  };

  const handleDownloadCampaing = async () => {
    try {
      //@ts-ignore
      const response = await downloadCampaing(agent.company, selectedRow.id);
      console.log('response: ', response)

      const blob = new Blob([response], { type: 'text/csv' });
      console.log('blob: ', blob)

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      //@ts-ignore
      a.download = `campaing_${selectedRow.id}.csv`;
      document.body.appendChild(a);
      a.click();

      a.remove();
      window.URL.revokeObjectURL(url);
  
      toast.success("Descarga exitosa", {
        autoClose: 1500,
      });
      tableGetData()
    } catch (e: any) {
      toast.error(e.response.data.message, {
        autoClose: 2000,
      });
    }
  };
  

  console.log( )

  const handlePauseCampaing = async () => {
    try {
      //@ts-ignore
      const data = await pauseCampaing(agent.company, selectedRow.id)
      toast.success(data.message, {
				autoClose: 1500,
			})
      tableGetData()
    } catch(e:any) {
      toast.error(e.response.data.message, {
				autoClose: 2000,
			})
    }
  }

  const handleResumeCampaing = async () => {
    try {
      //@ts-ignore
      const data = await resumeCampaing(agent.company, selectedRow.id)
      toast.success(data.message, {
				autoClose: 1500,
			})
      tableGetData()
    } catch(e:any) {
      toast.error(e.response.data.message, {
				autoClose: 2000,
			})
    }
  }

    return (
      <>
      <div style={{ height: 400, width: '100%' }}>
        {!isTemplates && <TabsConfig data={TABELS}/>}
        {!isTemplates && <ActionsConfig tableSelected={params.table || ''} selectedRow={selectedRow} tableGetData={tableGetData}/>}
        {isTemplates && (
          <div className="flex gap-3 mb-5 mt-3">
            <button 
              onClick={handleDownloadCampaing} 
              disabled={!selectedRow} 
              className="px-5 py-3 text-white rounded-sm bg-[#576080]">
                Descargar campaña
            </button>
            <button 
              onClick={handlePauseCampaing} 
              //@ts-ignore
              disabled={selectedRow?.status === 'PENDING' || selectedRow?.status === 'PROCESSING' ? false : true} 
              className="px-5 py-3 text-white rounded-sm bg-[#f95757]">
                Pausar campaña
            </button>
            <button 
              onClick={handleResumeCampaing} 
              //@ts-ignore
              disabled={selectedRow?.status === 'PAUSED' || selectedRow?.status === 'INCOMPLETE' ? false : true} 
              className="px-5 py-3 text-white rounded-sm bg-[#35cab4]">
                Continuar campaña
            </button>
          </div>
        )}
        {!loading ? (
          <DataGrid
            rows={data}
            columns={columnsNames}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 50 },
              }
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
            pageSizeOptions={[5, 10]}
            checkboxSelection
            rowSelectionModel={selectionModel}
            //@ts-ignore
            onRowSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
          />
        ) : (
          <div className='loading-table'>
            <CircularProgress color="success" />
          </div>
        )
      }
      </div>
    </>
    );
  }

export default TableConfig