import { axiosConfig } from "./axios.config";

const getTable = async (table, company) => {
  const res = await axiosConfig.get(
    `${process.env.REACT_APP_API_NEW_URL}/configuration/${company}/table_${table}`
  );
  return res.data;
};

const getSourcesTable = async (company) => {
  const res = await axiosConfig.get(
    `${process.env.REACT_APP_API_V3}/sources/${company}`
  );
  return res.data;
};

const getResponseTable = async (company) => {
  const res = await axiosConfig.get(
    `${process.env.REACT_APP_API_V3}.1/company_assets/${company}/fast_answers`
  );
  return res.data;
};

const getSmartMessagesTable = async (company) => {
  const res = await axiosConfig.get(
    `${process.env.REACT_APP_API_V3}/topics/${company}/topics`
  );
  return res.data;
};

const actionTable = async (type, body, table, id, company) => {
  const url = `${process.env.REACT_APP_API_NEW_URL}/configuration/${company}/table_${table}`;

  if (type === "edit") {
    const res = await axiosConfig.put(`${url}/${id}`, body);
    return res.data;
  } else if (type === "add") {
    const res = await axiosConfig.post(url, body);
    return res.data;
  } else {
    const res = await axiosConfig.delete(`${url}/${id}`, body);
    return res.data;
  }
};

export {
  getTable,
  actionTable,
  getSourcesTable,
  getSmartMessagesTable,
  getResponseTable,
};
