import { Badge, Checkbox } from "flowbite-react";
import { ConversationProps } from "../interfaces";
import moment from "moment-timezone";
import React, { useContext, useEffect, useRef } from "react";
import Tag from "../interfaces/tag.interface";
import { useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import { AiOutlineStar, AiFillStar } from "react-icons/ai";
import {
  BsStopwatchFill,
  BsCheck2All,
  BsChevronDown,
  BsPersonDash,
} from "react-icons/bs";
import { BiTransfer } from "react-icons/bi";
import { FaCheckSquare } from "react-icons/fa";
import {
  MdOutlineMarkChatUnread,
  MdOutlineMarkChatRead,
  MdBlock,
} from "react-icons/md";
import { act_on_chats } from "../services/ChatsActions.service";
import userContext from "../utils/userContext";
import { toast, ToastContainer } from "react-toastify";
import { FiArchive } from "react-icons/fi";
import { agents } from "../services/Agents.services";

export const Conversation: React.FC<ConversationProps> = ({
  name,
  lastMessageTimestamp,
  lastMessage,
  photo,
  phoneNumber,
  conversation_id,
  checked,
  setChecked,
  checkConversation,
  isChatSeen,
  agentFullName,
  agentAssigned,
  tags,
  time_left,
  isSelectChatOpen,
  filter,
  starred,
  handleRefetch,
  setUser,
  isBroaderPreview = false,
}) => {
  const { agent, clientUser, context, setContext, setClientUser } = useContext(
    userContext
  ) as any;
  const [openSlide, setopenSlide] = useState<boolean>(false);
  const [agentDropdown, setAgentDropdown] = useState<boolean>(false);
  const [agentsList, setAgentsList] = useState([]);
  const dropdown = useRef<HTMLDivElement>(null);
  const dropdownAgent = useRef<HTMLDivElement>(null);

  const handleCheck = (event: any) => {
    let updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);
  };

  const closeOpenMenus = (e: any) => {
    if (dropdown.current && openSlide && !dropdown.current.contains(e.target)) {
      setopenSlide(false);
      setAgentDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", closeOpenMenus);
    return () => {
      document.removeEventListener("mousedown", closeOpenMenus);
    };
  });

  const getAgents = async () => {
    try {
      const data = await agents(agent.company);
      setAgentsList(data);
      setAgentDropdown(true);
    } catch (e) {
      console.log("error en agents");
    }
  };

  const containsChineseCharacters = (texto: string) => {
    const regex =
      /[\p{Script=Hani}\p{Script=Hiragana}\p{Script=Katakana}\p{Script=Han}\p{Script=Hangul}]+/gu;
    return texto.replace(regex, "");
  };

  const formatTime = (date: Date) => {
    const dateDay = moment(date).calendar();
    if (dateDay.includes("hoy")) {
      return moment(date).format("LT");
    } else {
      return moment(date).format("ll").slice(0, -8);
    }
  };

  const actionChat = async (
    action: string,
    autoAssign: boolean = false,
    agentTransfer: string = "",
    isUndo: boolean = false
  ) => {
    const conversationIdArray = [conversation_id];
    const parameter = determineParameter(autoAssign, agentTransfer);

    try {
      const data = await actOnChats(
        action,
        conversationIdArray,
        parameter,
        isUndo
      );
      showToastWithUndoButton(data, action, autoAssign, agentTransfer);
      handlePostAction(action);
    } finally {
      handleRefetch();
    }
  };

  const determineParameter = (autoAssign: boolean, agentTransfer: string) => {
    if (agentTransfer) {
      setAgentDropdown(false);
      return agentTransfer;
    } else if (autoAssign) {
      return agent.email;
    }
    return "";
  };

  const actOnChats = (
    action: string,
    conversationIdArray: string[],
    parameter: string,
    isUndo: boolean = false
  ) => {
    const contextValue =
      context === "admin" ? "chats_manager" : "agent_workspace";
    return act_on_chats(
      agent.id,
      agent.company,
      !isUndo ? contextValue : "chats_manager",
      action,
      conversationIdArray,
      parameter
    );
  };

  const showToastWithUndoButton = (
    data: any,
    action: string,
    autoAssign: boolean,
    agentTransfer: string
  ) => {
    const undoAction = getUndoAction(action, autoAssign, agentTransfer);
    toast.success(
      <div className="flex flex-col">
        {data}
        {autoAssign && (
          <button
            className="mt-2 bg-[#0daa96] px-2 py-1 text-white"
            onClick={() => goToChat()}
          >
            Ir al chat
          </button>
        )}
        {undoAction}
      </div>,
      {
        autoClose: 2000,
      }
    );
  };

  const getUndoAction = (
    action: string,
    autoAssign: boolean,
    agentTransfer: string
  ) => {
    if (context === "agent") {
      return (
        <button
          className="mt-2 bg-[#0daa96] px-2 py-1 text-white"
          onClick={() => actionChat("transfer_chats_to_agent", true, "", true)}
        >
          Deshacer
        </button>
      );
    } else {
      const area = localStorage.getItem("currentArea");

      if (area === "WAITING AGENT") {
        switch (action) {
          case "archive_chats":
            return (
              <button
                className="mt-2 bg-[#0daa96] px-2 py-1 text-white"
                onClick={() =>
                  actionChat("unarchive_chats", autoAssign, agentTransfer)
                }
              >
                Deshacer
              </button>
            );
          case "transfer_chats_to_agent":
            return (
              <button
                className="mt-2 bg-[#0daa96] px-2 py-1 text-white"
                onClick={() => actionChat("dessasign_agent_from_chats")}
              >
                Deshacer
              </button>
            );
          case "block_chats":
            return (
              <button
                className="mt-2 bg-[#0daa96] px-2 py-1 text-white"
                onClick={() => actionChat("unblock_chats", true)}
              >
                Deshacer
              </button>
            );
          default:
            return null;
        }
      } else if (area === "ARCHIVED") {
        if (
          action === "unarchive_chats" ||
          action === "transfer_chats_to_agent" ||
          action === "block_chats"
        ) {
          return (
            <button
              className="mt-2 bg-[#0daa96] px-2 py-1 text-white"
              onClick={() => actionChat("archive_chats")}
            >
              Deshacer
            </button>
          );
        }
      }
    }
  };

  const handlePostAction = (action: string) => {
    const actionsThatResetClientUser = [
      "mark_chats_as_unread",
      "archive_chats",
      "dessasign_agent_from_chats",
      "block_chats",
      "transfer_chats_to_agent",
    ];

    if (actionsThatResetClientUser.includes(action)) {
      setClientUser({});
    } else {
      handleRefetch();
    }
  };

  const goToChat = () => {
    setContext("agent");
    window.localStorage.setItem("context", "agent");
    setTimeout(() => {
      handleRefetch();
    }, 1000);
  };

  return (
    <div className={`flex flex-col w-full conversation-container`}>
      <div className="relative">
        <div className={`flex w-full pl-2 pr-2`}>
          {!isBroaderPreview && (
            <Checkbox
              className={`cursor-pointer self-center mr-1.5 checkbox ${
                checked.includes(conversation_id) ? "opacity-70" : "opacity-0"
              }`}
              id="conversation"
              onChange={handleCheck}
              onClick={(event) => event.stopPropagation()}
              value={conversation_id}
              checked={checked.includes(conversation_id)}
            />
          )}
          <div className="flex w-9/12 items-center">
            <div className="w-full font-medium flex flex-col justify-center">
              {name.length ? (
                <p
                  className={`truncate ${
                    isChatSeen
                      ? "text-[#ACB8C0]"
                      : "text-[#f1edec] font-semibold"
                  }`}
                >{`${containsChineseCharacters(name)}`}</p>
              ) : (
                <p
                  className={`truncate ${
                    isChatSeen
                      ? "text-[#ACB8C0]"
                      : "text-[#f1edec] font-semibold"
                  }`}
                >
                  {phoneNumber}
                </p>
              )}
              <div className="flex">
                <BsCheck2All
                  className="w-3/12 mr-1"
                  style={{ fontSize: 24, color: "white", width: 20 }}
                />
                <Tooltip title={lastMessage} placement="bottom-end">
                  <p
                    className={`text-sm dark:text-gray-500 ${
                      isChatSeen
                        ? "text-[#ACB8C0]"
                        : "text-[#20CAB4] font-medium"
                    } w-full truncate text-left`}
                  >
                    {lastMessage &&
                      lastMessage
                        .split(/(\*.*?\*|_.*?_|https?:\/\/\S+)/)
                        .map((part: any, index: any) => {
                          if (part.startsWith("*") && part.endsWith("*")) {
                            return (
                              <span key={index} className="font-bold">
                                {part.slice(1, -1)}
                              </span>
                            );
                          } else if (
                            part.startsWith("_") &&
                            part.endsWith("_")
                          ) {
                            return (
                              <span
                                key={index}
                                className={`italic ${
                                  isChatSeen
                                    ? "text-[#ACB8C0]"
                                    : "text-[#f1edec] font-semibold"
                                }`}
                              >
                                {part.slice(1, -1)}
                              </span>
                            );
                          } else if (/\bhttps?:\/\/\S+\b/.test(part)) {
                            return (
                              <a
                                key={index}
                                className="text-principalColor"
                                href={part}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {part}
                              </a>
                            );
                          } else {
                            return (
                              <span
                                className={`${
                                  isChatSeen
                                    ? "text-[#ACB8C0]"
                                    : "text-[#f1edec] font-semibold"
                                }`}
                                key={index}
                              >
                                {part}
                              </span>
                            );
                          }
                        })}
                  </p>
                </Tooltip>
              </div>
              <div className="flex w-full">
                <div className={"flex w-full mt-2 mb-2"}>
                  <Tooltip
                    //@ts-ignore
                    title={time_left.hover_description}
                    placement="bottom-end"
                  >
                    <div className="border-r border-[#ccc] flex items-center !text-[#212944]">
                      <BsStopwatchFill
                        style={{
                          fill: "#212944",
                          fontSize: 24,
                          background:
                            time_left.status == "yellow"
                              ? "#CFCA6D"
                              : time_left.status == "green"
                              ? "#08AA95"
                              : time_left.status == "grey"
                              ? "#CCCCCC"
                              : "#FF5757",
                        }}
                        className="!text-[#212944] font-[30px] p-1 bg-[#2baa95] rounded-full overflow-hidden mr-2"
                      />
                    </div>
                  </Tooltip>
                  {tags
                    // .filter((tag: Tag) => tag.is_active === 1)
                    .slice(0, window.screen.width <= 1366 ? 3 : 4)
                    .map((t: Tag) => (
                      <Tooltip title={t.name} placement="bottom-end">
                        <div>
                          <Badge
                            className="hover:bg-gray-500 mx-2 my-0 rounded-full w-6 h-6 text-center text-xs font-bold justify-center break-words"
                            style={{
                              color: "#acb8c0",
                              backgroundColor: "transparent",
                              border: `1px solid ${t.color}`,
                            }}
                            title={t.name}
                            key={t.id}
                          >
                            {t.name.slice(0, 2)}
                          </Badge>
                        </div>
                      </Tooltip>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-4/12 items-end">
            <div
              className={`${
                isChatSeen ? "text-[#ACB8C0]" : "text-[#35cab4]"
              } dark:text-gray-500 text-xs py-1 text-end whitespace-nowrap`}
            >
              {formatTime(lastMessageTimestamp)}
            </div>
            <div className="flex items-center mt-1">
              {!isBroaderPreview && (
                <>
                  {starred ? (
                    <div
                      className="p-[4px] rounded-full"
                      onClick={(e) => {
                        e.stopPropagation();
                        actionChat("mark_chats_as_unstarred");
                      }}
                    >
                      <AiFillStar style={{ color: "#cfca6d", fontSize: 19 }} />
                    </div>
                  ) : (
                    <div
                      className="p-[4px] rounded-full mr-2 opacity-0 star-handler group"
                      onClick={(e) => {
                        e.stopPropagation();
                        actionChat("mark_chats_as_starred");
                      }}
                    >
                      <AiOutlineStar
                        className="block group-hover:hidden"
                        style={{ color: "#cfca6d", fontSize: 19 }}
                      />
                      <AiFillStar
                        className="hidden group-hover:block"
                        style={{ color: "#cfca6d", fontSize: 19 }}
                      />
                    </div>
                  )}
                </>
              )}
              {!isChatSeen && (
                <div className="bg-[#35cab4] p-[4px] rounded-full">
                  <MdOutlineMarkChatUnread
                    style={{ color: "#212944", fontSize: 14 }}
                  />
                </div>
              )}
            </div>

            {filter == "WITH AGENT" && (
              <div className="flex items-center">
                <div
                  className={`flex text-center justify-end text-base mt-1 ml-2 ${
                    isChatSeen === 0 ? "text-white" : "text-principalColor"
                  }`}
                  title={agentFullName}
                >
                  {agentAssigned === null ? (
                    agentFullName.split(" ").map((name) => name.charAt(0))
                  ) : (
                    <>{agentAssigned && agentAssigned.slice(0, 5)}</>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        {!isBroaderPreview && (
          <>
            <button
              onClick={(e) => {
                e.stopPropagation();
                setopenSlide(!openSlide);
              }}
              className="absolute right-1 text-white top-[80%]"
              title="Acciones"
            >
              <BsChevronDown style={{ fontSize: 12, color: "white" }} />
            </button>
            {openSlide && (
              <div
                ref={dropdown}
                className="dropdownContainer !right-[-4%] !left-[auto] bg-[#121827]"
              >
                {context === "agent" ? (
                  <>
                    {isChatSeen ? (
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          actionChat("mark_chats_as_unread");
                        }}
                        className="flex gap-2 items-center hover:text-[#20cab4]"
                      >
                        <MdOutlineMarkChatUnread style={{ fontSize: 16 }} />
                        Marcar como no leido
                      </button>
                    ) : (
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          actionChat("mark_chats_as_read");
                        }}
                        className="flex gap-2 items-center hover:text-[#20cab4]"
                      >
                        <MdOutlineMarkChatRead style={{ fontSize: 16 }} />
                        Marcar como leido
                      </button>
                    )}

                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        actionChat("dessasign_agent_from_chats");
                      }}
                      className="flex gap-2 items-center hover:text-[#20cab4]"
                    >
                      <BsPersonDash style={{ fontSize: 16 }} />
                      Desasignarme chat
                    </button>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        getAgents();
                      }}
                      className="flex gap-2 items-center hover:text-[#20cab4]"
                    >
                      <BiTransfer style={{ fontSize: 16 }} />
                      Transferir a agente
                    </button>
                    {agentDropdown && (
                      <div
                        ref={dropdownAgent}
                        className="dropdownContainer !w-[100px] border-white border !min-w-[200px] left-[50px] top-[140px] bg-[#121827] max-h-[200px] overflow-y-auto overflow-x-hidden"
                      >
                        {agentsList.map((agent) => (
                          <button
                            className="hover:text-[#20cab4]"
                            onClick={() =>
                              actionChat(
                                "transfer_chats_to_agent",
                                false,
                                //@ts-ignore
                                agent.email
                              )
                            }
                          >
                            {/* @ts-ignore */}
                            {agent.nombre}
                          </button>
                        ))}
                      </div>
                    )}
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        actionChat("archive_chats");
                      }}
                      className="flex gap-2 items-center hover:text-[#20cab4]"
                    >
                      <FiArchive style={{ fontSize: 16 }} />
                      Archivar chat
                    </button>
                    {/* <button onClick={(e) => handleCheck(e)} className='flex gap-2 items-center hover:text-[#20cab4]'>
                      <FaCheckSquare style={{fontSize: 16}}/>
                      Seleccionar chat
                    </button> */}
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        actionChat("block_chats");
                      }}
                      className="flex gap-2 items-center hover:text-[#20cab4]"
                    >
                      <MdBlock style={{ fontSize: 16 }} />
                      Bloquear chat
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        actionChat("transfer_chats_to_agent", true, "");
                      }}
                      className="flex gap-2 items-center hover:text-[#20cab4]"
                    >
                      <BsPersonDash style={{ fontSize: 16 }} />
                      Asignarme chat
                    </button>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        getAgents();
                        setAgentDropdown(true);
                      }}
                      className="flex gap-2 items-center hover:text-[#20cab4]"
                    >
                      <BiTransfer style={{ fontSize: 16 }} />
                      Transferir a agente
                    </button>
                    {agentDropdown && (
                      <div
                        ref={dropdownAgent}
                        className="dropdownContainer !w-[100px] border-white border !min-w-[200px] left-[50px] top-[140px] bg-[#121827] "
                      >
                        {/* @ts-ignore */}
                        {agentsList.map((agent) => (
                          <button
                            className="hover:text-[#20cab4]"
                            onClick={() =>
                              actionChat(
                                "transfer_chats_to_agent",
                                false,
                                //@ts-ignore
                                agent.email
                              )
                            }
                          >
                            {/* @ts-ignore */}
                            {agent.nombre}
                          </button>
                        ))}
                      </div>
                    )}
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        actionChat("archive_chats");
                      }}
                      className="flex gap-2 items-center hover:text-[#20cab4]"
                    >
                      <FiArchive style={{ fontSize: 16 }} />
                      Archivar chat
                    </button>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        actionChat("block_chats");
                      }}
                      className="flex gap-2 items-center hover:text-[#20cab4]"
                    >
                      <MdBlock style={{ fontSize: 16 }} />
                      Bloquear chat
                    </button>
                  </>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
