import { Menu, TextareaAutosize, TextField, Tooltip } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { getSourcesCategories } from "../../services/Analytics.services";
import userContext from "../../utils/userContext";
import Checkbox from '@mui/material/Checkbox';
import SearchAutocomplete from "../SearchAutocomplete/SearchAutocomplete";
import { getAllTags } from "../../services/Preview.service";
import { Tag } from "../../interfaces";
import { toast } from "react-toastify";
import { getFlows, getProductsDropdown } from "../../services/Tabs.service";
import WorkflowItem from "../Workflows/WorkflowItem";
import { MdClose } from "react-icons/md";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { lightGreen } from '@mui/material/colors';
import { addPlantillaSingle, addPlantillaMultiple, getAgents, getAreas, getPlantillasForm, getCsvExample } from "../../services/Plantillas.config";
import { TiInfoLarge } from "react-icons/ti";


type FormPlantillas = {
	agent_email: string,
	name: string,
	type: string,
	area: string,
	description: string,
	tags: any[],
	products: any[],
	flow: any[],
	ad_id: string,
	trackeable: boolean,
	category: string,
	categorySelect: string,
	trigger: string,
	source_checker: string
}

type Props = {
	closeModal: (value: React.SetStateAction<boolean>) => void
	refetchTable?: () => Promise<void>
}
type RepeatType = {
	count: number,
	delay_sec: number,
	till_done: boolean
  }
interface WorkflowType {
	actions: string[]
	companyId: string,
	description: string,
	repeat: RepeatType,
	title: string,
	version: number,
	_id: string
  }
interface ProductType {
	id: string, 
	name: string
}
interface PlantillaType {
	id: string
	name: string
	text: string
}

const AddTemplate: React.FC<Props> = ({
    closeModal,
		refetchTable
}) => {	
	const [form, setForm] = useState<any>(
		{
			area: '',
			assign_to_agent: '',
			phone_number: '',
			new_contact_name: '',
			template_name: "",
			forced_send: false,
			parameters: [],
			tags: [],
			products: [],
			flow: [],
			description: "",
			campaign_name : ""
		}
	)
	const [areaOptions, setAreaOptions] = useState([])
	const [agentsOptions, setAgentsOptions] = useState([])
	const [categoriesOptions, setCategoriesOptions] = useState([])
	const [isAutomation, setIsAutomation] = useState(false)
	const [tags, setTags] = useState<Tag[]>([])
	const [products, setProducts] = useState<Tag[]>([])
	const [workflows, setWorkflows] = useState<WorkflowType[]>([])
	const [plantillas, setPlantillas] = useState<PlantillaType[]>([])
	const { agent } = useContext(userContext) as any;
  const [file, setFile] = useState(null);

	const [anchorElTag, setAnchorElTag] = React.useState<null | HTMLElement>(null);
	const openTag = Boolean(anchorElTag);
	const [anchorElProd, setAnchorElProd] = React.useState<null | HTMLElement>(null);
	const openProd = Boolean(anchorElProd);
	const [anchorElWorkflow, setAnchorElWorkflow] = React.useState<null | HTMLElement>(null);
	const openWorkflow = Boolean(anchorElWorkflow);
	const [selectedWorkflows, setSelectedWorkflows] = useState([])
	const [selectedPlantillas, setSelectedPlantillas] = useState<PlantillaType[]>([])
	const [radioValue, setRadioValue] = useState('single');
	const [selectedTags, setSelectedTags] = useState([])
	const [selectedProducts, setSelectedProducts] = useState([])
	
	const handleChange = (e:React.ChangeEvent<HTMLInputElement  | HTMLTextAreaElement | SelectChangeEvent>) => {
		//@ts-ignore
		setForm({...form, [e.target.name]: e.target.name === 'forced_send' ? e.target.checked : e.target.value})
	}

	const handleFileChange = (e: any) => {
    setFile(e.target.files[0]);
  };

	const addPlantilla = async(body:any, single:boolean) => {
		const process = toast.loading("Agregando Plantilla...")
		let response
		try {
			if(single) {
				response = await addPlantillaSingle(agent.company, body)
			} else {
				response = await addPlantillaMultiple(agent.company, body)
			}
			toast.update(process, 
				{
					render: response.message, 
					type: "success", 
					isLoading: false, 
					autoClose: 1000,
				}
			);
			setTimeout(() => {
				if(refetchTable) {
					refetchTable()
				}
				closeModal(false)
			}, 1500)
		}
		catch (e) {
			console.log('e: ', e)
			toast.update(process, 
				{
					//@ts-ignore
					render: e.response.data.message, 
					type: "error", 
					isLoading: false, 
					autoClose: 800,
				}
			);
		}
	}

	const getCategoriesSource = async() => {
		try {
			let response = await getSourcesCategories(agent.company)
			setCategoriesOptions(response)
		}
		catch {

		}
	}

	const getAreaDestino = async() => {
		try {
			let response = await getAreas(agent.company)
			setAreaOptions(response)
		}
		catch {
		}
	}

	const getAgenteDestino = async() => {
		try {
			let response = await getAgents(agent.company)
			console.log('response agente: ', response)

			setAgentsOptions(response)
		}
		catch {
		}
	}

	const sendForm = async () => {

		let formToSend = form

		formToSend.template_name = selectedPlantillas[0].name
		//@ts-ignore
		formToSend.products = selectedProducts.map((prod) => prod.id)
		//@ts-ignore
		formToSend.flow = selectedWorkflows.map((flow) => flow._id)
		//@ts-ignore
		formToSend.tags = selectedTags.map((tag) => tag.id)

		if(radioValue === 'single') {
			delete formToSend.campaign_name
			addPlantilla(formToSend, true)

		} else {

			delete formToSend.phone_number
			delete formToSend.new_contact_name

			if (!file) {
        toast("Please select a CSV file to upload.");
        return;
      }

      const formData = new FormData();
      formData.append("campaign_data", JSON.stringify(formToSend));
      formData.append("files", file);

			addPlantilla(formData, false)
		}
	}

	const getCsvPlaceholder = async () => {
		try {
			const response = await getCsvExample(agent.company)
			console.log('response: ', response, await getCsvExample(agent.company))

			//@ts-ignore
      const blob = new Blob([response], { type: 'text/csv' });
			console.log('blob: ', blob)
			const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      //@ts-ignore
      a.download = `example.csv`;
			document.body.appendChild(a);
      a.click();

      a.remove();
      window.URL.revokeObjectURL(url);
			toast.success("Descarga exitosa", {
        autoClose: 1500,
      });
		}
		catch (e) {
			console.log('Error fetching workflows:', e);
		}
	}

	const getAllFlows = async () => {
		try {
			const data = await getFlows(agent.company);
			setWorkflows(data);
		} catch (e) {
			console.log('Error fetching workflows:', e);
		}
	};

	const getAllPlantillas = async () => {
		try {
			const data = await getPlantillasForm(agent.company)
			console.log('data: ', data)
			setPlantillas(data)
		} catch(e) {
			console.log('Error fetching plantillas', e)
		}
	}

	
	useEffect(() => {
		getAllFlows()
		getAllPlantillas()
		getAllTags(agent.company).then((data) => {
			setTags(data);
		});
		getProductsDropdown(agent.company).then((data) => {
			setProducts(data)
		});
		getCategoriesSource()
		getAreaDestino()
		getAgenteDestino()
		setForm({...form, agent_email : agent.email})
	}, [])

	const removeProduct = (id: string) => {
		setSelectedProducts(selectedProducts.filter((prod: ProductType) => prod.id !== id));
	};

	const removeTag = (id: string) => {
		setSelectedTags(selectedTags.filter((tag: Tag) => tag.id !== id));
	};

	const onProductSelect = (product:any) => {
		//@ts-ignore
		if (!selectedProducts.some((p: any) => p.id === product.id)) {
			//@ts-ignore
			setSelectedProducts([...selectedProducts, product]);
			handleCloseProd()
		} else {
			toast.error('El producto ya está en la lista.', {
				autoClose: 2000,
			})
		}
	}

	const onTagSelect = (tag:any) => {
		//@ts-ignore
		if (!selectedTags.some((p: any) => p.id === tag.id)) {
			//@ts-ignore
			setSelectedTags([...selectedTags, tag])
			handleCloseTag()
		} else {
			toast.error('El tag ya está en la lista.', {
				autoClose: 2000,
			})
		}
	}

	const onWorkflowSelect = (workflowItem:any) => {
		//@ts-ignore
		if (!selectedWorkflows.some((p: any) => p._id === workflowItem._id)) {
			//@ts-ignore
			setSelectedWorkflows([...selectedWorkflows, workflowItem])
			handleCloseWorkflow()
		} else {
			toast.error('El workflow ya está en la lista.', {
				autoClose: 2000,
			})
		}
	}

	const onPlantillasSelect = (plantillaItem:any) => {
		//@ts-ignore
		if (!selectedPlantillas.some((p: any) => p.id === plantillaItem.id)) {
			// @ts-ignore
			setSelectedPlantillas([plantillaItem])
			handleCloseWorkflow()
		} else {
			toast.error('La plantilla ya está en la lista.', {
				autoClose: 2000,
			})
		}
	}

	const handleClickTag = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorElTag(event.currentTarget);
	};
	const handleCloseTag = () => {
		setAnchorElTag(null);
	};

	const handleCloseWorkflow = () => {
		setAnchorElWorkflow(null);
	};
	const handleClickWorkflow = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorElWorkflow(event.currentTarget);
	};

	const handleClickProd = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorElProd(event.currentTarget);
	};
	const handleCloseProd = () => {
		setAnchorElProd(null);
	};

	const removeWorkflow = (id: string) => {
		setSelectedWorkflows(selectedWorkflows.filter((workflow: WorkflowType) => workflow._id !== id));
	};

  const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadioValue((event.target as HTMLInputElement).value);
  };


	return(
		<div className="flex gap-5 border-t border-[#1a2238]">
			<div className="border-r pr-5 pt-5 border-[#1a2238] min-w-[150px]"> 
				<button>
					<h2 
						className={`text-left ${isAutomation ? ' text-white' :' text-[#20cab4]'} mb-5 w-full`}
						onClick={() => setIsAutomation(false)}
					>Plantillas</h2>
				</button>
				<button>
					<h2 
						className={`text-left ${!isAutomation ? ' text-white' :' text-[#20cab4]'} mb-5 w-full`}
						onClick={() => setIsAutomation(true)}
					>Automatización</h2>
				</button>
			</div>
			<div className="py-5 min-w-[450px] pr-5">
				{!isAutomation ? (
					<>
						<h2 className="text-left text-white mb-5">Destinatario</h2>
								<RadioGroup
									value={radioValue}
									onChange={handleChangeRadio}
									className="!flex justify-between mb-3"
								>
									<FormControlLabel 
										value="single" 
										control={<Radio sx={{
										color: lightGreen[800],
											'&.Mui-checked': {
													color: lightGreen[600],
												},
											}}/>} 
									label="Unico destinatario" />
										<FormControlLabel value="multiple" control={<Radio sx={{
											color: lightGreen[800],
											'&.Mui-checked': {
												color: lightGreen[600],
											},
										}}/>} 
									label="Multiples destinatarios" />
								</RadioGroup>
						<div className="flex flex-col">
							{radioValue === 'single' ? (
								<>
									<label className="text-[#a3aeb8] mb-3 text-left">Numero de telefono</label>
									<TextField
										id="phone_number"
										name='phone_number'
										onChange={(e) => handleChange(e)}
										value={form.phone_number}
										className='mb-5 input-source'
										InputLabelProps={{ shrink: true }}
									/>
								</>
							) : (
								<>
								<div>
									<label>Upload CSV File (only for multiple campaigns):</label>
									<input type="file" onChange={(e) => handleFileChange(e)} accept=".csv" />
									<div className="border-b border-gray-500 mt-2 mb-3 pb-3 text-left">
										<label className="text-sm underline pl-6" onClick={() => getCsvPlaceholder()}>Descargar ejemplo</label>	
									</div>
								</div>
								<label className="text-[#a3aeb8] mb-3 text-left">Nombre de la campaña de plantillas</label>
									<TextField
										id="campaign_name"
										name='campaign_name'
										onChange={(e) => handleChange(e)}
										value={form.campaign_name}
										className='mb-5 input-source'
										InputLabelProps={{ shrink: true }}
									/>
								</>
							)}
							<h2 className="text-left text-white mb-5 mt-5 ">Plantillas disponibles</h2>
							<div className="overwriteSearchStyles relative flex justify-between">
								<SearchAutocomplete 
									placeholder='Buscar etiquetas' 
									data={plantillas} 		
									autoOpen={false}							
									onSelect={onPlantillasSelect}
								/>
								{selectedPlantillas.length > 0 && 
									<button className="left-1" onClick={() => setSelectedPlantillas([])}>
										Borrar seleccion
									</button>
								}
							</div>
							<div className="w-full mb-3">
								{selectedPlantillas.map( (plantilla, i) => 
									<div key={i} className="relative flex-col flex items-start justify-start mt-3 float-left rounded px-2 pr-7 py-1 bg-[#1F6466]">
										<span className="text-[#aaa] text-xs text-left">{plantilla.name}</span>
										<p className="text-white text-sm text-left">{plantilla.text}</p>
									</div>
								)}
							</div>
							<div className="flex gap-2 items-center justify-start">
							<FormControlLabel control={<Checkbox 
                checked={form.forced_send}
								name='forced_send'
								id='forced_send'
								inputProps={{ 'aria-label': 'controlled' }}
								onChange={(e) => handleChange(e)}
								defaultChecked 
              />} label="Envio forzado" />
							<Tooltip title={'Las plantillas no serán enviadas si: La misma plantilla fue enviada al cliente previamente.El cliente se encuentra actualmente siendo siendo atendido por otro agente.El envío forzado hará que la plantilla se envíe de todas formas.'} placement="right">
									<div>
										<TiInfoLarge className="text-[24px] text-[#acb8c0]" />
									</div>
							</Tooltip>
							</div>
							<div className="flex flex-col gap-2">
							<div className="flex items-center">
									<label className="text text-[#a3aeb8] mb-3 mt-2 text-left mr-4">Area destino</label>
									<Tooltip title={'Al area al que sera transferido el chat luego de enviarse la plantilla.'} placement="right">
											<div>
												<TiInfoLarge className="text-[24px] text-[#acb8c0]" />
											</div>
									</Tooltip>
								</div>
								<Select
									notched
									labelId="area"
									id="area"
									value={form.area}
									name='area'
									label="Area destino"
									//@ts-ignore
									onChange={(e) => handleChange(e)}
									className='mb-5 input-source w-full'
								>
									{areaOptions.map( (option: any, i:number) => <MenuItem key={i} value={option}>{option}</MenuItem>)}
								</Select>
							</div>

							{form.area === 'Asignar a agente' && (
								<div className="flex flex-col gap-2">
									<div className="flex items-center">
										<label className="text text-[#a3aeb8] mb-3 text-left mr-4">Agente destino</label>
										<Tooltip title={'Unicamente disponible si el area destino es "transferir a un agente".'} placement="right">
											<div>
												<TiInfoLarge className="text-[24px] text-[#acb8c0]" />
											</div>
										</Tooltip>
									</div>
									<Select
										notched
										labelId="assign_to_agent"
										id="assign_to_agent"
										value={form.assign_to_agent}
										name='assign_to_agent'
										label="Agente destino"
										//@ts-ignore
										onChange={(e) => handleChange(e)}
										className='mb-5 input-source w-full'
									>
										{agentsOptions.map( (option: any, i:number) => <MenuItem key={i} value={option.email}>{option.nombre}</MenuItem>)}
									</Select>
								</div>
							)}
							
							<div className="flex justify-end w-full mt-5 gap-5">
								<button className="bg-[#ff5758] rounded text-white px-6 py-2 text-sm" onClick={() => closeModal(false)}>Descartar</button>
								<button className="bg-[#20cab4] rounded text-white px-6 py-2 text-sm" onClick={() => setIsAutomation(true)}>Continuar</button>
								<button className="bg-[#20cab4] rounded text-white px-6 py-2 text-sm" onClick={() => sendForm()}>Enviar ahora</button>

							</div>
						</div>

					</>
				) : (
					<>
						<h2 className="text-left text-white mb-5">Automatización</h2>
						<div className="flex flex-col relative">
							<div  className="flex flex-col relative mb-3">
								<label className="text-sm text-[#a3aeb8] mb-3 text-left">Añade etiquetas</label>
								<button 
									aria-controls={openTag ? 'basic-menu' : undefined}
									aria-haspopup="true"
									aria-expanded={openTag ? 'true' : undefined}
									onClick={handleClickTag}
									className="rounded w-5 h-5 flex items-center justify-center text-[#acb8c0] border border-[#acb8c0] absolute right-0 top-0"
								>
									+
								</button>
								<Menu
									anchorEl={anchorElTag}
									open={openTag}
									onClose={handleCloseTag}
								>
									<SearchAutocomplete 
										placeholder='Buscar etiquetas' 
										data={tags} 
										//@ts-ignore
										onSelect={onTagSelect}
										type='tag'
									/>
								</Menu>	
								<div className="w-full">
									{/* @ts-ignore */}
									{selectedTags.map( (tag:Tag, i) => 
										<div key={i} className="relative ml-2 mb-2 float-left rounded px-2 pr-7 py-1 bg-[#49526f]">
											<button className="absolute top-2 right-1" onClick={() => removeTag(tag.id)}>
												<MdClose style={{fontSize: 18, color: 'white'}}/>
											</button>
											<span className="text-white text-sm">{tag.name}</span>
										</div>
									)}
								</div>
							</div>
							<div  className="flex flex-col relative mb-3">
								<label className="text-sm text-[#a3aeb8] mb-3 text-left">Añade productos</label>
								<button 
									aria-controls={openProd ? 'basic-menu' : undefined}
									aria-haspopup="true"
									aria-expanded={openProd ? 'true' : undefined}
									onClick={handleClickProd}
									className="rounded w-5 h-5 flex items-center justify-center text-[#acb8c0] border border-[#acb8c0] absolute right-0 top-0"
								>
									+
								</button>
								<Menu
									anchorEl={anchorElProd}
									open={openProd}
									onClose={handleCloseProd}
								>
									<SearchAutocomplete 
										placeholder='Buscar productos' 
										data={products} 
										//@ts-ignore
										onSelect={onProductSelect}
										type='tag'
									/>
								</Menu>	
								<div className="w-full">
									{/* @ts-ignore */}
									{selectedProducts.map( (prod:ProductType, i) => 
									
									<div key={i} className="relative ml-2 mb-2 float-left rounded px-2 pr-7 py-1 bg-[#49526f]">
										<button className="absolute top-2 right-1" onClick={() => removeProduct(prod.id)}>
											<MdClose style={{fontSize: 18, color: 'white'}}/>
										</button>
										<span className="text-sm text-white">{prod.name}</span>
									</div>
									)}
								</div>
							</div>
							<label className="text-sm text-[#a3aeb8] mb-3 text-left">Añade una nota</label>
							<TextareaAutosize
								id="description"
								name='description'
								onChange={handleChange}
								value={form.description}
								className='mb-5 input-source bg-[#1a2238] text-[14px] text-[#43f8e8] rounded'
							/>
							<div  className="flex flex-col relative mb-3">
								<label className="text-sm text-[#a3aeb8] mb-3 text-left">Añade workflows</label>
								<button 
									aria-controls={openWorkflow ? 'basic-menu' : undefined}
									aria-haspopup="true"
									aria-expanded={openWorkflow ? 'true' : undefined}
									onClick={handleClickWorkflow}
									className="rounded w-5 h-5 flex items-center justify-center text-[#acb8c0] border border-[#acb8c0] absolute right-0 top-0"
								>
									+
								</button>
								<Menu
									anchorEl={anchorElWorkflow}
									open={openWorkflow}
									onClose={handleCloseWorkflow}
								>
									<SearchAutocomplete 
										placeholder='Buscar workflows' 
										data={workflows} 
										//@ts-ignore
										onSelect={onWorkflowSelect}
										type='workflow'
									/>
								</Menu>	
								<div className="w-full">
									{/* @ts-ignore */}
									{selectedWorkflows.map( (workflowItem:WorkflowType, i) => {
										return(
											<WorkflowItem flow={workflowItem} key={i} deleteWorkflow={() => removeWorkflow(workflowItem._id)}/>
											// <span className="ml-2 mb-2 float-left rounded text-white text-sm px-2 py-1 bg-[#49526f]">{workflowItem.title}</span>
										)
									})}
								</div>
							</div>
							<div className="flex justify-end w-full mt-5 gap-5">
								<button className="bg-[#ff5758] rounded text-white px-6 py-2 text-sm" onClick={() => closeModal(false)}>Descartar</button>
								<button className="bg-[#20cab4] rounded text-white px-6 py-2 text-sm" onClick={() => setIsAutomation(false)}>Atras</button>
								<button className="bg-[#20cab4] rounded text-white px-6 py-2 text-sm" onClick={() => sendForm()}>Confirmar</button>
							</div>
						</div>
					</>
				)}
			</div>
		</div>
	)
}

export default AddTemplate