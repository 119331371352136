import React from 'react';
import { useEffect, useState, useContext } from 'react';
import { Close as CloseIcon, Edit as EditIcon } from '@mui/icons-material';
import userContext from '../../utils/userContext';
import { addSale, updateSale, deleteSale } from '../../services/sales.service';
import { Sale,AddSaleModalProps, EditSaleModalProps, DeleteConfirmModal } from '../../interfaces/sales.interfaces';
import { getChat } from '../../services/ChatsActions.service';
import SaleDetailsModal from './SaleDetailsModal';
import ViewAllSalesModal from './ViewAllSalesModal';
import moment from 'moment';

function Sales({sales, currencyOptions, setSales, allProducts}: any) {
    const { clientUser, agent, context } = useContext<any>(userContext);
    const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [saleIdToDelete, setSaleIdToDelete] = useState(null);
    const [isViewAllSalesModalOpen, setIsViewAllSalesModalOpen] = useState<boolean>(false);
    const [selectedSaleForDetails, setSelectedSaleForDetails] = useState<Sale | null>(null);
    const [newSale, setNewSale] = useState({
        product_id: "",
        quantity: "",
        chat_id: "",
        agent_email: "",
        total_amount: "",
        paid_amount: "",
        installments: 1,
        created_at: "",
        updated_at: "",
        details: {
        observation: ""
        },
        currency: "",
        crm_id: ""
    });

    const remainingSalesCount = sales.length - 2;

    const [selectedSale, setSelectedSale] = useState<Sale | null>(null);

    const handleOpenConfirmModal = (id: any) => {
        setSaleIdToDelete(id);
        setShowConfirmModal(true);
    };
      
    const handleConfirmDelete = () => {
        if (saleIdToDelete) {
          deleteSale(agent.company, saleIdToDelete, agent.id, clientUser.conversation_id)
            .then(() => {
              setSales((currentSales: any) => currentSales.filter((sale: any) => sale._id !== saleIdToDelete)); 
              setShowConfirmModal(false); 
              setSaleIdToDelete(null); 
            })
            .catch((error) => {
              console.error(error);
              setShowConfirmModal(false);
              setSaleIdToDelete(null);
            });
        }
    };
      
    const handleCloseModal = () => setShowConfirmModal(false);

    const handleOpenAddModal = () => {
        setNewSale({
          ...newSale,
          agent_email: agent.id,
          chat_id: clientUser.conversation_id,
        });
        setIsAddModalOpen(true);
    };

    const AddSaleModal: React.FC<AddSaleModalProps> = ({ open, onClose, onSubmit, agentId, chatId }) => {
        const [sale, setSale] = useState<Sale>({
            product_id: "",
            quantity: "",
            total_amount: "",
            paid_amount: "",
            currency: "",
            installments: 1,
            details: { observation: "" },
        });
    
        useEffect(() => {
            if (open) {
                setSale({
                    ...sale,
                    agent_email: agentId,
                    chat_id: chatId,
                });
            }
        }, [open, agentId, chatId]);
    
        const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>) => {
            const { name, value } = e.target;
            if (name === "details.observation") {                
                setSale(prev => ({
                    ...prev,
                    details: { ...prev.details, observation: value },
                }));
            } else {
                setSale(prev => ({ ...prev, [name]: value }));
            }
        };
        
    
        const handleSubmit = (e: React.FormEvent) => {
            e.preventDefault();
            const now = moment(new Date).format()
            onSubmit({
                ...sale,
                created_at: now,
                updated_at: now,
            });
            onClose();
        };
    
        if (!open) return null;
    
        return (
            <div className="fixed inset-0 top-1 left-1 flex justify-center items-center z-50 backdrop-blur-sm bg-[##00000040]" onClick={onClose}>
                <div className="bg-[#212944] border border-[#2f3652] p-4 rounded-lg shadow-lg w-full max-w-md" id="modal-content" onClick={(e) => e.stopPropagation()}>

                    <div className="relative">
                        <button
                            onClick={onClose} 
                            className="absolute top-0 right-0 self-center text-red-500 hover:text-red-700"
                            aria-label="Cerrar"
                        >
                            <span className="text-2xl">&times;</span>
                        </button>
                    </div>


                    <form onSubmit={handleSubmit} className="space-y-4">
                        <h2 className="text-lg font-semibold text-white">Nueva Venta</h2>
                        
                        <div>
                            <label htmlFor="product_id" className="block text-sm font-medium text-white">Producto</label>
                            <select
                                name="product_id"
                                id="product_id"
                                value={sale.product_id}
                                onChange={handleChange}
                                className="mt-1  block w-full px-3 py-2 border bg-[#1a2238] border-[#49526f] rounded-md shadow-sm focus:outline-none focus:ring-teal-500 focus:border-teal-500"
                                required
                            >
                                <option className='' value="">Seleccione un producto</option>
                                {Object.entries(allProducts).map(([productId, productDetails]: any) => (
                                    <option key={productId} className='' value={productId}>{productDetails.name}</option>
                                ))}
                            </select>
                        </div>

                        <div>
                            <label htmlFor="quantity" className="block text-sm font-medium text-white">Cantidad</label>
                            <input
                                type="number"
                                name="quantity"
                                id="quantity"
                                value={sale.quantity.toString()}
                                onChange={handleChange}
                                className="mt-1 bg-[#1a2238] block w-full px-3 py-2 border border-[#49526f] rounded-md shadow-sm focus:outline-none focus:ring-teal-500 focus:border-teal-500"
                                required
                            />
                        </div>

                        <div>
                            <label htmlFor="total_amount" className="block text-sm font-medium text-white">Total</label>
                            <input
                                type="number"
                                name="total_amount"
                                id="total_amount"
                                value={sale.total_amount.toString()}
                                onChange={handleChange}
                                className="mt-1 block w-full bg-[#1a2238] px-3 py-2 border border-[#49526f] rounded-md shadow-sm focus:outline-none focus:ring-teal-500 focus:border-teal-500"
                                required
                            />
                        </div>

                        <div>
                            <label htmlFor="paid_amount" className="block text-sm font-medium text-white">Pagó</label>
                            <input
                                type="number"
                                name="paid_amount"
                                id="paid_amount"
                                value={sale.paid_amount.toString()}
                                onChange={handleChange}
                                className="mt-1 block w-full bg-[#1a2238] px-3 py-2 border border-[#49526f] rounded-md shadow-sm focus:outline-none focus:ring-teal-500 focus:border-teal-500"
                            />
                        </div>

                        <div>
                            <label htmlFor="currency" className="block text-sm font-medium text-white">Moneda</label>
                            <select
                                name="currency"
                                id="currency"
                                value={sale.currency}
                                onChange={handleChange}
                                className="mt-1 block w-full bg-[#1a2238] px-3 py-2 border border-[#49526f] rounded-md shadow-sm focus:outline-none focus:ring-teal-500 focus:border-teal-500"
                                required
                            >
                                <option className='' value="">Seleccione una moneda</option>
                                {currencyOptions.map(({ value, label }: any) => (
                                    <option className='' key={value} value={value}>{label}</option>
                                ))}
                            </select>
                        </div>

                        <div>
                            <label htmlFor="installments" className="block text-sm font-medium text-white">Cuotas</label>
                            <input
                                type="number"
                                name="installments"
                                id="installments"
                                value={sale.installments.toString()}
                                onChange={handleChange}
                                className="mt-1 block w-full bg-[#1a2238] px-3 py-2 border border-[#49526f] rounded-md shadow-sm focus:outline-none focus:ring-teal-500 focus:border-teal-500"
                            />
                        </div>

                        <div>
                            <label htmlFor="observation" className="block text-sm font-medium text-white">Descripción</label>
                            <textarea
                                name="details.observation"
                                id="observation"
                                value={sale.details.observation}
                                onChange={handleChange}
                                className="mt-1 text-white block w-full px-3 py-2 border bg-[#1a2238] border-[#49526f] rounded-md shadow-sm focus:outline-none focus:ring-teal-500 focus:border-teal-500"
                            />
                        </div>

                        <div>
                            <label htmlFor="crm_id" className="block text-sm font-medium text-white">CRM id</label>
                            <textarea
                                name="crm_id"
                                id="crm_id"
                                value={sale.crm_id}
                                onChange={handleChange}
                                className="mt-1 block text-white bg-[#1a2238] w-full px-3 py-2 border border-[#49526f] rounded-md shadow-sm focus:outline-none focus:ring-teal-500 focus:border-teal-500"
                            />
                        </div>
                        <button
                            type="submit"
                            className="mt-4 w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                        >
                            Añadir Venta
                        </button>
                    </form>
                </div>

            </div>
        );
    };

    const EditSaleModal: React.FC<EditSaleModalProps> = ({ open, onClose, onSubmit, sale }) => {
        const [editSale, setEditSale] = useState<any>(sale);
    
        useEffect(() => {
            if (open) {
                setEditSale(sale);
            }
        }, [open, sale]);
    
        const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
            const { name, value } = e.target;
            if (name === "details.observation") {
                setEditSale({
                    ...editSale,
                    details: { ...editSale.details, observation: value }
                });
            } else {
                setEditSale({
                    ...editSale,
                    [name]: value
                });
            }
        };
    
        const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            const updatedSale = {
                ...editSale,
                updated_at: new Date().toISOString(), 
                updater_agent_id: agent.id,
                chat_id: clientUser.conversation_id
            };
            
            onSubmit(updatedSale);
            onClose();
        };
    
        if (!open) return null;
    
        return (
            <div className="fixed inset-0 z-50 flex justify-center items-center backdrop-blur-sm bg-[##00000040]" onClick={onClose}>
            <div className="bg-[#212944] border border-[#2f3652] p-4 rounded-lg shadow-lg w-full max-w-md" onClick={(e) => e.stopPropagation()}>
                <div className="relative">
                    <button
                        onClick={onClose} 
                        className="absolute top-0 right-0 text-red-500 hover:text-red-700"
                        aria-label="Cerrar"
                    >
                        <CloseIcon sx={{ fontSize: 24 }} />
                    </button>
                </div>
                <form onSubmit={handleSubmit} className="space-y-4">
                    <h2 className="text-lg font-semibold text-white">Editar Venta</h2>
                

                        <div>
                            <label htmlFor="product_id" className="block text-sm font-medium text-white">Producto</label>
                            <select
                                name="product_id"
                                id="product_id"
                                value={editSale.product_id}
                                onChange={handleChange}
                                className="mt-1 block w-full px-3 py-2 border bg-[#1a2238] border-[#49526f] rounded-md shadow-sm focus:outline-none focus:ring-teal-500 focus:border-teal-500"
                                required
                            >
                                <option className='' value="">Seleccione un producto</option>
                                {Object.entries(allProducts).map(([productId, productDetails]: any) => (
                                    <option className='' key={productId} value={productId}>{productDetails.name}</option>
                                ))}
                            </select>
                        </div>


                    <div>
                        <label htmlFor="quantity" className="block text-sm font-medium text-white">Cantidad</label>
                        <input
                            type="number"
                            name="quantity"
                            id="quantity"
                            value={editSale.quantity}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border bg-[#1a2238] border-[#49526f] rounded-md shadow-sm focus:outline-none focus:ring-teal-500 focus:border-teal-500"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="total_amount" className="block text-sm font-medium text-white">Total</label>
                        <input
                            type="number"
                            name="total_amount"
                            id="total_amount"
                            value={editSale.total_amount}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border bg-[#1a2238] border-[#49526f] rounded-md shadow-sm focus:outline-none focus:ring-teal-500 focus:border-teal-500"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="paid_amount" className="block text-sm font-medium text-white">Pagado</label>
                        <input
                            type="number"
                            name="paid_amount"
                            id="paid_amount"
                            value={editSale.paid_amount}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border bg-[#1a2238] border-[#49526f] rounded-md shadow-sm focus:outline-none focus:ring-teal-500 focus:border-teal-500"
                        />
                    </div>
                    <div>
                            <label htmlFor="currency" className="block text-sm font-medium text-white">Moneda</label>
                            <select
                                name="currency"
                                id="currency"
                                value={editSale.currency}
                                onChange={handleChange}
                                className="mt-1 block w-full px-3 py-2 border bg-[#1a2238] border-[#49526f] rounded-md shadow-sm focus:outline-none focus:ring-teal-500 focus:border-teal-500"
                                required
                            >
                                <option className='' value="">Seleccione una moneda</option>
                                {currencyOptions.map(({ value, label }: any) => (
                                    <option className='' key={value} value={value}>{label}</option>
                                ))}
                            </select>
                    </div>
                    <div>
                        <label htmlFor="installments" className="block text-sm font-medium text-white">Cuotas</label>
                        <input
                            type="number"
                            name="installments"
                            id="installments"
                            value={editSale.installments}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border bg-[#1a2238] border-[#49526f] rounded-md shadow-sm focus:outline-none focus:ring-teal-500 focus:border-teal-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="observation" className="block text-sm font-medium text-white">Observación</label>
                        <textarea
                            name="details.observation"
                            id="observation"
                            value={editSale.details.observation}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border bg-[#1a2238] border-[#49526f] rounded-md shadow-sm focus:outline-none focus:ring-teal-500 focus:border-teal-500"
                        />
                    </div>
                    <div>
                            <label htmlFor="crm_id" className="block text-sm font-medium text-white">CRM id</label>
                            <textarea
                                name="crm_id"
                                id="crm_id"
                                value={editSale.crm_id}
                                onChange={handleChange}
                                className="mt-1 block w-full px-3 py-2 border bg-[#1a2238] border-[#49526f] rounded-md shadow-sm focus:outline-none focus:ring-teal-500 focus:border-teal-500"
                            />
                    </div>
                    <button
                        type="submit"
                        className="mt-4 w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                    >
                        Actualizar Venta
                    </button>
                </form>
            </div>
        </div>
        );
    };

    const SimpleConfirmModal: React.FC<DeleteConfirmModal> = ({ isOpen, onClose, onConfirm, message }) => {
        if (!isOpen) return null;
      
        return (
          <div className="fixed inset-0 z-50 overflow-auto bg-smoke-light flex">
            <div className="relative p-4 bg-white w-72 max-w-md m-auto flex-col flex rounded-lg">
              <div>{message}</div>
              <div className="flex justify-end space-x-4 mt-8">
                <button
                  onClick={onClose}
                  className="px-4 py-2 rounded text-white bg-gray-500 hover:bg-gray-600"
                >
                  Cancelar
                </button>
                <button
                  onClick={onConfirm}
                  className="px-4 py-2 rounded text-white bg-red-500 hover:bg-red-600"
                >
                  Eliminar
                </button>
              </div>
            </div>
          </div>
        );
    }
      
    const handleAddSale = async (sale: any) => {
        console.log('sale to be created: ', sale)
        try {
            const addedSale = await addSale(agent.company, sale);
            getChat(agent.company, agent.id, clientUser.conversation_id, context === 'admin' ? 'chats_manager' : 'agent_workspace').then((response) => {
                setSales(response.client.sales);
              }).catch((error) => {
                console.error(error);
              });
            setIsAddModalOpen(false); 
        } catch (error) {
            console.error("Failed to add sale:", error);
        }
    };   

    const handleOpenEditModal = (sale: any) => {
        setSelectedSale(sale); 
        setIsEditModalOpen(true); 
    };

    const handleOpenSaleDetailsModal = (sale: Sale) => {
        setSelectedSaleForDetails(sale);
    };
    
    const handleUpdateSale = async (updatedSale: any) => {
        try {
            await updateSale(agent.company, updatedSale._id, updatedSale); 
            setSales((currentSales: any) => currentSales.map((sale: any) => sale._id === updatedSale._id ? updatedSale : sale));
            setIsEditModalOpen(false);
        } catch (error) {
            console.error("Error al actualizar la venta:", error);
        }
    };

  return (
    <>
        {context === 'agent' && 
            <button 
                onClick={context === "agent" ? handleOpenAddModal : undefined} 
                className="rounded w-7 h-7 flex items-center justify-center text-[#acb8c0] border border-[#acb8c0] absolute right-12 top-4"
            >
                +
            </button>
        }
        <div className="flex flex-wrap justify-center gap-2 pl-3 pr-3 pt-3">
            
            <div className="flex flex-wrap justify-start items-center gap-2 w-full">
                {remainingSalesCount > 0 && (
                <div className="flex items-center justify-center h-10 w-10 bg-teal-400 rounded-full cursor-pointer" onClick={() => setIsViewAllSalesModalOpen(true)}>
                <span className="text-white font-bold">+{remainingSalesCount}</span>
                </div>
            )}

            {sales.slice(Math.max(sales.length - 2, 0)).map((sale: any, index: any): any => (
                <div key={index} className="flex h-16 w-[32%] bg-[#121827] shadow-md rounded-lg overflow-hidden relative cursor-pointer" onClick={() => handleOpenSaleDetailsModal(sale)}>
                {/* Botones de cierre y edición */}
                {context === 'agent' && 
                    <div className="absolute right-1 top-0" onClick={(e) => e.stopPropagation()}>
                        <button onClick={() => handleOpenConfirmModal(sale._id)}>
                            <CloseIcon sx={{ fontSize: 12 }} />
                        </button>
                    </div>
                }
                {context === 'agent' && (
                    <div className="absolute bottom-0 right-1" onClick={(e) => e.stopPropagation()}>
                        <button onClick={() => handleOpenEditModal(sale)}>
                            <EditIcon sx={{ fontSize: 12 }} />
                        </button>
                    </div>

                )}
                {/* Contenido de la venta */}
                <div className="bg-teal-400 w-1/5 p-1 flex items-center justify-center">
                    $
                </div>
                <div className="w-4/5 p-1 flex flex-col justify-between">
                    <div className="flex justify-between items-center">
                        <h3 className=" ">{sale.quantity} {
                            allProducts[sale.product_id] ? allProducts[sale.product_id].name : 'Producto'
                        }</h3>
                    </div>
                    <div className="mt-1">
                        <p className="text-sm text-gray-600">{sale.total_amount} {sale.currency || "none"}</p>
                    </div>
                </div>
                </div>
            ))}

            <AddSaleModal
                open={isAddModalOpen}
                onClose={() => setIsAddModalOpen(false)}
                onSubmit={handleAddSale}
                agentId={agent.id}
                chatId={clientUser.conversation_id}
            />

            <SimpleConfirmModal
                isOpen={showConfirmModal}
                onClose={handleCloseModal}
                onConfirm={handleConfirmDelete} 
                message="¿Estás seguro de que quieres eliminar esta venta?"
            />

            {isEditModalOpen && selectedSale && (
                <EditSaleModal
                    open={isEditModalOpen}
                    onClose={() => setIsEditModalOpen(false)}
                    onSubmit={handleUpdateSale}
                    sale={selectedSale}
                />
            )}

            <SaleDetailsModal
                open={!!selectedSaleForDetails}
                sale={selectedSaleForDetails}
                onClose={() => setSelectedSaleForDetails(null)}
                // @ts-ignore
                allProducts={allProducts}
            />


            {
                isViewAllSalesModalOpen && (
                    <ViewAllSalesModal
                        open={isViewAllSalesModalOpen}
                        sales={sales}
                        onClose={() => setIsViewAllSalesModalOpen(false)}
                        allProducts={allProducts}
                    />
                )
            }

            </div>
        </div>
    </>
  );
}

export default Sales;