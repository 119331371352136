import React, { useState, useContext, useEffect } from "react";
import MyInbox from "../components/MyInbox/MyInbox";
import MyPreview from "../components/MyPreview";
import MyPanel from "../components/MyPanel";
import OpenNotification from "../components/OpenNotification";
import userContext from "../utils/userContext";
import "react-toastify/dist/ReactToastify.css";
import { useDocumentClick } from "../hooks/useDocumentClick";

const Messenger: React.FC = () => {
  const [inboxToggle, setInboxToggle] = useState<boolean>(false);
  const { context, fastAnswersModal } = useContext<any>(userContext);
  const [user, setUser] = useState<string>("");
  const [panelToggle, setPanelToggle] = useState<boolean>(false);

  const [tagDropdownIsOpen, setTagDropdownIsOpen] = useDocumentClick(
    ".tags-dropdown-container",
    false
  );
  const [fastAnswersIsOpen, setFastAnswersIsOpen] = useDocumentClick(
    ".fast-answers-container",
    false
  );

  const [chatFilterIsOpen, setChatFilterIsOpen] = useDocumentClick(
    ".chat-filter-container",
    false
  );

  const [tagFilterIsOpen, setTagFilterIsOpen] = useDocumentClick(
    ".tags-filter-container",
    false
  );

  const [getChatsIsOpen, setGetChatsIsOpen] = useDocumentClick(
    ".get-chats-modal-container",
    false
  );
  const [dropChatsIsOpen, setDropChatsIsOpen] = useDocumentClick(
    ".drop-chats-modal-container",
    false
  );
  const [userModalIsOpen, setUserModalIsOpen] = useDocumentClick(
    ".user-modal-container",
    false
  );
  const [countrySelectIsOpen, setCountrySelectIsOpen] = useDocumentClick(
    ".country-dropdown-container",
    false
  );
  const [tickerIdSelectIsOpen, setTickerIdSelectIsOpen] = useDocumentClick(
    ".tickerId-dropdown-container",
    false
  );

  const [botDropdownIsOpen, setBotDropdownIsOpen] = useDocumentClick(
    ".bot-dropdown-container",
    false
  );
  const [userInfoIsOpen, setUserInfoIsOpen] = useDocumentClick(
    ".bot-dropdown-container",
    false
  );

  const [refetchPreview, setRefetchPreview] = useState<boolean>(false);

  useEffect(() => {
    console.log("cambio de fondo");
  }, [fastAnswersModal]);

  return (
    <div
      className={`min-h-screen max-w-screen font-sans flex items-center justify-center bg-slate-100 text-black ${
        fastAnswersModal && " modal-background"
      }`}
    >
      <OpenNotification />
      <div className="w-screen h-screen grid grid-rows-1 grid-cols-8 bg-white shadow-lg shadow-slate-400">
        <MyPreview
          inboxToggle={inboxToggle}
          setInboxToggle={setInboxToggle}
          setUser={setUser}
          tagFilterIsOpen={tagFilterIsOpen}
          setTagFilterIsOpen={setTagFilterIsOpen}
          getChatsIsOpen={getChatsIsOpen}
          setGetChatsIsOpen={setGetChatsIsOpen}
          dropChatsIsOpen={dropChatsIsOpen}
          setDropChatsIsOpen={setDropChatsIsOpen}
          userInfoIsOpen={userInfoIsOpen}
          setUserInfoIsOpen={setUserInfoIsOpen}
          refetchPreview={refetchPreview}
          setRefetchPreview={setRefetchPreview}
          chatFilterIsOpen={chatFilterIsOpen}
          setChatFilterIsOpen={setChatFilterIsOpen}
        />
        <MyInbox
          panelToggle={panelToggle}
          setPanelToggle={setPanelToggle}
          user={user}
          inboxToggle={inboxToggle}
          setInboxToggle={setInboxToggle}
          fastAnswersIsOpen={fastAnswersIsOpen}
          setFastAnswersIsOpen={setFastAnswersIsOpen}
          botDropdownIsOpen={botDropdownIsOpen}
          setBotDropdownIsOpen={setBotDropdownIsOpen}
          setUserModalIsOpen={setUserModalIsOpen}
          userModalIsOpen={userModalIsOpen}
          countrySelectIsOpen={countrySelectIsOpen}
          setCountrySelectIsOpen={setCountrySelectIsOpen}
          setTickerIdSelectIsOpen={setTickerIdSelectIsOpen}
          tickerIdSelectIsOpen={tickerIdSelectIsOpen}
          setRefetchPreview={setRefetchPreview}
          bgColor={context === "admin" ? "bg-[#576080]" : "bg-[#212944]"}
          setUser={setUser}
        />
        <MyPanel
          panelToggle={panelToggle}
          setPanelToggle={setPanelToggle}
          refetchPreview={refetchPreview}
          user={user}
          inboxToggle={inboxToggle}
          setInboxToggle={setInboxToggle}
          tagDropdownIsOpen={tagDropdownIsOpen}
          setTagDropdownIsOpen={setTagDropdownIsOpen}
          bgColor={context === "admin" ? "bg-[#576080]" : "bg-[#212944]"}
          isPanelDisabled={false}
          setRefetchPreview={setRefetchPreview}
        />
      </div>
    </div>
  );
};

export default Messenger;
