import { useState , useContext, useEffect} from "react";
import userContext from "../utils/userContext";
import { MdExpandMore, MdExpandLess } from "react-icons/md";

import { TabProps } from "../interfaces/tabProps.interface";
import { Highlight } from "./Highlight";
import { Note } from "./Note";
import Sales from "./Sales/Sales";
import InterestProducts from "./interestProducts";
import { getChat } from '../services/ChatsActions.service';
import { getAllProducts } from "../services/products.service";
import WorkflowsTab from "./Workflows/WorkflowsTab";

const CURRENCY_OPTIONS = [
  { value: 'ARS', label: 'ARS - Peso Argentino' },
  { value: 'USD', label: 'USD - Dolar Estadounidense' },
  { value: 'EUR', label: 'EUR - Euro' },
  { value: 'CLP', label: 'CLP - Peso Chileno' },
  { value: 'COP', label: 'COP - Peso Colombiano' },
  { value: 'MXN', label: 'MXN - Peso Mexicano' },
  { value: 'PEN', label: 'PEN - Sol Peruano' },
  { value: 'UYU', label: 'UYU - Peso Uruguayo' },
  { value: 'VEF', label: 'VEF - Bolívar Venezolano' },
  { value: 'BOB', label: 'BOB - Boliviano' },
  { value: 'CRC', label: 'CRC - Colón Costarricense' },
  { value: 'CUP', label: 'CUP - Peso Cubano' },
  { value: 'DOP', label: 'DOP - Peso Dominicano' },
  { value: 'GTQ', label: 'GTQ - Quetzal Guatemalteco' },
  { value: 'HNL', label: 'HNL - Lempira Hondureño' },
  { value: 'NIO', label: 'NIO - Córdoba Nicaragüense' },
  { value: 'PAB', label: 'PAB - Balboa Panameño' },
  { value: 'PYG', label: 'PYG - Guaraní Paraguayo' }
]

export const Tab: React.FC<TabProps> = ({ disabled, activeTagsTab, highlights, refetchData, isBroaderPreview = false }) => {
  const [showForm, setShowForm] = useState<boolean>(false);
  const {context , clientUser, agent} = useContext<any>(userContext);
  const [showInterestProducts, setShowInterestProducts] = useState<boolean>(true);
  const [showSales, setShowSales] = useState<boolean>(true); 
  const [showWorkflows, setShowWorkflows] = useState<boolean>(true)
  const [interestProducts, setInterestProducts] = useState([]);
  const [sales, setSales] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [currencyOptions, setCurrencyOptions] = useState<Array<{ value: string; label: string }>>(CURRENCY_OPTIONS);

  const fetchCurrencies = async () => {
      try {
          const response = await fetch('https://openexchangerates.org/api/currencies.json');
          const data = await response.json();

          const additionalCurrencies = Object.entries(data)
            .filter(([code]) => !['ARS', 'USD', 'EUR', "CLP", "COP", "MXN", "PEN", "UYU", "VEF", "BOB", "CRC", "CUP", "DOP", "GTQ", "HNL", "NIO", "PAB", "PYG"].includes(code))
            .map(([code, name]) => ({
              value: code,
              // @ts-ignore
              label: `${code} - ${(spanishCurrencyTranslations)[name] || name}`,
            }));

          setCurrencyOptions(prevOptions => [
            ...prevOptions, 
            ...additionalCurrencies
          ]);
      } catch (error) {
          console.error('Failed to fetch currencies:', error);
      }
  };

  useEffect(() => {
    setShowForm(false)
    if (clientUser.conversation_id && agent.company && agent.id) {
      getChat(agent.company, agent.id, clientUser.conversation_id, context === 'admin' ? 'chats_manager' : 'agent_workspace').then((response) => {
        setInterestProducts(response.client.products);
        setSales(response.client.sales);
      }).catch((error) => {
        console.error(error);
      });
    } else {
      setInterestProducts([]);
      setSales([]);
    }
  }, [clientUser, agent]);

  useEffect(() => {
    getAllProducts("products",agent.company).then((response) => {
        setAllProducts(response);
        console.log('all products:', response)
    })
    .catch((error) => {
        console.log(error);
    }   
    );
}, [agent.company]);

  useEffect(() => {
    fetchCurrencies();
  }, []);

  const toggleInterestProducts = () => {
    setShowInterestProducts(!showInterestProducts); 
  };

  const toggleSales = () => {
    setShowSales(!showSales); 
  };

  return (
    <div className="flex flex-col h-4/6 overflow-y-scroll overflow-x-hidden">
      <div className="border-b border-[#2f3652] pb-3 pt-3 px-5 relative">
        <div 
          className={`flex justify-center items-center cursor-pointer mb-2`} 
          onClick={() => setShowWorkflows(!showWorkflows)}
          >
          <h3 className="flex text-[#acb8c0] tracking-wide text-lg text-left w-full">
            Workflows
          </h3>
          {showWorkflows ? <MdExpandLess className="absolute right-5 text-gray-500" /> : <MdExpandMore className="absolute right-5 text-gray-500" />}
        </div>
        {showWorkflows && <WorkflowsTab isBroaderPreview={isBroaderPreview}/>}
      </div>
        

      <div className={`border-b border-[#2f3652] pb-3 pt-3 px-5 relative ${isBroaderPreview ? 'pointer-events-none' : 'pointer-events-auto'}`}>
        <div 
          className={`flex justify-center items-center cursor-pointer ${!showInterestProducts ? "mb-2" : ""}`} 
          onClick={toggleInterestProducts}
        >
          <h3 className="flex text-[#acb8c0] tracking-wide text-lg text-left w-full">
            Productos de Interés
          </h3>
          {showInterestProducts ? <MdExpandLess className="absolute right-5 text-gray-500" /> : <MdExpandMore className="absolute right-5 text-gray-500" />}
        </div>
        {showInterestProducts && <InterestProducts
          interestProducts={interestProducts}
          setInterestProducts={setInterestProducts}
        />}

      </div>

      <div className={`border-b border-[#2f3652] pb-3 pt-3 px-5 relative ${isBroaderPreview ? 'pointer-events-none' : 'pointer-events-auto'}`}>
        <div 
          className={`flex justify-center items-center cursor-pointer ${!showSales ? "mb-2" : ""}`} 
          onClick={toggleSales}
        >
            <h3 className="flex text-[#acb8c0] tracking-wide text-lg text-left w-full">
              Ventas
            </h3>
            {showSales ? <MdExpandLess className="absolute right-5 text-gray-500" /> : <MdExpandMore className="absolute right-5 text-gray-500" />}
        </div>
        {showSales && <Sales sales={sales} currencyOptions={currencyOptions} setSales={setSales} allProducts={allProducts}/>}
      </div>
      <div className={`border-b border-[#2f3652] pb-3 pt-3 px-5 relative ${isBroaderPreview ? 'pointer-events-none' : 'pointer-events-auto'}`}>
        <div className={`pb-2 mt-1 flex justify-center items-center`} >
          <h3 className="flex text-[#acb8c0] tracking-wide text-lg text-left w-full">
            {showForm ? "Crear highlight" : "Actividad"}
          </h3>
        </div>
      </div>
      
      <div className="px-3 overflow-y-scroll h-full flex flex-col">
        {showForm ? <Note setShowForm={setShowForm} refetchData={refetchData}/> : <Highlight  highlightsData={highlights} />}
        {context === 'agent' && (
          <button
            title="crear highlight"
            onClick={() => setShowForm(!showForm)}
            className={`${
              disabled && "pointer-events-none bg-green-500 text-gray-300"
            } absolute bottom-5 right-5 px-3 py-1 rounded-full bg-green-400 m-auto text-lg text-white`}
          >
            {showForm ? "-" : "+"}
          </button>
        )}
      </div>
    </div>
  );
};
